/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import urls from '../../urls';
import * as actions from '../actionConstants';

export const fetchQuestions = (meetingId, questionType) => (dispatch) => {
  dispatch({
    type: actions.LOADING,
  });
  axios.get(`${urls.fetchQuestions}?meeting_id=${meetingId}&question_type=${questionType}`).then((res) => {
    if (res.data.status_code === 404) {
      dispatch({
        type: actions.FETCH_ERRORS,
        payload: {
          data: res.data.message,
          type: res.data.type_name,
        },
      });
      dispatch({
        type: actions.LOADED,
      });
      return;
    }
    dispatch({
      type: actions.FETCH_QUESTIONS,
      payload: {
        data: res.data,
      },
    });
    dispatch({
      type: actions.LOADED,
    });
  }).catch(() => {
    dispatch({
      type: actions.LOADED,
    });
  });
};

export const saveResult = (data) => ({
  type: actions.SAVE_RESULT,
  payload: {
    data,
  },
});

export const fetchResultSummary = (meetingId, data) => (dispatch) => {
  dispatch({
    type: actions.LOADING,
  });
  const literacy = [];
  const numeracy = [];

  data.forEach((item) => {
    const answer = {
      id: item.id,
      answer: item.option,
    };

    if (item.questionType === 1) {
      literacy.push(answer);
    } else {
      numeracy.push(answer);
    }
  });

  const body = {
    literacy_response: literacy,
    numeracy_response: numeracy,
  };
  axios.put(`${urls.base}/${meetingId}/literacy_and_numeracy_scores/`, body)
    .then((res) => {
      dispatch({
        type: actions.FETCH_RESULT_SUMMARY,
        payload: {
          data: res.data,
        },
      });
      dispatch({
        type: actions.LOADED,
      });
    }).catch(() => {
      dispatch({
        type: actions.LOADED,
      });
    });
};

export const changeSummaryKey = (key) => ({
  type: actions.CHANGE_SUMMARY,
  payload: {
    data: key,
  },
});

export const summaryVideo = (data) => ({
  type: actions.SUMMARY_VIDEO,
  payload: {
    data,
  },
});

export const changeVideoIndex = (index) => ({
  type: actions.CHANGE_CURR_VIDEO_INDEX,
  index,
});

export const changeSummaryType = () => ({
  type: actions.CHANGE_SUMMARY,
  payload: {
    data: 'numeracy',
  },
});
