/* eslint-disable */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
// import CallIcon from '@material-ui/icons/Call';
import { AnimateWrapper } from "../../containers";
import lpImg from "../../assets/pictures/lpImg.svg";
import CueComponent from "../../containers/CueComponent";
import FaqModal from "../../containers/Modal";
import urls from "../../urls";

// import boyImg from '../../assets/pictures/boy.svg';
// import girlImg from '../../assets/pictures/girl.svg';
// import { Button } from '../../ui';

const LandingWrapper = styled.div`
  // background-image: url(${lpImg});
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position : center;
  // padding-top : 7.8vw;
  // // min-width: 100%;
  // // min-height: 100%;
  // overflow: auto;
  // position: relative;
  // width: 100%;
  // height: 100vw;
  // @media all and (min-width: 1024px) {
  //   height: 100%;
  // }
  background-image: url(${lpImg});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const ContentWrapper = styled.div`
  // height: 50%;
  // width: 100%;
  // position: fixed;
  // display: flex;
  // justify-content: space-between;
  // padding: 20px;
  // bottom: 10px;
`;

const Section = styled.section`
  // text-align : center;
  //     position : absolute;
  //     top : 60% ;
  //     left : 50%;
  //     transform : translate(-50% , 60%)

  // align-self: flex-end;
  // width: 60%;
  // transform: translateY(-10px);
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    text-align: center;
    position: absolute;
    top: 68%;
    left: 50%;
    transform: translate(-50%, 60%);
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    text-align: center;
    position: absolute;
    top: 68%;
    left: 50%;
    transform: translate(-50%, 60%);
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    text-align: center;
    position: absolute;
    top: 68%;
    left: 50%;
    transform: translate(-50%, 60%);
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    text-align: center;
    position: absolute;
    top: 68%;
    left: 50%;
    transform: translate(-50%, 60%);
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    text-align: center;
    position: absolute;
    top: 68%;
    left: 50%;
    transform: translate(-50%, 60%);
  }
`;

const Heading = styled.h2`
  color: #00397a;
  padding-top: 7.8vw;
  padding-left: 118px;
`;

const Description = styled.div`
  color: #00397a;
  font-size: 2.7vw;
  // line-height: 1.5rem;
  // @media all and (min-width: 1024px) {
  //   font-size: 1.5rem;
  //   line-height: 2rem;
  // }
`;

// const NextButton = styled(Button)`
//   align-self: flex-end;
//   transform: translateY(-20px);
// `;

const CallButton = styled.button`
  background-color: #4caf50; /* Green */
  // border: none;
  color: white;
  padding: 12px 28px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  margin: 12px;
  z-index: 99;
`;

const LandingPage = () => {
  const uuidCall = useSelector((state) => state.personalInfo.uuid);
  const counsellorName = useSelector((state) => state.personalInfo.counsellor);
  const isRole = useSelector((state) => state.personalInfo.role);
  // const [isOpen, setOpen] = useState(false);

  // const openModal = () => {
  //   setOpen(true);
  // };

  // const handleModal = () => {
  //   setOpen(false);
  // };
  // // AV calling feature.
  // // let name;
  // let connectedUser;
  // //* *****
  // // UI selectors block
  // //* *****
  // const cameraSelect = document.querySelector('#camera');
  // // const loginPage = document.querySelector('#loginPage');
  // // const usernameInput = document.querySelector('#usernameInput');
  // // const loginBtn = document.querySelector('#loginBtn');

  // // const callPage = document.querySelector('#callPage');
  // // const callToUsernameInput = document.querySelector('#callToUsernameInput');
  // // const callBtn = document.querySelector('#callBtn');

  // // const callername = document.getElementById('callername');

  // // const accBtn = document.querySelector('#acc');

  // // const rejBtn = document.querySelector('#rej');

  // // const cancelBtn = document.querySelector('#cancelBtn');

  // // const hangUpBtn = document.querySelector('#hangUpBtn');

  // const localAudio = document.querySelector('#localAudio');
  // const remoteAudio = document.querySelector('#remoteAudio');

  // // const audiosrc1 = document.getElementById('audiosrc1');
  // // const audiosrc2 = document.getElementById('audiosrc2');

  // // const ad1 = document.getElementById('ad1');
  // // const ad2 = document.getElementById('ad2');

  // // const audiomute = document.querySelector('#audio_mute');
  // // const videooff = document.querySelector('#video_off');

  // let yourConn;
  // let stream;

  // const USER = uuidCall;

  // let websocketProtocol;

  // console.log('host = ', window.location.host);

  // // console.log('protocol=', location.protocol);

  // // if (window.location.protocol === 'http:') {
  // //   websocketProtocol = 'ws';
  // // } else {
  // //   websocketProtocol = 'wss';
  // // }

  // // const websocketBaseUrl = `${websocketProtocol}://${window.location.host}`;

  // const conn = new WebSocket(
  //   `${urls.audioAPI}/vc_new_user/none`,
  // );

  // // eslint-disable-next-line func-names
  // conn.onopen = function () {
  //   console.log('Connected to the signaling server');
  // };

  // // when we got a message from a signaling server
  // // conn.onmessage = function (msg) {
  // //   console.log('Got message', msg.data);
  // //   const data = JSON.parse(msg.data);

  // //   function handleLogin(success) {
  // //     if (success === false) {
  // //       alert('try a different username');
  // //     } else {
  // //       loginPage.style.display = 'none';
  // //       callPage.style.display = 'block';

  // //       // Starting a peer connection

  // //       getMediaDevice();

  // //       // getting local audio stream

  // //       const mediaConstraints = {
  // //         audio: true,
  // //         video: {
  // //           width: { min: 640, ideal: 1280, max: 1920 },
  // //           height: { min: 480, ideal: 720, max: 1080 },
  // //           deviceId: cameraSelect.value,

  // //         },
  // //       };

  // //       /*
  // // const localStream = getUserMedia({video: true, audio: true});
  // // var configuration = {
  // //             "iceServers": [{ "url": "stun:stun1.1.google.com:19302" }]
  // //          };

  // // const yourConn = new RTCPeerConnection(configuration);
  // // localStream.getTracks().forEach(track => {
  // //     yourConn.addTrack(track, localStream);
  // // });

  // // const remoteStream = MediaStream();

  // // remoteAudio.srcObject = remoteStream;

  // // yourConn.addEventListener('track', async (event) => {

  // // ad2.pause();
  // //  $("#myModal2").modal('hide');

  // //     remoteStream.addTrack(event.track, remoteStream);
  // // });

  // // */

  // //       navigator.mediaDevices.getUserMedia(mediaConstraints).then((mystream) => {
  // //         console.log('Stream connected successfully');
  // //         stream = mystream;
  // //         localAudio.srcObject = stream;

  // //         // using Google public stun server
  // //         const configuration = {
  // //           iceServers: [{ url: 'stun:stun1.1.google.com:19302' }],
  // //         };

  // //         yourConn = new RTCPeerConnection(configuration);

  // //         stream.getTracks().forEach((track) => {
  // //           yourConn.addTrack(track, stream);
  // //         });

  // //         // setup stream listening
  // //         // yourConn.addStream(stream);

  // //         // when a remote user adds stream to the peer connection, we display it
  // //         // yourConn.onaddstream = function (e) {

  // //         videooff.addEventListener('click', (event) => {
  // //           // stream.getVideoTracks()[0].enabled = !(stream.getVideoTracks()[0].enabled);

  // //           if (stream.getVideoTracks()[0].enabled == true) {
  // //             stream.getVideoTracks()[0].enabled = false;
  // //             stream.getVideoTracks()[0].stop();
  // //           } else stream.getVideoTracks()[0].enabled = true;
  // //         });

  // //         audiomute.addEventListener('click', (event) => {
  // //           stream.getAudioTracks()[0].enabled = !(stream.getAudioTracks()[0].enabled);
  // //         });

  // //         const remoteStream = new MediaStream();

  // //         remoteAudio.srcObject = remoteStream;

  // //         yourConn.addEventListener('track', async (event) => {
  // //           // ad2.pause();
  // //           // $("#myModal2").modal('hide');

  // //           remoteStream.addTrack(event.track, remoteStream);
  // //         });

  // //         // Setup ice handling
  // //         yourConn.onicecandidate = function (event) {
  // //           if (event.candidate) {
  // //             send({
  // //               type: 'candidate',
  // //               candidate: event.candidate,
  // //             });
  // //           }
  // //         };
  // //       }).catch((error) => {
  // //         console.log('error in stream:', error);
  // //       });
  // //     }
  // //   }
  // //   switch (data.type) {
  // //     case 'login':
  // //       handleLogin(data.success);
  // //       break;
  // //     case 'call':
  // //       handlecall(data.success);
  // //       break;
  // //     // when somebody wants to call us
  // //     case 'offer':
  // //       handleOffer(data.offer, data.name);
  // //       break;
  // //     case 'answer':
  // //       handleAnswer(data.answer);
  // //       break;
  // //     // when a remote peer sends an ice candidate to us
  // //     case 'candidate':
  // //       handleCandidate(data.candidate);
  // //       break;
  // //     case 'leave':
  // //       handleLeave();
  // //       break;
  // //     default:
  // //       break;
  // //   }
  // // };

  // // eslint-disable-next-line func-names
  // conn.onerror = function (err) {
  //   console.log('Got error', err);
  // };

  // // alias for sending JSON encoded messages
  // function send(message) {
  //   // attach the other peer username to our messages
  //   if (connectedUser) {
  //     message.name = connectedUser;
  //   }

  //   conn.send(JSON.stringify(message));
  // }

  // // callPage.style.display = 'none';

  // // Login when the user clicks the button
  // // not reqd
  // // loginBtn.addEventListener('click', (event) => {
  // //   name = usernameInput.value;

  // //   if (name.length > 0) {
  // //     send({
  // //       type: 'login',
  // //       name,
  // //     });
  // //   }
  // // });

  // let notbusy = 0;
  // // calling

  // function handlecall(success) {
  //   if (success === true) {
  //     notbusy = 0;

  //     yourConn.createOffer((offer) => {
  //       send({
  //         type: 'offer',
  //         offer,
  //       });

  //       yourConn.setLocalDescription(offer);
  //     }, (error) => {
  //       alert('Error when creating an offer');
  //     });
  //   } else {
  //     notbusy = 1;
  //     alert('User is busy ! Please try after sometime');
  //   }
  // }

  // // initiating a call
  // // not reqd
  // // callBtn.addEventListener('click', () => {
  // //   const callToUsername = callToUsernameInput.value;

  // //   if (callToUsername.length > 0 && notbusy === 0) {
  // //     connectedUser = callToUsername;

  // //     // $("#myModal2").modal({backdrop:'static',keyboard:false});

  // //     // ad2.play();
  // //     send({
  // //       type: 'call',
  // //       name,
  // //     });
  // //   }
  // // });

  // // cancelBtn.addEventListener("click", function () {

  // //             send({
  // //            type: "leave"
  // //           });

  // //       handleLeave();
  // // });

  // // when somebody sends us an offer

  // function handleOffer(offer, namee) {
  //   connectedUser = namee;
  //   yourConn.setRemoteDescription(new RTCSessionDescription(offer));

  //   //    $("#myModal").modal({backdrop:'static',keyboard:false});
  //   //  ad1.play();
  //   // console.log(`Callername: ${name}`);
  //   //  callername.innerHTML = name ;
  //   // create an answer to an offer

  //   // rejBtn.addEventListener("click", function () {
  //   //             send({
  //   //              type: "leave"
  //   //         });

  //   //       handleLeave();
  //   // });

  //   // accBtn.addEventListener("click", function () {
  //   //   $("#myModal").modal('hide');

  //   // audiosrc1.src=null;
  //   // ad1.pause();

  //   yourConn.createAnswer((answer) => {
  //     yourConn.setLocalDescription(answer);

  //     send({
  //       type: 'answer',
  //       answer,
  //     });
  //   }, (error) => {
  //     alert(`Error when creating an answer: ${error}`);
  //   });

  //   // });
  // }

  // // when we got an answer from a remote user

  // function handleAnswer(answer) {
  //   yourConn.setRemoteDescription(new RTCSessionDescription(answer));
  // }

  // // when we got an ice candidate from a remote user

  // function handleCandidate(candidate) {
  //   yourConn.addIceCandidate(new RTCIceCandidate(candidate));
  // }

  // // Leave function
  // function handleLeave() {
  //   connectedUser = null;
  //   remoteAudio.src = null;
  //   localAudio.src = null;
  //   yourConn.close();
  //   yourConn.onicecandidate = null;
  //   yourConn.onaddstream = null;
  //   //   $("#myModal2").modal('hide');
  //   // $("#myModal").modal('hide');
  //   // audiosrc1.src=null;
  //   // audiosrc2.src=null;
  //   // ad1.pause();
  //   // ad2.pause();
  // }
  // // hang up
  // // not reqd
  // // hangUpBtn.addEventListener('click', () => {
  // //   send({
  // //     type: 'leave',
  // //   });

  // //   handleLeave();
  // // });

  // function getMediaDevice() {
  //   if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
  //     console.log('enumerateDevices() not supported.');
  //     return;
  //   }

  //   // List cameras and microphones.
  //   navigator.mediaDevices.enumerateDevices()
  //     .then((devices) => {
  //       devices.forEach((device) => {
  //         console.log(`${device.kind}: ${device.label
  //         } id = ${device.deviceId}`);

  //         // add different camera options to a select tag // videoinput
  //         if (device.kind === 'videoinput') {
  //           const option = document.createElement('option');
  //           option.value = device.deviceId;
  //           option.text = device.label;
  //           cameraSelect.append(option);
  //         }
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(`${err.name}: ${err.message}`);
  //     });
  // }
  // // when we got a message from a signaling server
  // conn.onmessage = function (msg) {
  //   console.log('Got message', msg.data);
  //   const data = JSON.parse(msg.data);

  //   function handleLogin(success) {
  //     if (success === false) {
  //       alert('try a different username');
  //     } else {
  //       // loginPage.style.display = 'none';
  //       // callPage.style.display = 'block';

  //       // Starting a peer connection

  //       getMediaDevice();

  //       // getting local audio stream

  //       const mediaConstraints = {
  //         audio: true,
  //         video: {
  //           width: { min: 640, ideal: 1280, max: 1920 },
  //           height: { min: 480, ideal: 720, max: 1080 },
  //           deviceId: cameraSelect.value,

  //         },
  //       };

  //       navigator.mediaDevices.getUserMedia(mediaConstraints).then((mystream) => {
  //         console.log('Stream connected successfully');
  //         stream = mystream;
  //         localAudio.srcObject = stream;

  //         // using Google public stun server
  //         const configuration = {
  //           iceServers: [{ url: 'stun:stun1.1.google.com:19302' }],
  //         };

  //         yourConn = new RTCPeerConnection(configuration);

  //         stream.getTracks().forEach((track) => {
  //           yourConn.addTrack(track, stream);
  //         });

  //         // setup stream listening
  //         // yourConn.addStream(stream);

  //         // when a remote user adds stream to the peer connection, we display it
  //         // yourConn.onaddstream = function (e) {
  //         // not reqd
  //         // videooff.addEventListener('click', (event) => {
  //         //   // stream.getVideoTracks()[0].enabled = !(stream.getVideoTracks()[0].enabled);

  //         //   if (stream.getVideoTracks()[0].enabled === true) {
  //         //     stream.getVideoTracks()[0].enabled = false;
  //         //     stream.getVideoTracks()[0].stop();
  //         //   } else stream.getVideoTracks()[0].enabled = true;
  //         // });
  //         // not reqd
  //         // audiomute.addEventListener('click', (event) => {
  //         //   stream.getAudioTracks()[0].enabled = !(stream.getAudioTracks()[0].enabled);
  //         // });

  //         const remoteStream = new MediaStream();

  //         remoteAudio.srcObject = remoteStream;

  //         yourConn.addEventListener('track', async (event) => {
  //           // ad2.pause();
  //           // $("#myModal2").modal('hide');

  //           remoteStream.addTrack(event.track, remoteStream);
  //         });

  //         // Setup ice handling
  //         yourConn.onicecandidate = function (event) {
  //           if (event.candidate) {
  //             send({
  //               type: 'candidate',
  //               candidate: event.candidate,
  //             });
  //           }
  //         };
  //       }).catch((error) => {
  //         console.log('error in stream:', error);
  //       });
  //     }
  //   }
  //   switch (data.type) {
  //     case 'login':
  //       handleLogin(data.success);
  //       break;
  //     case 'call':
  //       handlecall(data.success);
  //       break;
  //     // when somebody wants to call us
  //     case 'offer':
  //       handleOffer(data.offer, data.name);
  //       break;
  //     case 'answer':
  //       handleAnswer(data.answer);
  //       break;
  //     // when a remote peer sends an ice candidate to us
  //     case 'candidate':
  //       handleCandidate(data.candidate);
  //       break;
  //     case 'leave':
  //       handleLeave();
  //       break;
  //     default:
  //       break;
  //   }
  // };

  // react wala code
  const callParent = () => {
    // audiosocket.send({
    //   type: 'call',
    //   uuidCall,
    // });
  };

  const data = () => (
    <div>
      <h4 style={{ marginBottom: 5 }}>
        Hi {counsellorName.username} All the Best. You can definitely convert
        this Lead.
      </h4>
      <p style={{ fontSize: 14 }}>
        Your Cues - Learn the names of Parent and Child
        <br />
        Greet and Welcome the Parent to the Orchids Virtual Walk Through.
        <br /> Small Talk Suggestions <br />
        <p style={{ marginTop: 5 }}>
          1.Are you from Bangalore or did you move here ? Follow on questions
          when did you move ? Is your Wife/husband also from here etc <br />
          2.Where are you working ? <br />
          3.How many members in the family?
        </p>
        <br />
      </p>
    </div>
  );
  return (
    <AnimateWrapper>
      <LandingWrapper>
        <ContentWrapper>
          {/* <div style={{
          width: '10%',
        }}
        >
          <img src={boyImg} alt="boy" height="100%" />
        </div> */}
          {/* <Button>
            Call Parent
          </Button> */}
          {/* {isRole === 'Counsellor' && (
          <CallButton
            onClick={callParent}
          >
            <span className="material-icons" />
            Call Parent
          </CallButton>
          )} */}
          <Heading red="red">
            <span
              style={{
                fontWeight: "100",
                fontSize: "3.8vw",
                display: "block",
                letterSpacing: "0.2vw",
              }}
            >
              A Virtual
            </span>
            <span
              style={{
                fontWeight: "400",
                fontSize: "6.2vw",
                letterSpacing: "0.4vw",
                lineHeight: "3vw",
              }}
            >
              Tour
            </span>
          </Heading>
          <Section>
            <Description>
              Let our application take you through a journey of excellence in
              education
            </Description>
          </Section>
          {/* <NextButton
          color="primary"
          rightEdge="right-top"
          small
        >
          LETS BEGIN
        </NextButton> */}
          {/* <div style={{
          width: '10%',
          position: 'relative',
        }}
        >
          <img
            src={girlImg}
            alt="girl"
            height="100%"
            style={{
              position: 'absolute',
              right: '0px',
            }}
          />
        </div> */}
        </ContentWrapper>
        {isRole === "Counsellor" && <FaqModal />}
        {isRole === "Counsellor" && <CueComponent data={data} />}
      </LandingWrapper>
    </AnimateWrapper>
  );
};

export default LandingPage;
