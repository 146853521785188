import React from 'react';
import styled from 'styled-components';
import {
  useDispatch,
} from 'react-redux';
import * as actionConstants from '../../_actions/actionConstants';
import AnimateWrapper from '../../containers/AnimateWrapper';
import {
  DialogueBox,
  Button,
} from '../../ui';
import ThankYouImage from '../../assets/pictures/DesktopIcon.svg';

const Buttons = styled(Button)`
border-radius: 10px;
background-color: #1E4D81;
color: white;
`;
const DialogBox = styled(DialogueBox)`
text-align: center;
background-color: white;
color: #1E4D81;
padding: 8px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
@media only screen and (min-width: 768px) {
  width: 50%;
  height: 40%;
}
@media only screen and (max-width: 767px) {
  width: 90%;
  height: 55%;
}
`;

const SuccessLable = styled.p`
text-align: center;
color: #1E4D81;
font-weight: 400px;
font-size: 15px;
@media only screen and (min-width: 768px) {
  padding-top: 70px;
  padding-bottom: 5px;
  font-size: 25px;
}
@media only screen and (max-width: 767px) {
  padding-top: 60px;
  padding-bottom: 2px;
}
`;

const DivTag = styled.div`
@media only screen and (min-width: 768px) {
  padding: 5px;
}
@media only screen and (max-width: 767px) {
  padding: 2px;
}
`;

const DivTable = styled.div`
padding-top: 20px;
display:table;
text-align: center;
margin-left: 15%;
width: 70%;
margin-right: 15%;
`;
const DivRow = styled.div`
display:table-row;
`;
const DivCell = styled.div`
display:table-cell;
width: 30%;
`;

const Image = styled.img`
@media only screen and (min-width: 768px) {
  height:200px;
  width:200px;
};
@media only screen and (max-width: 767px) {
  height: 150px;
  width: 150px;
}
`;

const PamentInitiationAndThank = () => {
  const dispatch = useDispatch();
  const audio = new Audio('https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/click.mp3');
  function buttonClick1(data) {
    audio.play();
    if (data === 'Pay now') {
      dispatch({ type: actionConstants.PAYMENT_STATUS, text: true });
      dispatch({
        type: actionConstants.STEP_ID,
        payload: {
          id: 33,
          direction: 1,
        },
      });
    }
    if (data === 'Pay later') {
      dispatch({ type: actionConstants.PAYMENT_STATUS, text: false });
      dispatch({
        type: actionConstants.STEP_ID,
        payload: {
          id: 36,
          direction: 1,
        },
      });
    }
  }

  return (
    <AnimateWrapper>
      <DialogBox>
        <Image
          src={ThankYouImage}
          alt="animated"
          style={{
            position: 'absolute', top: 0, transform: 'translate(-50%, -50%)',
          }}
        />
        <DivTag>
          <SuccessLable>
            Thank you for walking along in the virtual journey
            <br />
            Please prossed by filling the application form
          </SuccessLable>
        </DivTag>
        <DivTable>
          <DivRow>
            <DivCell>
              <Buttons
                onClick={() => buttonClick1('Pay now')}
              >
                PAY NOW
              </Buttons>
            </DivCell>
            &nbsp;&nbsp;
            <DivCell>
              <Buttons
                onClick={() => buttonClick1('Pay later')}
              >
                PAY LATER
              </Buttons>
            </DivCell>
          </DivRow>
        </DivTable>
      </DialogBox>
    </AnimateWrapper>
  );
};

export default PamentInitiationAndThank;
