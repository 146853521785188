/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { VideoText, AnimateWrapper } from "../../containers";
import { Loader } from "../../ui";
import img from "../../assets/pictures/boy.svg";
import * as actionsCreators from "../../_actions/actionCreators";
import CueComponent from "../../containers/CueComponent";
import FaqModal from "../../containers/Modal";
// import { fetchLiteracyVideo } from '../../_actions/actionCreators';

const NumeracyVideo = () => {
  const dispatch = useDispatch();
  // const meetingId = useSelector((state) => state.personalInfo.meeting_id);
  // const isParent = useSelector((state) => state.personalInfo.isParent);
  const videos = useSelector((state) => state.questions.summaryVideo);
  const videoIndex = useSelector((state) => state.questions.currVideoIndex);
  const isLoading = useSelector((state) => state.common.loading);
  // const resultSummary = useSelector((state) => state.questions.resultSummary);
  // const summaryKey = useSelector((state) => state.questions.summaryKey);
  // eslint-disable-next-line no-unused-vars
  const [dataInfo, setDataInfo] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [suggestions, setSuggestions] = useState([]);
  const isRole = useSelector((state) => state.personalInfo.role);

  useEffect(() => {
    if (videos && videos.numeracy && videos.numeracy[videoIndex]) {
      dispatch(actionsCreators.changePageTitle("Numeracy"));
      // dispatch(actionsCreators.changePageTitle(videos.numeracy[videoIndex].title));
    }
  }, [dispatch, videos, videoIndex]);
  const data = () => (
    <div>
      <p style={{ fontSize: 14 }}>
        Counselor Cue : <br />
        Ask the Parent if their child in good at Math
        <br />
        Tell the Parent that we make Math interesting for the child.
        <br />
        Tell them we use teaching aids like
        <br />
        • Numicon shapes,
        <br />
        • Cuisenaire Rods,
        <br />
        • Tens Frames, etc.
        <br />
        Tell the parent that we expose the children to multi sensor activities,
        <br />
        learn by doing and discovering
        <br />
      </p>
    </div>
  );

  // useEffect(() => {
  //   if (resultSummary
  //     && resultSummary.literacy_preferred_courses
  //     && resultSummary.literacy_score.length >= 2) {
  //     const dataToSave = resultSummary.literacy_preferred_courses[1].map((item) => ({
  //       name: item.category_name,
  //       result: item.score,
  //     }));
  //     const suggest = resultSummary.literacy_preferred_courses[0].map((item, index) => ({
  //       label: item,
  //       name: item,
  //       value: index,
  //       checked: true,
  //     }));
  //     setDataInfo(dataToSave);
  //     setSuggestions(suggest);
  //   }
  //   // dispatch(actionCreators.fetchLiteracyVideo(meetingId, !isParent));
  // }, [dispatch, meetingId, isParent, resultSummary]);

  return (
    <AnimateWrapper key={videoIndex}>
      <VideoText
        data={
          videos.numeracy &&
          videos.numeracy[videoIndex] &&
          videos.numeracy[videoIndex].title
        }
        videoSrc={
          videos.numeracy &&
          videos.numeracy[videoIndex] &&
          videos.numeracy[videoIndex].video
        }
        img={img}
        // imgPosition="top-right-img"
      />
      {isRole === "Counsellor" && <FaqModal />}

      {isRole === "Counsellor" && <CueComponent data={data} />}

      <Loader open={isLoading} />
    </AnimateWrapper>
  );
};

export default NumeracyVideo;
