/* eslint-disable */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../_actions/actionCreators";
import { VideoText } from "../../containers";
import { Loader } from "../../ui";
import img from "../../assets/pictures/boy.svg";
import CueComponent from "../../containers/CueComponent";
import FaqModal from "../../containers/Modal";

// const data = '';

const Introduction1 = () => {
  const dispatch = useDispatch();
  const meetingId = useSelector((state) => state.personalInfo.meeting_id);
  const introVideo = useSelector((state) => state.personalInfo.introVideo);
  const isLoading = useSelector((state) => state.common.loading);
  const isRole = useSelector((state) => state.personalInfo.role);
  // console.log('Intro  Video++++', introVideo);

  useEffect(() => {
    dispatch(actionCreators.fetchIntroVideo(meetingId));
  }, [dispatch, meetingId]);

  const data = () => (
    <div>
      <p style={{ fontSize: 14 }}>
        Counselor Cue : Voice over when the video is playing
        <br />
        Talk about our main feature
        <br />
        Large Campus, spacious and well lit classrooms,
        <br />
        digiboards, 2 teachers per class, reading room, dance room,
        <br />
        music room, swimming, skating ring, basketball court, computer labs,
        robotics labs.
        <br />
        What else would you want to know more about our school?
      </p>
    </div>
  );
  return (
    <div>
      <VideoText
        // data={introVideo && introVideo[0].title}
        videoSrc={introVideo && introVideo[0] && introVideo[0].videos}
        img={img}
        imgPosition="top-right-img"
      />
      {isRole === "Counsellor" && <FaqModal />}

      {isRole === "Counsellor" && <CueComponent data={data} />}

      <Loader open={!introVideo || isLoading} />
    </div>
  );
};

export default Introduction1;
