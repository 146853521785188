export const ON_FETCH_CATEGORY_VIDEOS_FAIL = 'fetchCategoryVideosFail';
export const ON_FETCH_CATEGORY_VIDEOS_SUCCESS = 'fetchCategoryVideosSuccess';
export const ON_FETCH_FEATURE_VIDEOS_SUCCESS_ONE = 'fetchFeatureVideosSuccess';
export const ON_FETCH_FEATURE_VIDEOS_SUCCESS_TWO = 'fetchFeatureVideosSuccessTwo';
export const ON_FETCH_VIDEOS_START = 'fetchingVideosStart';
export const INCREMENT_STEP_SPECIAL_PROGRAM = 'incrementStepSpecialProgram';
export const DECREMENT_STEP_SPECIAL_PROGRAM = 'decrementStepSpecialProgram';
export const INCREMENT_STEP_FEATURE_LIST_ONE = 'incrementStepFeatureList';
export const DECREMENT_STEP_FEATURE_LIST_ONE = 'decrementStepFeatureList';
export const INCREMENT_STEP_FEATURE_LIST_TWO = 'incrementStepFeatureListOne';
export const DECREMENT_STEP_FEATURE_LIST_TWO = 'decrementStepFeatureListTwo';
export const ON_BACK_CLICK = 'backClicked';
