/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import styled from 'styled-components';
import fetchVideosByCategory from '../../_actions/CategoryVideos/videoCategoryActions';
import girlImg from '../../assets/pictures/girl.svg';
import { VideoText } from '../../containers';
import { Loader } from '../../ui';
import { useSocket } from '../../utils';
import * as actionConstants from '../../_actions/actionConstants';
import * as actionCreators from '../../_actions/actionCreators';
import { updateViewed } from '../FeatureLIst/utilFunc';
import FaqModal from '../../containers/Modal';

// const Heading = styled.h2`
//   margin: 15px 0px;
//   font-weight: 400;
//   text-align: center;
//   color: #204060;
// `;

const getCategories = (currCat) => {
  switch (currCat) {
    case 'Sports and PE': return 'Sports';
    case 'Performing Arts Program': return 'Arts';
    case 'Personalized Learning Kits': return 'Kits';
    case 'Competitions/Events': return 'Competitions';
    case 'Safety and Security': return 'Safety';
    case 'Computer/Robotics': return 'Technology';
    default: return 'Features';
  }
};

function VideoCategory() {
  const dispatch = useDispatch();
  const socket = useSocket();
  const [textTitle, setTextTitle] = useState('');
  const isRole = useSelector((state) => state.personalInfo.role);

  const {
    // eslint-disable-next-line no-unused-vars
    personalInfo,
    videoCategories: {
      videoLinks = [],
      featureVideoLinksOne = [],
      featureVideoLinksTwo = [],
      currentStepSpecialProgram, currentStepFeatureListOne, currentStepFeatureListTwo,
      loading,
      isBack,
    },
    common, selectedCategories,
    specialPrograms: { categoriesViewedInfo: programCatViewedInfo },
    schoolFeatures: { categoriesViewedInfo: featuresCatViewedInfo },
  } = useSelector((state) => state);

  const updateViewedCategories = () => {
    // const { categoriesViewedInfo = [] } = state.specialPrograms;
    const tempArray = [
      { data: programCatViewedInfo, dispatchConstant: actionConstants.UPDATE_VIEWED_PROGRAMS },
      { data: featuresCatViewedInfo, dispatchConstant: actionConstants.UPDATE_VIEWED_PROGRAMS }];
    tempArray.forEach((item) => {
      if (item.data) {
        const dispatchPayload = {
          type: item.dispatchConstant,
          payload: { results: updateViewed(item.data) },
        };
        dispatch(dispatchPayload);
        if (socket) socket.trigger('update_categories_viewed_info', dispatchPayload);
      }
    });
  };

  const {
    featureListSubCategoryIds, specialProgramsSubCategoryIds,
  } = selectedCategories;

  const getIds = (list) => list.map((item) => item.id);

  useEffect(() => {
    const currentStep = common.stepId === 23
      ? currentStepFeatureListOne : currentStepFeatureListTwo;
    const featureVideos = common.stepId === 23 ? featureVideoLinksOne : featureVideoLinksTwo;
    const videos = featureVideos;
    const title = videos.length
      ? videos[currentStep - 1].sub_category.sub_category_name
      : 'Features';
    dispatch(actionCreators.changePageTitle(getCategories(title)));
    setTextTitle(title);
  }, [
    dispatch,
    currentStepFeatureListTwo,
    currentStepFeatureListOne,
    featureVideoLinksTwo,
    featureVideoLinksOne,
    common.stepId,
  ]);

  useEffect(() => {
    if (socket) {
      socket.bind('step_change', (data) => {
        dispatch({
          type: actionConstants.STEP_ID,
          payload: {
            id: data.step,
            direction: data.direction,
          },
        });
      });
    }

    socket.bind('change_video_step', (data) => {
      dispatch({
        type: data.type,
        payload: data.payload,
      });
    });

    socket.bind('handle_back', (data) => {
      dispatch({
        type: data.type,
        payload: data.payload,
      });
    });
  }, [socket, dispatch]);

  useEffect(() => {
    if (!isBack) {
      dispatch(fetchVideosByCategory({
        meetingId: personalInfo.meeting_id,
        category: common.stepId === 13 ? 3 : 4,
        subCategory: common.stepId === 13
          ? getIds(specialProgramsSubCategoryIds) : getIds(featureListSubCategoryIds),
        stepId: common.stepId,
      }));
    }
    updateViewedCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDescription = () => {
    const currentStep = common.stepId === 23
      ? currentStepFeatureListOne : currentStepFeatureListTwo;
    const featureVideos = common.stepId === 23 ? featureVideoLinksOne : featureVideoLinksTwo;
    const step = common.stepId === 13 ? currentStepSpecialProgram : currentStep;
    const videos = common.stepId === 13 ? videoLinks : featureVideos;

    return videos.length ? videos[step - 1].description : 'No Description found';
  };

  const getVideoSrc = () => {
    const currentStep = common.stepId === 23
      ? currentStepFeatureListOne : currentStepFeatureListTwo;
    const featureVideos = common.stepId === 23 ? featureVideoLinksOne : featureVideoLinksTwo;
    const step = common.stepId === 13 ? currentStepSpecialProgram : currentStep;
    const videos = common.stepId === 13 ? videoLinks : featureVideos;
    return videos.length ? videos[step - 1].videos : null;
  };

  useEffect(() => {
    const currentStep = common.stepId === 23
      ? currentStepFeatureListOne : currentStepFeatureListTwo;
    const featureVideos = common.stepId === 23 ? featureVideoLinksOne : featureVideoLinksTwo;
    const step = common.stepId === 13 ? currentStepSpecialProgram : currentStep;
    const videos = common.stepId === 13 ? videoLinks : featureVideos;
    console.log('videos.length ? videos[step - 1].video...');
    console.log(videos.length ? videos[step - 1].videos : null);
  }, [common.stepId, currentStepFeatureListOne,
    currentStepFeatureListTwo,
    currentStepSpecialProgram, featureVideoLinksOne, featureVideoLinksTwo, videoLinks]);

  return (
    <>
      {/* {
        getTitle()
          ? (
            <>
              <Heading>{getTitle()}</Heading>
              <hr />
            </>
          )
          : ''
      } */}

      <VideoText imgPosition="bottom-left-img" img={girlImg} data={getDescription()} videoSrc={getVideoSrc()} title={textTitle} />
      {isRole === 'Counsellor' && <FaqModal />}

      <Loader open={loading} />
    </>
  );
}

export default VideoCategory;
