/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
// import { motion } from 'framer-motion';
import { useSelector, useDispatch } from "react-redux";
import * as actionConstants from "../../_actions/actionConstants";
import * as actionCreators from "../../_actions/actionCreators";
// import TextPlusButton from '../../containers/pageAndButton';
import AnimateWrapper from "../../containers/AnimateWrapper";
import JourneyImage from "../../assets/pictures/Journeyselector.svg";

import { useSocket } from "../../utils";
import { DialogueBox, RadioCheckBox } from "../../ui";
import CueComponent from "../../containers/CueComponent";
import FaqModal from "../../containers/Modal";

const PARENT_CHILD_SELECTION = "parent_child_selection";

const CheckBoxRadio = styled(RadioCheckBox)`
  border-radius: 20px;
  border: 10px solid red;
`;
const DialogBox = styled(DialogueBox)`
  text-align: center;
  background-color: white;
  color: #1e4d81;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media only screen and (min-width: 768px) {
    width: 50%;
    height: 50%;
  }
  @media only screen and (max-width: 767px) {
    width: 90%;
    height: 60%;
  }
`;

const SuccessLable = styled.p`
  text-align: center;
  color: #1e4d81;
  font-weight: 400px;
  font-size: 15px;
  @media only screen and (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 5px;
    font-size: 25px;
  }
  @media only screen and (max-width: 767px) {
    padding-top: 60px;
    padding-bottom: 2px;
  }
`;
const DivTag = styled.div`
  @media only screen and (min-width: 768px) {
    padding: 5px;
  }
  @media only screen and (max-width: 767px) {
    padding: 2px;
  }
`;

const DivTable = styled.div`
  width: 90%;
  margin-left: 5%;
  padding: 20px;
  display: table;
  text-align: center;
`;
const DivRow = styled.div`
  display: table-row;
`;
const DivCell = styled.div`
  display: table-cell;
  width: 30%;
`;

const ImageTag = styled.img`
  @media only screen and (min-width: 768px) {
    height: 200px;
    width: 200px;
  }
  @media only screen and (max-width: 767px) {
    height: 150px;
    width: 150px;
  }
`;
const ParentChildInfo = () => {
  const dispatch = useDispatch();
  const isParent = useSelector((state) => state.personalInfo.isParent);
  const isRole = useSelector((state) => state.personalInfo.role);
  const checkSpeedInterval = 2000;
  const imageAddr =
    "https://images.unsplash.com/photo-1601275225755-f6a6c1730cb1";
  const downloadSize = 4916219; // oldsize 16913; // bytes
  const [speed, setSpeed] = useState(5.0);
  const [speedflag, setSpeedflag] = useState(0);
  const [answer, setAnswer] = useState("");
  const socket = useSocket();
  const audio = new Audio(
    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/click.mp3"
  );

  const measureConnectionSpeed = () => {
    // console.log('speedtest.....');
    const startTime = new Date().getTime();
    let endTime;
    const download = new Image();
    function showResults() {
      const duration = (endTime - startTime) / 1000;
      const bitsLoaded = downloadSize * 8;
      const speedBps = (bitsLoaded / duration).toFixed(2);
      const speedKbps = (speedBps / 1024).toFixed(2);
      const speedMbps = (speedKbps / 1024).toFixed(2);
      setSpeed(speedMbps);
    }
    download.onload = function () {
      endTime = new Date().getTime();
      showResults();
    };

    // startTime = (new Date()).getTime();
    const cacheBuster = `?nnn=${startTime}`;
    download.src = imageAddr + cacheBuster;
    console.log("Speed(Mbps) in 2secs interval", speed);
  };

  const checkNetSpeed = useCallback(() => {
    measureConnectionSpeed();
    if (speed >= 5) {
      if (speedflag !== 0) {
        dispatch(actionCreators.changeNetSpeed("high"));
      }
      setSpeedflag(0);
    } else if (speed < 5 && speed >= 1) {
      if (speedflag !== 1) {
        dispatch(actionCreators.changeNetSpeed("medium"));
        console.log("speed is medium");
      }
      setSpeedflag(1);
    } else {
      if (speedflag !== 2) {
        dispatch(actionCreators.changeNetSpeed("low"));
        console.log("speed is low");
      }
      setSpeedflag(2);
    }
    console.log("Speed(Mbps) in 2secs interval", speed);
  }, [measureConnectionSpeed, speed, speedflag]);

  useEffect(() => {
    if (socket) {
      socket.bind(PARENT_CHILD_SELECTION, (data) => {
        dispatch({
          type: actionConstants.PARENT_CHILD_COUNSELLING,
          text: data.isParent,
        });
      });
    }
  }, [socket, dispatch]);

  useEffect(() => {
    if (isParent === true) {
      setAnswer("Just My Parents");
    } else if (isParent === false) {
      setAnswer("Parents and Me");
    }
  }, [isParent]);

  useEffect(() => {
    const speedTracker = setInterval(checkNetSpeed, checkSpeedInterval);
    return () => {
      clearInterval(speedTracker);
    };
  }, [checkNetSpeed]);

  function buttonClick1(data) {
    setAnswer(data.target.value);
    audio.play();
    if (data.target.value === "Just My Parents") {
      dispatch({ type: actionConstants.PARENT_CHILD_COUNSELLING, text: true });
      socket.trigger(PARENT_CHILD_SELECTION, { isParent: true });
    } else if (data.target.value === "Parents and Me") {
      dispatch({ type: actionConstants.PARENT_CHILD_COUNSELLING, text: false });
      socket.trigger(PARENT_CHILD_SELECTION, { isParent: false });
    }
  }

  const data = () => (
    <div>
      <h4 style={{ marginBottom: 5 }}>Rapport building – 3 – 5 minutes</h4>
      <p style={{ fontSize: 14 }}>
        Start with a small pep talk:
        <br />
        1. Is your child along with you now ?
        <br />
        2. Collect Student Details.
      </p>
    </div>
  );

  return (
    <AnimateWrapper>
      <DialogBox>
        <ImageTag
          src={JourneyImage}
          alt="animated"
          style={{
            position: "absolute",
            top: 0,
            transform: "translate(-50%, -50%)",
          }}
        />
        <DivTag>
          <SuccessLable>
            So before we start on the awesome journey around our school, we
            would like to know if your child is along with you in this tour or
            it is just you?
          </SuccessLable>
        </DivTag>
        <DivTable>
          <DivRow>
            <DivCell>
              <CheckBoxRadio
                type="checkbox"
                checked={answer === "Just My Parents"}
                value="Just My Parents"
                label="Only Parents"
                onChange={(e) => buttonClick1(e)}
              />
            </DivCell>
            <DivCell>
              <CheckBoxRadio
                type="checkbox"
                checked={answer === "Parents and Me"}
                value="Parents and Me"
                label="Parents with child"
                onChange={(e) => buttonClick1(e)}
              />
            </DivCell>
          </DivRow>
        </DivTable>
      </DialogBox>
      {isRole === "Counsellor" && <FaqModal />}
      {isRole === "Counsellor" && <CueComponent data={data} />}
    </AnimateWrapper>
  );
};

export default ParentChildInfo;
