const theme = {
  palette: {
    button: {
      // main: '#dd5279',
      main: '#1180F7',
      // gradient: 'linear-gradient(90deg,rgba(221,82,121,1) 0%,rgba(253,165,46,1) 100%)',
      gradient: 'linear-gradient(#3A94F7 ,#0557B4, #1180F7)',
      secondaryGradient: 'linear-gradient(90deg, rgba(238,125,108,1) 0%, rgba(213,77,123,1) 100%)',
    },
    primary: {
      // main: '#f99b9b',
      // gradient: 'linear-gradient(90deg, rgba(249,155,155,1) 0%, rgba(241,198,120,1) 100%)',
      textColor: '#1E4D81',
      // textColor: 'black',
      main: 'rgba(119,145,230,0.6)',
      // main: 'white',
      left: 'rgba(249,155,155,1)',
      right: 'rgba(241,198,120,1)',
    },
    secondary: {
      textColor: '#285e7f',
    },
  },
};

export default theme;
