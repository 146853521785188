/* eslint-disable */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import axios from 'axios';
import urls from "../../urls";
import { Loader } from "../../ui";
import { AnimateWrapper, VideoText } from "../../containers";
import img from "../../assets/pictures/boy.svg";
import CueComponent from "../../containers/CueComponent";
import FaqModal from "../../containers/Modal";

// const data = 'Our Principal Message for you!';

const PrincipalVideo = () => {
  const isRole = useSelector((state) => state.personalInfo.role);
  const [videoSrc, setVideoSrc] = useState(null);
  const [videoTitle, setVideoTitle] = useState(null);
  const [loading, setLoading] = useState(true);
  const meetingId = useSelector((state) => state.personalInfo.meeting_id);
  const longString =
    "Here are Students and Parent testimonials that affirm the value and quality  of our educational services/ Offerings. This shows the trust parents put in us, to foster the wellbeing and development of their children.";
  const data = () => (
    <div>
      <p style={{ fontSize: 14 }}>
        Why I am showing you these students and parents testimonials?
      </p>{" "}
      <p style={{ fontSize: 14 }}>{longString}</p>
    </div>
  );
  useEffect(() => {
    setLoading(true);
    async function functionToGetgradeList() {
      const response = await fetch(
        `${urls.specialProgramsAndFeatures}?meeting_id=${meetingId}&category=8`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const fetchData = await response.json();
      return fetchData;
    }
    if (meetingId) {
      functionToGetgradeList()
        .then((res) => {
          if (res && res.result.length !== 0) {
            setVideoSrc(res.result[0].videos);
            setVideoTitle(res.result[0].title);
            setLoading(false);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error, "dddddddd");
          setLoading(false);
        });
    }
  }, [meetingId]);
  return (
    <AnimateWrapper>
      <VideoText
        data={videoTitle}
        videoSrc={videoSrc}
        img={img}
        imgPosition="top-right-img"
      />
      {isRole === "Counsellor" && <CueComponent data={data} />}
      {isRole === "Counsellor" && <FaqModal />}

      <Loader open={loading} />
    </AnimateWrapper>
  );
};

export default PrincipalVideo;
