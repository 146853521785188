import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import AnimateWrapper from "../../containers/AnimateWrapper";
import { DialogueBox } from "../../ui";
import QuestonImage from "../../assets/pictures/ANyquestionsDesktopicon.svg";
import CueComponent from "../../containers/CueComponent";
import FaqModal from "../../containers/Modal";

const QuestionsPage = () => {
  const isRole = useSelector((state) => state.personalInfo.role);
  const DialogBox = styled(DialogueBox)`
    text-align: center;
    background-color: white;
    color: #1e4d81;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media only screen and (min-width: 768px) {
      width: 50%;
      min-height: 30%;
    }
    @media only screen and (max-width: 767px) {
      width: 90%;
      min-height: 40%;
    }
  `;

  const SuccessLable = styled.h1`
    text-align: center;
    color: #1e4d81;
    @media only screen and (min-width: 768px) {
      padding-top: 30px;
      padding-bottom: 5px;
    }
    @media only screen and (max-width: 767px) {
      padding-top: 25px;
      padding-bottom: 5px;
    }
  `;

  const Title = styled.p`
    text-align: center;
    color: #1e4d81;
    @media only screen and (min-width: 768px) {
      padding: 5px;
      padding-top: 20px;
      font-size: 25px;
    }
    @media only screen and (max-width: 767px) {
      padding: 2px;
      font-size: 15px;
    }
  `;
  const DivTag = styled.div`
    @media only screen and (min-width: 768px) {
      padding: 5px;
    }
    @media only screen and (max-width: 767px) {
      padding: 2px;
    }
  `;
  const longString =
    "If parents try to Negotiate  show them the importance of Value over price. Try to close the admission based on the interest level.";
  const data = () => (
    <div>
      <p style={{ fontSize: 14 }}>
        Explain About the fee structure and admission process. How to complete
        the admission process easily and smoothly online.
      </p>{" "}
      <p style={{ fontSize: 14 }}>{longString}</p>
    </div>
  );
  return (
    <>
      <AnimateWrapper>
        <DialogBox>
          <DivTag style={{ textAlign: "center" }}>
            <img
              src={QuestonImage}
              alt="animated"
              height="100px"
              width="100px"
              style={{
                position: "absolute",
                top: 0,
                transform: "translate(-50%, -50%)",
              }}
            />
          </DivTag>
          <DivTag>
            <SuccessLable>Questions</SuccessLable>
          </DivTag>
          <DivTag>
            <Title>
              {" "}
              Would you like to know anything more about our school?{" "}
            </Title>
          </DivTag>
        </DialogBox>
      </AnimateWrapper>
      {isRole === "Counsellor" && <FaqModal />}

      {isRole === "Counsellor" && <CueComponent data={data} />}
    </>
  );
};
export default QuestionsPage;
