/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import urls from '../../urls';
import {
  Loader,
  ReactSelect,
  Input,
  Button,
  DialogueBox,
  TextArea,
  Modal,
} from '../../ui';
import * as actionConstants from '../../_actions/actionConstants';
import * as actions from '../../_actions/personalInfo/actions';
import { AnimateWrapper } from '../../containers';

const ReactSelectOption = styled(ReactSelect)`
margin:0;
padding:0;
`;

const DivTable = styled.div`
width: 100%;
display:table;
`;

const DivAdderssTable = styled.div`
width:100%;
padding-left:15px;
padding-right:15px;
display: flex;
`;
const DivRow = styled.div`
 display:table-row;
`;

const DivPaymentRow = styled.div`
background-color: rgb(255, 249, 255);
padding:5px;
`;

const DivCell = styled.div`
display:table-cell;
@media only screen and (min-width: 768px) {
  width:50%;
}
@media only screen and (max-width: 767px) {
  width: 50%;
}
`;

const TermsAndConditionRow = styled.div`
display:table-row;
text-align: center;
color: black;
margin: auto;
`;

const SubHeddingTag = styled.span`
color:  #1E4D81;
@media only screen and (min-width: 768px) {
  padding-left: 35px;
  padding-right: 35px;
  font-size: 20px;
}
@media only screen and (max-width: 767px) {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
}
`;

const ParentDiv = styled.div`
width: 100%;
`;
const DialogBox = styled(DialogueBox)`
margin-top: 20px;
margin-bottom: 10px;
@media only screen and (min-width: 768px) {
    overflow: auto;
    width: 80%;
    height: 75%;
  }
@media only screen and (max-width: 767px) {
    overflow: auto;
    width: 90%;
    height: 70%;
  }
`;

const ParaGraph = styled.h4`
color: white;
padding: 5px;
`;
const DateInput = styled(Input)`
text-align: left
`;

const Lable = styled.span`
transform-origin: left center;
color: white;
font-family: sans-serif;
font-weight: bold;
letter-spacing: 0.01em;
font-size: 15px;
box-sizing: border-box;
padding-left: 35px;
text-transform: uppercase;
`;

const Span = styled.strong`
color: #1E4D81
`;

const Title = styled.strong`
color: white;
padding-top: 5px;
font-size: 16px;
text-align: center;
`;

const SubTitle = styled.h3`
margin-top: 1.5%;
color: white;
text-align: center;
`;

const CheckBoxInput = styled.input`
  height: 15px;
  width: 25px;
  border-radius: 50px;
`;

const DivPamentTable = styled.div`
@media only screen and (min-width: 768px) {
    width: 98%;
    margin-left: 1%
    margin-right: 1%
  }
@media only screen and (max-width: 767px) {
    width:100%
  }
text-align: left;
padding: 10px;
border-radius: 10px;
border: 1px solid white;
`;
const DivFooterTable = styled.div`
display: flex;
padding-top: 10px;
text-align: center;
`;

const DivAdderssCellOne = styled.div`
width: 83%;
`;

const DivAdderssCellTwo = styled.div`
@media only screen and (min-width: 768px) {
    margin-top: 30px
  }
@media only screen and (max-width: 767px) {
    margin-top: 5%
  }
margin-left: 2%;
width: 15%;
`;

const ModelForeTerms = styled(Modal)`
border-radius: 50px;
`;

const BillingDetails = () => {
  const dispatch = useDispatch();
  const audio = new Audio('https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/click.mp3');
  const [isOpen, setOpen] = useState(false);
  const fullPersomalInfoData = useSelector((state) => state.personalInfo);
  const termsOpen = useSelector((state) => state.personalInfo.openTermsAndConditionsDialog);
  const fullStateData = useSelector((state) => state.personalInfo);
  const stepId = useSelector((state) => state.common.stepId);
  const [acceptTermsAndConditions, setAcceptTermaAndConditions] = useState(false);
  const applicationDetailsState = useSelector((state) => state.personalInfo.applicationDetails);
  const termsAndConditonState = useSelector((state) => state.personalInfo.AgreeTermsAndConditions);
  const [applicationDetails, setApplicationDetails] = useState([
    {
      student_name: '',
      parentName: '',
      phoneNumber: '',
      emailId: '',
      address: '',
      gradeId: '',
      dateofBirth: '',
    },
  ]);
  const isLoading = useSelector((state) => state.common.loading);
  const [gradeList, setGradeList] = useState([]);
  const curday = (sp, formate) => {
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    if (formate) {
      return (mm + sp + dd + sp + yyyy);
    }
    return (yyyy + sp + mm + sp + dd);
  };

  function functionToChangeGradeList(data) {
    if (data && data.length !== 0) {
      const array = [];
      for (let i = 0; i < data.length; i += 1) {
        array.push({ value: data[i].id, label: data[i].grade });
      }
      setGradeList(array);
    }
  }

  useEffect(() => {
    async function functionToGetgradeList() {
      const response = await fetch(urls.gradeListApi, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      const fetchData = await response.json();
      return fetchData;
    }
    if (fullStateData && fullStateData.gradeList && fullStateData.gradeList.length === 0) {
      functionToGetgradeList().then((data) => {
        functionToChangeGradeList(data);
      });
    } else {
      functionToChangeGradeList(fullStateData.gradeList);
    }

    if (fullStateData && fullStateData.child && fullStateData.lead) {
      const data = [
        {
          student_name: fullStateData.child && fullStateData.child.child_name,
          parentName: fullStateData.lead && fullStateData.lead.lead_name,
          phoneNumber: fullStateData.lead && fullStateData.lead.lead_contact_no,
          emailId: fullStateData.lead && fullStateData.lead.lead_email_id,
          address: fullStateData.lead && fullStateData.lead.lead_address,
          gradeId: {
            value: fullStateData.child
            && fullStateData.child.child_class && fullStateData.child.child_class.id,
            label: fullStateData.child
            && fullStateData.child.child_class && fullStateData.child.child_class.grade,
          },
          dateofBirth: fullStateData.child.Date_of_birth,
        },
      ];
      setApplicationDetails(data);
      dispatch({ type: actionConstants.APPLICATION_FORM, details: data });
    }
  }, []);

  function saveData() {
    audio.play();
    if (applicationDetailsState === '' || (applicationDetailsState && !applicationDetailsState[0].student_name) || (applicationDetailsState && !applicationDetailsState[0].phoneNumber) || (applicationDetailsState && !applicationDetailsState[0].parentName) || (applicationDetailsState && !applicationDetailsState[0].address) || (applicationDetailsState && !applicationDetailsState[0].dateofBirth)) {
      window.alert('please fill your application form ');
      return;
    }
    const updateData = {
      session_year: fullStateData.lead.academic_year,
      branch_id: fullStateData.lead.branch.letseduvate_id,
      grade_id: fullStateData.applicationDetails && fullStateData.applicationDetails[0].gradeId.value,
      letseduvate_id: fullStateData.child.child_class.letseduvate_id,
      address: (fullStateData.applicationDetails && fullStateData.applicationDetails[0].address) || null,
      application_date: curday('-'),
      child_id: fullStateData.child.id,
      date_of_birth: fullStateData.applicationDetails && fullStateData.applicationDetails[0].dateofBirth,
      enquiry_code: fullStateData.lead.enquiry_no,
      father_email: fullStateData.applicationDetails && fullStateData.applicationDetails[0].emailId,
      father_mobile_no: fullStateData.applicationDetails && fullStateData.applicationDetails[0].phoneNumber,
      father_name: fullStateData.applicationDetails && fullStateData.applicationDetails[0].parentName,
      gender: fullStateData.child.child_gender && fullStateData.child.child_gender === 'Male' ? 1 : 2,
      lead_id: fullStateData.lead.id,
      mother_email: fullStateData.lead.mother_email_id,
      mother_mobile_no: fullStateData.lead.mother_contact_no,
      mother_name: fullStateData.lead.mother_name,
      nearest_locality: null,
      opting_class: null,
      phone: fullStateData.applicationDetails && fullStateData.applicationDetails[0].phoneNumber,
      student_name: fullStateData.applicationDetails && fullStateData.applicationDetails[0].student_name,
    };
    dispatch((actions.fetchApplicationDetail(updateData)));
  }

  function handleDetails(information, key) {
    setApplicationDetails((data) => {
      const newData = [...data];
      switch (key) {
        case key:
          newData[0][key] = information;
          dispatch({ type: actionConstants.APPLICATION_FORM, details: newData });
          dispatch({ type: actionConstants.APPLICATION_EDIT_STATUS, status: true });
          return newData;
        default:
          return null;
      }
    });
  }

  function handleTermsAndConditons(e) {
    setAcceptTermaAndConditions(e.target.checked);
    if (stepId && fullStateData && !fullStateData.ApplicationUpdatedData) {
      saveData();
    }
    dispatch({ type: actionConstants.AGREE_TERMS_AND_CONDITIONS, status: e.target.checked });
  }

  useEffect(() => {
    if (stepId && applicationDetailsState) {
      setApplicationDetails(applicationDetailsState);
    }
  }, [stepId, applicationDetailsState]);

  useEffect(() => {
    if (stepId) {
      setOpen(termsOpen);
    }
  }, [stepId, termsOpen]);

  useEffect(() => {
    if (stepId) {
      setAcceptTermaAndConditions(termsAndConditonState);
    }
  }, [stepId, termsAndConditonState]);

  const handleModal = () => {
    setOpen(false);
    dispatch({ type: actionConstants.OPEN_TERMS_AND_CONDITIONS, status: false });
  };

  function handleNextPage() {
    dispatch((actions.fetchAirPayResponse(fullPersomalInfoData)));
    dispatch({
      type: actionConstants.STEP_ID,
      payload: {
        id: 34,
        direction: 1,
      },
    });
  }

  const termsAndConfitionsModel = () => {
    let review = null;
    review = (
      <ModelForeTerms open={isOpen} closeHandler={handleModal} large style={{ borderRadius: '20px', background: '#014c93', opacity: 1 }}>
        <div style={{ padding: '30px' }}>
          <div style={{ padding: '30px', border: '1px solid white', borderRadius: '10px' }}>
            <h2>
              <ParaGraph>
                Terms &amp; Conditions : &nbsp;&nbsp;
              </ParaGraph>
            </h2>
            <ParaGraph>
              1. Please be informed that additional transaction
              charges are applicable. For more information
              please contact your school administrator.
            </ParaGraph>
            <ParaGraph>
              2. LetsEduvate will not store any of
              your Bank credentials / Card details.
              The system will redirect you to concerned
              Bank pages only to complete your transaction.
            </ParaGraph>
            <ParaGraph>
              3. No refund(s)/ No Cancellations are allowed for the transactions done
              through this channel.
            </ParaGraph>
            <ParaGraph>
              4. In case of any dispute regarding the payments, you are requested to
              contact school administrator.
            </ParaGraph>
            <ParaGraph>
              5. Terms and conditions include Online Payment Fee charges, Refund Policy,
              Privacy Policy, Withdrawal from School, Governing Law, Jurisdiction etc.
            </ParaGraph>
          </div>
          <DivFooterTable>
            <TermsAndConditionRow>
              <Title>I / We acknowledge and agree to the terms And conditions</Title>
              <CheckBoxInput
                value={acceptTermsAndConditions}
                checked={acceptTermsAndConditions}
                type="checkbox"
                onChange={(e) => handleTermsAndConditons(e)}
              />
            </TermsAndConditionRow>
          </DivFooterTable>
        </div>
        {termsAndConditonState === true
         && (
         <div style={{ paddingBottom: '20px', paddingRight: '20px', textAlign: 'right' }}>
           <Button style={{ backgroundColor: 'white', color: ' #1E4D81', width: '1%' }} onClick={handleNextPage}>NEXT</Button>
         </div>
         )}
      </ModelForeTerms>
    );
    return review;
  };

  return (
    <AnimateWrapper>
      <DialogBox>
        <ParentDiv>
          <DivTable>
            <DivRow>
              <DivCell>
                <Input required label="Child Name" value={applicationDetails[0].student_name} onChange={(e) => handleDetails(e.target.value, 'student_name')} />
              </DivCell>
              <DivCell>
                <Input required label="Parent Name" value={applicationDetails[0].parentName} onChange={(e) => handleDetails(e.target.value, 'parentName')} />
              </DivCell>
            </DivRow>
            <DivRow>
              <DivCell>
                <Input required label="Phone Number" type="number" value={applicationDetails[0].phoneNumber} onChange={(e) => e.target.value.length < 11 && handleDetails(e.target.value, 'phoneNumber')} />
              </DivCell>
              <DivCell>
                <Input required label="Email Id" type="email" value={applicationDetails[0].emailId} onChange={(e) => handleDetails(e.target.value, 'emailId')} />
              </DivCell>
            </DivRow>
            <DivRow>
              <DivCell>
                <Lable>GRADE * </Lable>
                <ReactSelectOption
                  isClearable
                  isSearchable
                  label="Grade"
                  value={applicationDetails[0].gradeId}
                  onChange={(e) => handleDetails(e, 'gradeId')}
                  options={gradeList}
                />
              </DivCell>
              <DivCell>
                <DateInput required type="date" label="DATE OF BIRTH" value={applicationDetails[0].dateofBirth} onChange={(e) => handleDetails(e.target.value, 'dateofBirth')} />
              </DivCell>
            </DivRow>
          </DivTable>
          <DivAdderssTable>
            <DivAdderssCellOne>
              <TextArea required label="ADDRESS" rows="2" value={applicationDetails[0].address} onChange={(e) => handleDetails(e.target.value, 'address')} />
            </DivAdderssCellOne>
            <DivAdderssCellTwo>
              <Button
                color="primary"
                small
                onClick={saveData}
              >
                <b style={{ color: 'white' }}>SAVE</b>
              </Button>
            </DivAdderssCellTwo>
          </DivAdderssTable>
          <DivTable>
            <DivRow>
              <SubTitle>PAYMENT DETAILS</SubTitle>
            </DivRow>
          </DivTable>
          <DivPamentTable>
            <DivPaymentRow>
              <SubHeddingTag>
                <Span>Type : </Span>
                Application fee
              </SubHeddingTag>
              <SubHeddingTag>
                <Span>Application date : </Span>
                {`  ${curday('/', true)}`}
              </SubHeddingTag>
              <SubHeddingTag>
                <Span> Amount To Pay : </Span>
                1500 Rs/-
              </SubHeddingTag>
            </DivPaymentRow>
          </DivPamentTable>
        </ParentDiv>
      </DialogBox>
      <Loader open={isLoading} />
      {termsAndConfitionsModel()}
    </AnimateWrapper>
  );
};

export default BillingDetails;
