/* eslint-disable  */
let BASE;
let HOST;
let HOSTNAME;
let WEBSOCKET_BASE;
let AUDIO_BASE;
let WEBSOCKET_HOST;
let AUDIO_PORT;
let PORT;

const PROTO = "http";
const PROTO_HTTPS = "https";
const WS = "ws";
const WSS = "wss";

if (process.env.REACT_APP_UI_ENV === "stage") {
  HOST = "marketing.stage-gke.letseduvate.com";
  WEBSOCKET_HOST = "virtual-counselling-ws.stage-gke.letseduvate.com";
  HOSTNAME = `${HOST}/qbox`;
  BASE = `${PROTO_HTTPS}://${HOSTNAME}`;
  WEBSOCKET_BASE = `${WSS}://${WEBSOCKET_HOST}/ws/virtual_counselling/`;
  AUDIO_BASE = `${WSS}://${WEBSOCKET_HOST}/ws`;
} else if (process.env.REACT_APP_UI_ENV === "prod") {
  HOST = "marketing.letseduvate.com";
  WEBSOCKET_HOST = "virtual-counselling-ws.letseduvate.com";
  HOSTNAME = `${HOST}/qbox`;
  BASE = `${PROTO_HTTPS}://${HOSTNAME}`;
  WEBSOCKET_BASE = `${WSS}://${WEBSOCKET_HOST}/ws/virtual_counselling/`;
  AUDIO_BASE = `${WSS}://${WEBSOCKET_HOST}/ws`;
} else {
  HOST = "marketing.stage-gke.letseduvate.com";
  WEBSOCKET_HOST = "virtual-counselling-ws.stage-gke.letseduvate.com";
  HOSTNAME = `${HOST}/qbox`;
  BASE = `${PROTO_HTTPS}://${HOSTNAME}`;
  PORT = 8001;
  AUDIO_PORT = 6379;
  WEBSOCKET_BASE = `${WSS}://${WEBSOCKET_HOST}/ws/virtual_counselling/`;
  AUDIO_BASE = `${WSS}://${WEBSOCKET_HOST}/ws`;
}

const urls = {
  gradeListApi: `${BASE}/VirtualGradeListAPIView/`,
  fetchLeadDetails: `${BASE}/virtual_tour_lead_details/`,
  specialProgramsAndFeatures: `${BASE}/special_program_and_features/`,
  fetchQuestions: `${BASE}/fetch_questions/`,
  base: BASE,
  socketBase: WEBSOCKET_BASE,
  fetchGrades: `${BASE}/grade/`,
  fetchBranch: `${BASE}/branch/`,
  fetchCityList: `${BASE}/CityList/`,
  getFaq: `${BASE}/list_create_FAQs/`,
  searchFaq: `${BASE}/search_FAQs/`,
  introVideo: `${BASE}/category_wise_video/`,
  childJourney: `${BASE}/child_journey_with/`,
  updateUserDetailsApi: `${BASE}/`,
  thankYouMailApi: `${BASE}/SendMailToParent/`,
  deviceCountApi: `${BASE}/`,
  fetchCategories: `${BASE}/list_faq_category/`,
  fiterFaqsAPI: `${BASE}/list_category_wise_faq/`,
  audioCallAPI: `${AUDIO_BASE}/Audio`,
  audioAPI: AUDIO_BASE,
};

export default urls;
