import * as actionConstants from '../../_actions/actionConstants';

const initialState = {
  loading: false,
  videoLinks: [],
  featureVideoLinksOne: [],
  featureVideoLinksTwo: [],
  currentStepSpecialProgram: 1,
  currentStepFeatureListOne: 1,
  currentStepFeatureListTwo: 1,
  isBack: false,
};

const videoCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.INCREMENT_STEP_SPECIAL_PROGRAM: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.DECREMENT_STEP_SPECIAL_PROGRAM: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.INCREMENT_STEP_FEATURE_LIST_ONE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.DECREMENT_STEP_FEATURE_LIST_ONE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.INCREMENT_STEP_FEATURE_LIST_TWO: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.DECREMENT_STEP_FEATURE_LIST_TWO: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.ON_FETCH_VIDEOS_START: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.ON_FETCH_CATEGORY_VIDEOS_SUCCESS: {
      return {
        ...state,
        videoLinks: action.payload.results,
        loading: action.payload.loading,
      };
    }
    case actionConstants.ON_FETCH_FEATURE_VIDEOS_SUCCESS_ONE: {
      return {
        ...state,
        featureVideoLinksOne: action.payload.results,
        loading: action.payload.loading,
      };
    }
    case actionConstants.ON_FETCH_FEATURE_VIDEOS_SUCCESS_TWO: {
      return {
        ...state,
        featureVideoLinksTwo: action.payload.results,
        loading: action.payload.loading,
      };
    }
    case actionConstants.ON_FETCH_CATEGORY_VIDEOS_FAIL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.ON_BACK_CLICK: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default videoCategoryReducer;
