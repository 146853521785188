export const PERSONAL = 'personal';
export const PERSONAL_INFO = 'personalInfo';
export const PARENT_CHILD_COUNSELLING = 'Parent_Child_Counselling';
export const PAYMENT_STATUS = 'Payment_Status';
export const SINGLE_INPUT_CITY = 'SINGLE_INPUT_CITY';
export const SINGLE_INPUT_BRANCH = 'SINGLE_INPUT_BRANCH';
export const SINGLE_INPUT_GRADE = 'SINGLE_INPUT_GRADE';
export const APPLICATION_FORM = 'Application_Details';
export const APPLICATION_EDIT_STATUS = 'Application_Edit_Status';
export const AGREE_TERMS_AND_CONDITIONS = 'Agree_Terms_And_Conditions';
export const MULTI_INFO = 'multipleInfo';
export const ON_FETCH_PERSONALINFO_SUCCESS = 'fetchPersonalInfoSuccess';
export const ON_FETCH_PERSONALINFO_FAIL = 'fetchPersonalInfoFail';
export const ON_FETCH_START = 'fetchingPerssonalInfo';
export const GRADE_LIST = 'Grade_List';
export const FAQ = 'FAQ_LIST';
export const APPLICATION_POST_RESPONSE = 'Application_Post_Response';
export const CITY_LIST = 'CITY_LIST';
export const BRANCH_LIST = 'BRANCH_LIST';
export const FETCH_INTRO_VIDEO = 'intro_video';
export const FETCH_JOURNEY_VIDEO = 'fetch_journey_video';
export const AIR_PAY_SUCCESS_RESPONSE = 'Air_Pay_Response_Success';
export const AIR_PAY_FAIL_RESPONSE = 'Air_Pay_Response_Fail';
export const FETCH_LITERACY_VIDEO = 'FETCH_LITERACY_VIDEO';
export const FETCH_NUMERACY_VIDEO = 'FETCH_NUMERACY_VIDEO';
export const PAYMENT_TRANSACTION_DETAILS = 'Payment_Transaction_Details';
export const SUCCESS_ORDERD_DETAILS = 'Success_Order_Details';
export const OPEN_TERMS_AND_CONDITIONS = 'Open_terms_And_Conditions';
export const CATEGORIES = 'CATEGORIES';
