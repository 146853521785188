import * as actionConstants from '../../_actions/actionConstants';

const initialState = {
  specialProgramsCategoryIds: [],
  specialProgramsSubCategoryIds: [],
  specialProgramsSubSubCategoryIds: [],
  featureListSubSubCategoryIds: [],
  featureListSubCategoryIds: [],
  featureListCategoryIds: [],
  subCatIds: [],
  subSubCatIds: [],
};

const SelectedCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.RESET_SELECTED_CATEGORY: {
      return {
        specialProgramsCategoryIds: [],
        specialProgramsSubCategoryIds: [],
        specialProgramsSubSubCategoryIds: [],
        featureListSubSubCategoryIds: [],
        featureListSubCategoryIds: [],
        featureListCategoryIds: [],
        subCatIds: [],
        subSubCatIds: [],
      };
    }
    case actionConstants.SPECIAL_PROGRAMS_CATEGORY: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.SPECIAL_PROGRAMS_SUB_CATEGORY: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.SPECIAL_PROGRAMS_SUB_SUB_CATEGORY: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.FEATURE_LIST_CATEGORY: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.FEATURE_LIST_SUB_CATEGORY: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.FEATURE_LIST_SUB_SUB_CATEGORY: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.SET_SUB_CAT_IDS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.SET_SUB_SUB_CAT_IDS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default SelectedCategoryReducer;
