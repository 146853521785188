/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect } from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import * as actions from '../../_actions/personalInfo/actions';
import ThankYouVisitAgainCounsellor from '../ThankyouCounsellor';
import ThankYouVisitAgainParent from '../ThankyouParent';

const ThankYouVisitAgain = () => {
  const {
    personalInfo: { role } = { },
  } = useSelector((state = {}) => state);
  const dispatch = useDispatch();
  const meetingId = useSelector((state) => state.personalInfo.meeting_id);
  useEffect(() => {
    dispatch((actions.thankYouMailSendingApi(meetingId)));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return role === 'Counsellor' ? <ThankYouVisitAgainCounsellor /> : <ThankYouVisitAgainParent />;
};

export default ThankYouVisitAgain;
