import axios from 'axios';
import { ON_FETCH_FEATURES_FAIL, ON_FETCH_FEATURES_START, ON_FETCH_FEATURES_SUCCESS } from './constants';
import urls from '../../urls';

function onSuccessFullFetch(data) {
  return {
    type: ON_FETCH_FEATURES_SUCCESS,
    payload: {
      results: data,
      loading: false,
    },
  };
}

function onFailedFetch(err) {
  return {
    type: ON_FETCH_FEATURES_FAIL,
    payload: {
      error: err,
      loading: false,
    },
  };
}

function onFetchStart() {
  return {
    type: ON_FETCH_FEATURES_START,
    payload: {
      loading: true,
    },
  };
}

function fetchFeatures({ category }) {
  return (dispatch) => {
    dispatch(onFetchStart());
    const url = `${urls.specialProgramsAndFeatures}?category_type=${category}`;
    axios.get(url)
      .then((res) => {
        const { result } = res.data;
        dispatch(onSuccessFullFetch(result));
      })
      .catch((err) => {
        dispatch(onFailedFetch(err.message));
      });
  };
}

export default fetchFeatures;
