/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from "react";

const TextMessage = (title) => {
  const Arts = () => (
    <div>
      <h4>Counselor Cue :</h4>
      <p style={{ fontSize: 14 }}>
        {" "}
        Very Important for Life skills development
      </p>
      <h4>Ask the Parent :</h4>
      <p style={{ fontSize: 14 }}>
        Why do we need to give importance of performing Arts
      </p>
      <h4>Tell them :</h4>
      <p style={{ fontSize: 14 }}>
        We want to create confident and creative individuals who can appreciate
        arts in all its forms. Four different forms of Performing Arts that we
        offer
      </p>
      <p style={{ fontSize: 14 }}>
        1. Dance-&gt; Expression of joy, creation.
        <br />
        2. Theatre-&gt; Artistic Expression, developing performance Skills
        <br />
        3. Music-&gt; Enriching music sense(ear training), Indian classical,
        western and modern forms
        <br />
        4. Visual Arts-&gt; Understand fundamentals of Arts through Colour
        Theory, Sculpture, weaving etc
      </p>
    </div>
  );

  const Empyt = () => (
    <div>
      <h3>Features</h3>
    </div>
  );

  const Computer = () => (
    <div>
      <h4>Counselor Cue :</h4>
      <p style={{ fontSize: 14 }}>
        Tell the Parent the emphasis on trending technology and practical
        applications. Emphasize on logical, analytical and programming skills.
      </p>
      <h4>Ask the Parent :</h4>
      <p style={{ fontSize: 14 }}>
        What according to you is the role of technology in todays world ?
      </p>
    </div>
  );

  const Competitions = () => (
    <div>
      <h4>Counselor Cue :</h4>
      <h4>Ask the Parent :</h4>
      <p style={{ fontSize: 14 }}>Why do you think competitions important ?</p>
      <h4>Tell them : We</h4>
      <p style={{ fontSize: 14 }}>
        • nurture young talent,
        <br />
        • build a strong foundation,
        <br />
        • we prepare children for career oriented exams,
        <br />• intra and inter school competitions in a fun and engaging way
        without over pressurizing the child.
      </p>
    </div>
  );

  const Kits = () => (
    <div>
      <h4>Counselor Cue :</h4>
      <p style={{ fontSize: 14 }}>
        {" "}
        Tell the parent about the Personalized Kits for children.
      </p>
      <h4>Ask the Parent :</h4>
      <p style={{ fontSize: 14 }}>
        Does this look interesting to you and have you heard of these kits
        before ?
      </p>
      <h4>Tell Them :</h4>
      <p style={{ fontSize: 14 }}>
        We give these kits as part of the Learning Program to make Math and
        Literacy more fun filled.
      </p>
    </div>
  );

  const Security = () => (
    <div>
      <h4>Counselor Cue :</h4>
      <p style={{ fontSize: 14 }}>
        {" "}
        Stress on Safety and Security features at Orchids (5 minutes)
      </p>
      <h4>Tell the Parent :</h4>
      <p style={{ fontSize: 14 }}>
        - Safety and Security is a top priority,
        <br />
        - lady attendents for wash rooms,
        <br />
        - clean and sanitized school facilities and surroundings,
        <br />
        - female security guards within the class and campus zones
        <br />- only female teachers till grade 5
      </p>
      <h4>Ask the Parent :</h4>
      <p style={{ fontSize: 14 }}>
        Does this address your Safety and Security concerns ?
      </p>
    </div>
  );

  const Sports = () => (
    <div>
      <h4>Counselor Cue :</h4>
      <h4>Ask the Parent :</h4>
      <p style={{ fontSize: 14 }}>
        1: What sport is your child (use the Childs’ Name) interested in ?
        <br />
        2: Ask the Child about his/her Favorite Sports person ? Why.
        <br />
      </p>
      <h4>Cue : Appreciate the Child.</h4>
      <p style={{ fontSize: 14 }}>
        Tell the Parent why Orchids gives a lot of emphasis on sports. Eye-hand
        coordination, Motor Skills, Learning new sports, Team spirit and Team
        work
      </p>
    </div>
  );

  return title === "Sports and PE"
    ? Sports
    : title === "Safety and Security"
    ? Security
    : title === "Personalized Learning Kits"
    ? Kits
    : title === "Competitions/Events"
    ? Competitions
    : title === "Computer/Robotics"
    ? Computer
    : title === "Performing Arts Program"
    ? Arts
    : Empyt;
};

export default TextMessage;
