import * as actionConstants from '../../_actions/actionConstants';

const initialState = {
  loading: false,
  categories: [],
};

const featureReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.ON_FETCH_FEATURES_START: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.ON_FETCH_FEATURES_SUCCESS: {
      return {
        ...state,
        categories: action.payload.results,
        loading: action.payload.loading,
      };
    }

    case actionConstants.ON_FETCH_FEATURES_FAIL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.UPDATE_VIEWED_FEATURES: {
      return {
        ...state,
        categoriesViewedInfo: action.payload.results,
      };
    }
    default: {
      return state;
    }
  }
};

export default featureReducer;
