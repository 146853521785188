import React from 'react';
import { useSelector } from 'react-redux';
import { AnimateWrapper } from '../../containers';
import AirPay from '../AirPayIntegration';

const PaymentAirPayPage = () => {
  const fullStateData = useSelector((state) => state.personalInfo);
  return (
    <AnimateWrapper>
      <h1> Please wait payment page loading ...</h1>
      {fullStateData && fullStateData.airPayResponse && fullStateData.airPayResponse
        && (
        <AirPay
          htmlData={fullStateData.airPayResponse}
          failResponse={fullStateData.airPayResponseFail}
        />
        )}
    </AnimateWrapper>
  );
};

export default PaymentAirPayPage;
