/* eslint-disable */
import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import AnimateWrapper from "../../containers/AnimateWrapper";
import * as actionConstants from "../../_actions/actionConstants";
import { ReactSelect, DialogueBox } from "../../ui";
import { useSocket } from "../../utils";
import CueComponent from "../../containers/CueComponent";
import FaqModal from "../../containers/Modal";

// const SelectWrapper = styled.h4`
//   color: white;
//   font-size : 18px;
//   @media only screen and (min-width: 768px) {
//     font-size : 20px;
//     padding: 0 16px;
//     margin: 10px 0;
//   }
//   @media only screen and (max-width: 767px) {
//     font-size : 15px;
//     padding: 0 20px;
//     margin: 10px 0;
//   }
// `;
const DateLabel = styled.label`
  font-family: sans-serif;
  font-weight: bold;
  color: white;
  letter-spacing: 0em;
  font-size: 15px;
  padding-left: 15px;
  box-sizing: border-box;
  display: block;
  text-transform: uppercase;
`;
// const Label = styled.h3`
//   color: #285e7f;
//   font-weight : bold;
//   text-align : center;
//   font-size : 20px;
//   @media all and (min-width: 1024px) {
//     font-size : 30px;
//     padding-bottom : 20px;
//   }
// `;
const DialogBox = styled(DialogueBox)`
  background: rgba(119, 145, 230, 0.6);
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media only screen and (min-width: 768px) {
    padding: 20px;
    width: 60%;
  }
  @media only screen and (max-width: 767px) {
    padding: 10px;
    height: 75%;
    width: 90%;
  }
`;

const BRANCH_CHNG = "branch_change";
const GRADE_CHNG = "grade_change";
const CITY_CHNG = "city_change";

const GatherOrVerifyInfo2 = () => {
  const dispatch = useDispatch();
  const fullStateData = useSelector((state) => state.personalInfo);
  const cityList = useSelector((state) => state.personalInfo.cityList);
  const branchList = useSelector((state) => state.personalInfo.branchList);
  const gradeList = useSelector((state) => state.personalInfo.gradeList);
  const isRole = useSelector((state) => state.personalInfo.role);
  const socket = useSocket();

  useEffect(() => {
    if (socket) {
      socket.bind(BRANCH_CHNG, (data) => {
        dispatch({
          type: actionConstants.SINGLE_INPUT_BRANCH,
          text: {
            ...data,
          },
        });
      });

      socket.bind(CITY_CHNG, (data) => {
        dispatch({
          type: actionConstants.SINGLE_INPUT_CITY,
          text: {
            ...data,
          },
        });
      });

      socket.bind(GRADE_CHNG, (data) => {
        dispatch({
          type: actionConstants.SINGLE_INPUT_GRADE,
          text: {
            ...data,
          },
        });
      });
    }
  }, [socket, dispatch]);

  const onCityChange = (e) => {
    if (e) {
      dispatch({
        type: actionConstants.SINGLE_INPUT_CITY,
        text: {
          id: e.value,
          city_name: e.label,
        },
      });
    }
    socket.trigger(CITY_CHNG, {
      id: e.value,
      city_name: e.label,
    });
  };

  const onBranchChange = (e) => {
    if (e) {
      dispatch({
        type: actionConstants.SINGLE_INPUT_BRANCH,
        text: {
          id: e.value,
          branch_name: e.label,
        },
      });
      socket.trigger(BRANCH_CHNG, {
        id: e.value,
        branch_name: e.label,
      });
    }
  };

  const onGradeChange = (e) => {
    if (e) {
      dispatch({
        type: actionConstants.SINGLE_INPUT_GRADE,
        text: {
          id: e.value,
          grade: e.label,
        },
      });
      socket.trigger(GRADE_CHNG, {
        id: e.value,
        grade: e.label,
      });
    }
  };

  const data = () => (
    <div>
      <p style={{ fontSize: 14 }}>
        Cue questions for Counsellor <br />
        Questions – Pre schools – Kids
        <br />
        • Who is your favorite storybook? character?
        <br />
        • Favorite Sports person ? Why
        <br />
        • What makes you happy?
        <br />
        • What TV character makes you laugh the most?
        <br />
        • What&apos;s your superhero ? what powers do you have?
        <br />
        • If you could ask a wild animal any question, what would you ask?
        <br />
        • What are some of the best things about nature?
        <br />
        • You&apos;re a photographer for a day, what would you take pictures of?
        <br />• Do you have any inventions in your brain?
      </p>
    </div>
  );

  return (
    <>
      <AnimateWrapper>
        <DialogBox>
          <DateLabel>Your city is :</DateLabel>
          <ReactSelect
            // isClearable
            isSearchable
            label="city"
            value={
              (fullStateData &&
                fullStateData.lead.city && {
                  label: fullStateData.lead.city
                    ? fullStateData.lead.city.city_name
                    : fullStateData.city.city_name,
                  value: fullStateData.lead.city
                    ? fullStateData.lead.city.id
                    : fullStateData.city.id,
                }) ||
              null
            }
            onChange={onCityChange}
            options={
              cityList && cityList.length
                ? cityList.map((item) => ({
                    value: item.id,
                    label: item.city_name,
                  }))
                : []
            }
          />
          <DateLabel>Which branch are you interested in?</DateLabel>
          <ReactSelect
            // isClearable
            isSearchable
            label="branch"
            value={
              (fullStateData &&
                fullStateData.lead &&
                fullStateData.lead.branch && {
                  label: fullStateData.lead.branch.branch_name,
                  value: fullStateData.lead.branch.id,
                }) ||
              null
            }
            onChange={onBranchChange}
            options={
              branchList && branchList.length
                ? branchList.map((item) => ({
                    value: item.id,
                    label: item.branch_name,
                  }))
                : []
            }
          />
          <DateLabel>Which Grade are you getting into?</DateLabel>
          <ReactSelect
            // isClearable
            isSearchable
            label="grade"
            value={
              (fullStateData &&
                fullStateData.child &&
                fullStateData.child.child_class && {
                  label: fullStateData.child.child_class.grade,
                  value: fullStateData.child.child_class.id,
                }) ||
              null
            }
            onChange={onGradeChange}
            options={
              gradeList && gradeList.length
                ? gradeList.map((item) => ({
                    value: item.id,
                    label: item.grade,
                  }))
                : []
            }
          />
        </DialogBox>
        {isRole === "Counsellor" && <FaqModal />}

        {isRole === "Counsellor" && <CueComponent data={data} />}
      </AnimateWrapper>
    </>
  );
};

export default GatherOrVerifyInfo2;
