/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Loader, DialogueBox, Button } from "../../ui";
import * as actions from "../../_actions/personalInfo/actions";
import AnimateWrapper from "../../containers/AnimateWrapper";
import thankyou from "../../assets/pictures/thanku.svg";
import smile1 from "../../assets/pictures/smile1.png";
import smile2 from "../../assets/pictures/smile2.png";
import smile3 from "../../assets/pictures/smile3.png";
import smile4 from "../../assets/pictures/smile4.png";
import smile5 from "../../assets/pictures/smile5.png";

import ThankYouVisitAgainCounsellor from "../ThankyouCounsellor";

// import { DialogueBox } from '../../ui';
// import OneDialogue from '../../containers/OneDialogue';

// const ThankYouWrapper = styled.div`
//   width: 50.41vw;
//   height : 25.5vw;
//   background-color : white;
//   border-radius : 30px;
//   position : absolute;
//   top : 50%;
//   left : 50%;
//   transform : translate(-50% , -50%);
//   // @media all and (min-width: 1024px) {
//   //   width: 100%;
//   // }
// `;

// const ImgTag = styled.img`
//     width : 100%;
//     height : 100%;
//     position :absolute;
//     padding : 10px;
//     top : 50%;
//      left : 50%;
//      transform : translate(-50% , -50%)
// `;
const InputField = styled.textarea`
  appearance: none;
  background-color: #f1f1f1;
  font-size: 18px;
  font-weight: 600;
  // line-height: 0;
  width: 100%;
  display: block;
  color: #285e7f;
  letter-spacing: 0.01em;
  position: relative;
  z-index: 1;
  height: 80px;
  border-radius: 10px;
  padding: 15px 30px;
  border: 0px solid;
  border-color: ${(props) => props.theme.palette.secondary.textColor};
  box-shadow: none;
  transition: all 0.25s cubic-bezier(0.53, 0.01, 0.35, 1.5);
  &:focus {
    outline: none;
    border: 1px solid #014c93;
    // background: ${() => {
      // const color = chroma(props.theme.palette.primary.main);
      // return color.alpha(0.2).css();
    }};
    color: #014c93;
    margin-top: 30px;
  }
  &:valid {
    margin-top: 30px;
  }
  @media only screen and (min-width: 768px) {
    height: 80px;
  }
  @media only screen and (max-width: 767px) {
    height: 40px;
  }
`;
const Buttons = styled(Button)`
  border-radius: 10px;
  background-color: #1e4d81;
  color: white;
`;
const DialogBox = styled(DialogueBox)`
  text-align: center;
  background-color: white;
  color: #1e4d81;
  padding: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // @media only screen and (min-width: 768px) {
  //   width: 50%;
  //   height: 60%;
  // }
  // @media only screen and (max-width: 767px) {
  //   width: 90%;
  //   height: 82%;
  // }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    width: 90%;
    height: 82%;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    width: 90%;
    height: 82%;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    width: 50%;
    height: 60%;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    width: 50%;
    height: 65%;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    width: 50%;
    height: 60%;
  }
`;

const SuccessLable = styled.p`
  text-align: center;
  color: #1e4d81;
  font-weight: 400px;
  font-size: 15px;
  @media only screen and (min-width: 768px) {
    padding-top: 70px;
    padding-bottom: 5px;
    font-size: 25px;
  }
  @media only screen and (max-width: 767px) {
    padding-top: 60px;
    padding-bottom: 2px;
  }
`;

const DivTag = styled.div`
  @media only screen and (min-width: 768px) {
    padding: 5px;
  }
  @media only screen and (max-width: 767px) {
    padding: 2px;
  }
`;

const EmojiDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const SmileyInput = styled.input`
  padding: 2px;
  outline: none;
  transition: transform 0.5s;
  &:hover {
    transform: scale(1.2);
  }
  &:focus {
    transform: scale(1.2);
  }
  // &:active {
  //    box-shadow: 12px 12px 25px #1e313d,
  //    -12px -12px 25px #284353;
  //    outline: none;
  // }
`;

const DivTable = styled.div`
  // padding-top: 20px;
  display: table;
  text-align: center;
  margin-left: 15%;
  width: 70%;
  margin-right: 15%;
`;
const DivRow = styled.div`
  display: table-row;
`;
const DivCell = styled.div`
  display: table-cell;
  width: 30%;
`;

const Image = styled.img`
  @media only screen and (min-width: 768px) {
    height: 150px;
    width: 150px;
  }
  @media only screen and (max-width: 767px) {
    height: 80px;
    width: 80px;
  }
`;

const ThankYouVisitAgainParent = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.common.loading);
  const meetingId = useSelector((state) => state.personalInfo.meeting_id);
  const [rating, setRating] = useState(0);
  const [feedbackText, setFeedbackText] = useState("");
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    dispatch(actions.thankYouMailSendingApi(meetingId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submittingFeedback = () => {
    dispatch(actions.feedback(meetingId, rating, feedbackText));
    setSubmitted(true);
  };

  const Star = ({ marked, starId }) => (
    <span
      star-id={starId}
      style={{
        color: marked ? "#ffb648" : "#bababa",
        cursor: "pointer",
        fontSize: 45,
      }}
      role="button"
    >
      {marked ? "\u2605" : "\u2606"}
    </span>
  );
  Star.propTypes = {
    marked: PropTypes.bool.isRequired,
    starId: PropTypes.number.isRequired,
  };

  const smileyFunc = (event) => {
    setRating(event.target.value);
  };

  // const StarRating = () => {
  //   const [selection, setSelection] = React.useState(0);
  //   const hoverOver = (event) => {
  //     let val = 0;
  //     if (event && event.target && event.target.getAttribute('star-id')) {
  //       val = event.target.getAttribute('star-id');
  //     }
  //     setSelection(val);
  //   };
  //   return (
  //     <div
  //       onMouseOut={() => hoverOver(null)}
  //       onClick={(event) => setRating(event.target.getAttribute('star-id') || rating)}
  //       onMouseOver={hoverOver}
  //     >
  //       {Array.from({ length: 5 }, (v, i) => (
  //         <Star
  //           starId={i + 1}
  //           key={`star_${i + 1} `}
  //           marked={selection ? selection >= i + 1 : rating >= i + 1}
  //         />
  //       ))}
  //     </div>
  //   );
  // };

  const parentThanks = () => (
    <>
      <AnimateWrapper>
        {/* <h2 style={{ textAlign: 'center', marginTop: '4vh' }}
        >Thank you for your Presence</h2> */}

        <DialogBox>
          <Image
            src={thankyou}
            alt="animated"
            style={{
              position: "absolute",
              top: 0,
              transform: "translate(-50%, -50%)",
            }}
          />
          <DivTag>
            <SuccessLable>
              Thank you for walking along in the virtual journey.
              <br />
              Please rate your experience and provide feedback below
              <br />
              {/* <StarRating /> */}
              <EmojiDiv>
                <SmileyInput
                  type="image"
                  src={smile1}
                  alt="img"
                  value={1}
                  width="10%"
                  height="10%"
                  onClick={(event) => smileyFunc(event)}
                />
                <SmileyInput
                  type="image"
                  src={smile2}
                  alt="img"
                  value={2}
                  width="10%"
                  height="10%"
                  onClick={(event) => smileyFunc(event)}
                />
                <SmileyInput
                  type="image"
                  src={smile3}
                  alt="img"
                  value={3}
                  width="10%"
                  height="10%"
                  onClick={(event) => smileyFunc(event)}
                />
                <SmileyInput
                  type="image"
                  src={smile4}
                  alt="img"
                  value={4}
                  width="10%"
                  height="10%"
                  onClick={(event) => smileyFunc(event)}
                />
                <SmileyInput
                  type="image"
                  src={smile5}
                  alt="img"
                  value={5}
                  width="10%"
                  height="10%"
                  onClick={(event) => smileyFunc(event)}
                />
              </EmojiDiv>
              <InputField
                name="feedback"
                placeholder="Write something..."
                maxlength="150"
                rows="5"
                cols="50"
                // type="text"
                value={feedbackText}
                onChange={(e) => setFeedbackText(e.target.value)}
              />
            </SuccessLable>
          </DivTag>
          <DivTable>
            <DivRow>
              <DivCell>
                <Buttons onClick={() => submittingFeedback()}>Submit</Buttons>
              </DivCell>
            </DivRow>
          </DivTable>
        </DialogBox>
      </AnimateWrapper>
      <Loader open={isLoading} />
    </>
  );
  return !submitted ? parentThanks() : <ThankYouVisitAgainCounsellor />;
};

export default ThankYouVisitAgainParent;
