import axios from 'axios';
import {
  ON_FETCH_CATEGORY_VIDEOS_FAIL, ON_FETCH_CATEGORY_VIDEOS_SUCCESS,
  ON_FETCH_VIDEOS_START, ON_FETCH_FEATURE_VIDEOS_SUCCESS_ONE, ON_FETCH_FEATURE_VIDEOS_SUCCESS_TWO,
} from './constants';
import urls from '../../urls';

function onSuccessFullFetch(data) {
  return {
    type: ON_FETCH_CATEGORY_VIDEOS_SUCCESS,
    payload: {
      results: data,
      loading: false,
    },
  };
}

function onSuccessFullFeatureVideosFetchOne(data) {
  return {
    type: ON_FETCH_FEATURE_VIDEOS_SUCCESS_ONE,
    payload: {
      results: data,
      loading: false,
    },
  };
}

function onSuccessFullFeatureVideosFetchTwo(data) {
  return {
    type: ON_FETCH_FEATURE_VIDEOS_SUCCESS_TWO,
    payload: {
      results: data,
      loading: false,
    },
  };
}

function onFailedFetch(err) {
  return {
    type: ON_FETCH_CATEGORY_VIDEOS_FAIL,
    payload: {
      error: err,
      loading: false,
    },
  };
}

function onFetchStart() {
  return {
    type: ON_FETCH_VIDEOS_START,
    payload: {
      loading: true,
    },
  };
}

function fetchVideosByCategory({
  meetingId, category, subCategory, stepId,
}) {
  return (dispatch) => {
    dispatch(onFetchStart());
    let url = `${urls.specialProgramsAndFeatures}?meeting_id=${meetingId}&category=${category}`;
    if (subCategory) {
      url += `&sub_category=${subCategory}`;
    }
    axios.get(url)
      .then((res) => {
        const { result } = res.data;
        const toDispatchFeature = stepId === 23
          ? onSuccessFullFeatureVideosFetchOne : onSuccessFullFeatureVideosFetchTwo;
        const toDispatch = stepId === 13
          ? onSuccessFullFetch(result) : toDispatchFeature(result);
        dispatch(toDispatch);
      })
      .catch((err) => {
        dispatch(onFailedFetch(err.message));
      });
  };
}

export default fetchVideosByCategory;
