import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Button, Loader } from "../../ui";
import * as actions from "../../_actions/personalInfo/actions";
import AnimateWrapper from "../../containers/AnimateWrapper";
import thankyou from "../../assets/pictures/thanku.svg";

// import { DialogueBox } from '../../ui';
// import OneDialogue from '../../containers/OneDialogue';

const ThankYouWrapper = styled.div`
  width: 50.41vw;
  height: 25.5vw;
  background-color: white;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // @media all and (min-width: 1024px) {
  //   width: 100%;
  // }
`;

const ImgTag = styled.img`
  width: 100%;
  height: 90%;
  position: absolute;
  padding: 10px;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ThankYouVisitAgainCounsellor = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.common.loading);
  const meetingId = useSelector(
    (state) => state.personalInfo.url_share.counsellor
  );
  const Buttons = styled(Button)`
    border-radius: 10px;
    background-color: #1e4d81;
    color: white;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    left: 40%;
  `;
  useEffect(() => {
    dispatch(actions.thankYouMailSendingApi(meetingId));
    dispatch(actions.doneCounsellingApi(meetingId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doneCounselling = () => {
    dispatch(actions.doneCounsellingApi(meetingId));
  };

  return (
    <>
      <AnimateWrapper>
        <h2 style={{ textAlign: "center", marginTop: "5vh" }}>
          Thank you for walking along in the virtual journey.
          <br />
          Thank you for your Presence
        </h2>
        <ThankYouWrapper>
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <ImgTag src={thankyou} alt="thank you" />
            {/* <Buttons onClick={() => doneCounselling()}>DONE</Buttons> */}
          </div>
        </ThankYouWrapper>
        <Loader open={isLoading} />
      </AnimateWrapper>
    </>
  );
};

export default ThankYouVisitAgainCounsellor;
