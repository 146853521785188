/* eslint-disable  */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import * as Component from "./question.styles";
import { RadioCheckBox } from "../../ui";
// import girlImg from '../../assets/pictures/girl.svg';

/**
 * options = [{lable : <LABEL>, value: <VALUE>}]
 */
const ImgTag = styled.img`
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    height: 140px;
    // object-fit: container;
    width: 100%;
    min-height: 100%;
    width: auto;
    // max-width: 10%;
    // max-height: 10%;
    // transform: scale(10);
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    height: 150px;
    // object-fit: container;
    width: 100%;
    min-height: 100%;
    width: auto;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    height: 280px;
    // object-fit: container;
    width: 100%;
    min-height: 100%;
    width: auto;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    height: 280px;
    // object-fit: container;
    width: 100%;
    min-height: 100%;
    width: auto;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    height: 280px;
    // object-fit: container;
    width: 100%;
    min-height: 100%;
    width: auto;
  }
`;
const Question = ({
  question,
  imageSrc,
  audioSrc,
  videoSrc,
  options,
  type,
  onChange,
}) => (
  <Component.Wrapper>
    <Component.DataWrapper>
      <Component.Section style={{ flexDirection: audioSrc && "column" }}>
        {imageSrc && (
          <Component.ImageWrapper>
            <ImgTag src={imageSrc} alt="question" width="100%" />
          </Component.ImageWrapper>
        )}
        {audioSrc && (
          <Component.AudioWrapper>
            <audio
              controls
              style={{
                width: "100%",
                display: "block",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "25px",
                transform: "translate(-50% , -50%)  scale(1.05)",
                boxShadow: "5px 5px 20px rgba(0,0, 0, 0.4)",
                borderRadius: "90px",
              }}
            >
              <source src={audioSrc} type="audio/ogg" />
              <source src={audioSrc} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </Component.AudioWrapper>
        )}
        {videoSrc && (
          <Component.VideoWrapper>
            <video width="100%" controls>
              <source src={videoSrc} type="video/mp4" />
              <source src={videoSrc} type="video/ogg" />
              Your browser does not support the video.
            </video>
          </Component.VideoWrapper>
        )}
        <Component.QuestionWrapper
          // leftEdge="left-bottom"
          large={!videoSrc && !audioSrc && !imageSrc}
        >
          {audioSrc && question}

          {/* {(imageSrc) && (
          <pre
            style={{
              whiteSpace: 'pre-line',
            }}
          >
            {question}
          </pre>
          )} */}

          {/* {(!imageSrc && !videoSrc && !audioSrc) && (
          <pre style={{ whiteSpace: 'pre-line' }}>
            {question}
          </pre>
          )} */}
          {/* {question} */}
        </Component.QuestionWrapper>
      </Component.Section>
      <Component.OptionsWrapper>
        {options.map((option) => (
          <Component.Option key={option.value}>
            <RadioCheckBox
              {...option}
              type={type === "single" ? "radio" : "checkbox"}
              name={type === "single" ? "options" : option.label}
              small={window.isMobile}
              onChange={onChange}
            />
          </Component.Option>
        ))}
      </Component.OptionsWrapper>
      {/* <img
      src={girlImg}
      alt="girl"
      height="50%"
      style={{
        position: 'absolute',
        left: '0px',
        bottom: '10px',
        zIndex: 1001,
      }}
    /> */}
    </Component.DataWrapper>
  </Component.Wrapper>
);

Question.propTypes = {
  question: PropTypes.string,
  imageSrc: PropTypes.string,
  audioSrc: PropTypes.string,
  videoSrc: PropTypes.string,
  options: PropTypes.instanceOf(Array).isRequired,
  type: PropTypes.oneOf(["multi", "single"]),
  onChange: PropTypes.func,
};

Question.defaultProps = {
  question: "",
  imageSrc: "",
  audioSrc: "",
  videoSrc: "",
  type: "single",
  onChange: () => {},
};

export default Question;
