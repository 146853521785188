/* eslint-disable */
/* eslint-disable func-names */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CueComponent from '../CueComponent';
import TextMessage from './textMessage';
// import { DialogueBox } from '../../ui';

import { useSocket } from '../../utils';
import { configMap } from '../../config/stepConfig';
import * as actionType from '../../_actions/actionConstants';
// import videoFile from './Horamavu Bangalore Walkthrough-3-2.m4v';

const VideoTag = styled(motion.div)`
  width : 100%;
  height : 23vh;
  position : relative; 
  `;

const VideoWrapper = styled.div`
  height : auto;
  margin : 0 auto;
  border : 2px solid #1E4D81;
  border-radius : 7px;
  margin-top: ${(props) => (props.imgPosition === 'top-right-img' ? '2%' : '20px')};
  position: ${(props) => (props.imgPosition === 'top-right-img' && 'relative')};
  top : ${(props) => (props.imgPosition === 'top-right-img' && '25%')};
  
  @media only screen and (max-width: 600px) {
  width : 63% ;
  
  }
  
  @media only screen and (min-width: 600px) {
  width : 63% ;
  
  }
  
  @media only screen and (min-width: 768px) {
  width : 80% ;
  }
  
  @media only screen and (min-width: 992px) {
  width: 60% ;
  }
  
  @media only screen and (min-width: 1200px) {
  width: 65% ;
  }
  `;

const Video = styled.video`
  width : 100%;
  height : auto;
  `;
const VideoHiddenControls = styled.video`
  width : 100%;
  height : auto;
  // ::-webkit-media-controls {
  // display: none;
  // }
  
  `;

// const ImgPositions = {
// 'bottom-left-img': css`
// left : 4%;
// bottom : 10%;
// flex-direction : row;
// `,

// 'top-right-img': css`
// right : 4%;
// top : 0%;
// flex-direction : row-reverse;
// `,
// };
// const TourDiv = styled.div`
// display : flex;
// height : 50%;
// position : absolute;
// padding-right : 20px;
// width : 70%;
// ${(props) => ImgPositions[props.ImgPositions]}

// `;

// const Heading = styled.h2`
// margin: 8px 0px;
// font-weight: 400;
// text-align: center;
// color: #204060;
// `;

const Playpause = styled.div`
  // background-image:url(http://png-4.findicons.com/files/icons/2315/default_icon/256/media_play_pause_resume.png);
  background-repeat:no-repeat;
  width:50%;
  height:50%;
  position:absolute;
  left:0%;
  right:0%;
  top:0%;
  bottom:0%;
  margin:auto;
  background-size:contain;
  background-position: center;
  `;

const VideoText = ({
  imgPosition,
  videoSrc,
  title,
}) => {
  const playerRef = useRef();
  const dispatch = useDispatch();
  const currStepId = useSelector((state) => state.common.stepId);
  const role = useSelector((state) => state.personalInfo.role);
  const netSpeed = useSelector((state) => state.common.netSpeed);
  const [currStep, setCurrStep] = useState(null);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const socket = useSocket();
  const [resolution, setResolution] = useState(netSpeed);
  const checkSpeedInterval = 1000;
  const imageAddr = 'https://images.unsplash.com/photo-1601275225755-f6a6c1730cb1';
  const downloadSize = 4916219; // oldsize 16913; // bytes
  const [speed, setSpeed] = useState(5.0);
  const [videoTime, setVideoTime] = useState(0);
  const [speedflag, setSpeedflag] = useState(0);

  const play = useCallback(() => {
    playerRef.current.play();
  }, []);

  const pause = useCallback(() => {
    playerRef.current.pause();
  }, []);

  const measureConnectionSpeed = () => {
    // console.log('speedtest.....');
    const startTime = (new Date()).getTime();
    let endTime;
    const download = new Image();
    function showResults() {
      const duration = (endTime - startTime) / 1000;
      const bitsLoaded = downloadSize * 8;
      const speedBps = (bitsLoaded / duration).toFixed(2);
      const speedKbps = (speedBps / 1024).toFixed(2);
      const speedMbps = (speedKbps / 1024).toFixed(2);
      setSpeed(speedMbps);
    }
    download.onload = function () {
      endTime = (new Date()).getTime();
      showResults();
    };

    // startTime = (new Date()).getTime();
    const cacheBuster = `?nnn=${startTime}`;
    download.src = imageAddr + cacheBuster;
    console.log('Speed(Mbps) in 2secs interval', speed);
  };
  const checkNetSpeed = useCallback(() => {
    if (videoSrc) {
      console.log(`using video url ${videoSrc[resolution]} ...`);
    }
    if (role === 'Counsellor') {
      setVideoTime(playerRef && playerRef.current && playerRef.current.currentTime);
    } else if (role === 'Parent') {
      setVideoTime(playerRef && playerRef.current && playerRef.current.currentTime);
    }
    measureConnectionSpeed();
    if (speed >= 5) {
      if (speedflag !== 0) {
        // callAjax();
        // console.log('speedflag !== 0');
        setResolution('high');
        if (role === 'Parent' && playerRef && playerRef.current) {
          playerRef.current.currentTime = videoTime;
          console.log('video time', videoTime);
        }
        if (role === 'Counsellor' && playerRef && playerRef.current) {
          playerRef.current.currentTime = videoTime;
          console.log('video time', videoTime);
        }
        console.log('speed is high');
        // console.log(`using video url ${videoSrc[resolution]} ...`);
        // Couns
        // console.log('first block');
      }
      setSpeedflag(0);
    } else if (speed < 5 && speed >= 1) {
      if (speedflag !== 1) {
        // timecurrent = playerRef.current.currentTime;
        setResolution('medium');
        if (role === 'Parent' && playerRef && playerRef.current) {
          playerRef.current.currentTime = videoTime;
          console.log('video time', videoTime);
        }
        if (role === 'Counsellor' && playerRef && playerRef.current) {
          playerRef.current.currentTime = videoTime;
          console.log('video time', videoTime);
        }
        console.log('speed is medium');
        // console.log(`using video url ${videoSrc[resolution]} ...`);
        // callAjax();
        // console.log('second block');
      }
      setSpeedflag(1);
    } else {
      if (speedflag !== 2) {
        setResolution('low');
        if (role === 'Parent' && playerRef && playerRef.current) {
          playerRef.current.currentTime = videoTime;
        }
        if (role === 'Counsellor' && playerRef && playerRef.current) {
          playerRef.current.currentTime = videoTime;
        }
        console.log('speed is low');
        // console.log(`using video url ${videoSrc[resolution]} ...`);
        // callAjax();
        // console.log('third block');
      }
      setSpeedflag(2);
    }
    if (!videoPlaying) {
      pause();
    }
    console.log('Speed(Mbps) in 2secs interval', speed);
  }, [role, measureConnectionSpeed, speed, speedflag, videoTime]);

  useEffect(() => {
    const vid = playerRef.current;
    if (socket && role && videoSrc) {
      if (role === 'Parent') {
        socket.bind('play', () => {
          console.log('starting play++++');
          play();
          setVideoPlaying(true);
        });
        socket.bind('pause', () => {
          console.log('starting pause++++');
          pause();
          setVideoPlaying(false);
        });
      } else {
        playerRef.current.onplay = function () {
          console.log('Triggering play+++');
          socket.trigger('play');
          setVideoPlaying(true);
        };
        playerRef.current.onpause = function () {
          console.log('Tiggering Pause+++++');
          socket.trigger('pause');
          setVideoPlaying(false);
        };
      }
    }
    return () => {
      if (socket) {
        socket.unbind('play');
        socket.unbind('pause');
      }
      if (videoSrc) {
        console.log('Leaving+++++');
        vid.pause();
        setVideoPlaying(false);
      }
    };
  }, [socket, play, pause, role, videoSrc]);

  useEffect(() => {
    const vid = playerRef.current;
    let initialPlay;
    if (currStepId !== null) {
      setCurrStep(configMap.get(currStepId));
    }
    if (videoSrc && vid) {
      console.log('Initial playing++++');
      initialPlay = setTimeout(() => {
        vid.play();
        setVideoPlaying(true);
        if (role === 'Counsellor') {
          vid.muted = true;
        } else {
          vid.muted = false;
        }
      }, 1000);
    }

    return () => {
      clearTimeout(initialPlay);
    };
  }, [currStepId, role, videoSrc]);

  useEffect(() => {
    const speedTracker = setInterval(checkNetSpeed, checkSpeedInterval);
    return () => {
      clearInterval(speedTracker);
    };
  }, [checkNetSpeed]);
  const nextHandler = () => {
    if (currStep.next !== null) {
      const id = currStep.next();
      socket.trigger('step_change', { step: id, direction: 1 });
      dispatch({
        type: actionType.STEP_ID,
        payload: {
          id,
          direction: 1,
        },
      });
    }
  };

  const Empyt = () => (
    <div>
      <h3>
        Feature List
      </h3>
    </div>
  );
  return (
    <VideoTag>
      {role === 'Counsellor' && <CueComponent data={(title && TextMessage(title)) || Empyt} />}
      <hr />
      <VideoWrapper imgPosition={imgPosition}>
        {videoSrc && role && role === 'Parent'
          ? (
            <VideoHiddenControls
              ref={playerRef}
              src={videoSrc && videoSrc.low}
              id="videoTextParent"
              controls
              autoPlay
              muted={false}
              onEnded={() => role === 'Parent' && videoSrc && nextHandler()}
            />
          )
          : (
            <Video
              src={videoSrc && videoSrc.low}
              ref={playerRef}
              id="videoTextCouns"
              loop
              controls
              autoPlay
              muted={false}
              onEnded={() => role === 'Parent' && videoSrc && nextHandler()}
            />
          )}
        <Playpause />
      </VideoWrapper>
    </VideoTag>
  );
};

VideoText.propTypes = {
  // data: PropTypes.string.isRequired,
  // img: PropTypes.string.isRequired,
  imgPosition: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default VideoText;
