import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import AnimateWrapper from "../../containers/AnimateWrapper";
import { DialogueBox } from "../../ui";
import QuestonImage from "../../assets/pictures/BeforeQuestionDesktopIcon.svg";
import CueComponent from "../../containers/CueComponent";
import FaqModal from "../../containers/Modal";

const ChildAcademicQuiz = () => {
  const isRole = useSelector((state) => state.personalInfo.role);

  const DialogBox = styled(DialogueBox)`
    text-align: center;
    background-color: white;
    color: #1e4d81;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media only screen and (min-width: 768px) {
      width: 50%;
      height: 30%;
    }
    @media only screen and (max-width: 767px) {
      width: 90%;
      height: 40%;
    }
  `;

  const Title = styled.p`
    text-align: center;
    color: #1e4d81;
    @media only screen and (min-width: 768px) {
      padding-top: 35px;
      font-size: 25px;
    }
    @media only screen and (max-width: 767px) {
      padding-top: 50px;
      font-size: 15px;
    }
  `;
  const DivTag = styled.div`
    @media only screen and (min-width: 768px) {
      padding: 5px;
    }
    @media only screen and (max-width: 767px) {
      padding: 2px;
    }
  `;

  const data = () => (
    <div>
      <p style={{ fontSize: 14 }}>
        A short Quiz Counselor Cue : Indicate to the parent that this is a small
        quiz for the child to understand <br /> the child better.
      </p>
    </div>
  );
  return (
    <>
      <AnimateWrapper>
        <DialogBox>
          <DivTag style={{ textAlign: "center" }}>
            <img
              src={QuestonImage}
              alt="animated"
              height="200px"
              width="200px"
              style={{
                position: "absolute",
                top: 0,
                transform: "translate(-50%, -50%)",
              }}
            />
          </DivTag>
          <DivTag>
            <Title>
              Before I tell you more, please answer a few questions for me.
              Don&apos;t worry, this will be easy.
            </Title>
          </DivTag>
        </DialogBox>
        {isRole === "Counsellor" && <FaqModal />}

        {isRole === "Counsellor" && <CueComponent data={data} />}
      </AnimateWrapper>
    </>
  );
};
export default ChildAcademicQuiz;
