import styled from 'styled-components';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

// const rightEdgePositions = {
//   'right-top': css`
//     border-left: 8px solid ${(props) => props.theme.palette.primary.right};
//     border-right: 8px solid transparent;
//     border-top: 8px solid ${(props) => props.theme.palette.primary.right};
//     border-bottom: 8px solid transparent;
//     right: -10px;
//     top: 8px;
//   `,
//   'right-bottom': css`
//     border-left: 8px solid ${(props) => props.theme.palette.primary.right};
//     border-right: 8px solid transparent;
//     border-top: 8px solid transparent;
//     border-bottom: 8px solid ${(props) => props.theme.palette.primary.right};
//     right: -10px;
//     bottom: 8px;
//   `,
//   'right-center': css`
//     border-left: 8px solid ${(props) => props.theme.palette.primary.right};
//     border-right: 8px solid transparent;
//     border-top: 8px solid transparent;
//     border-bottom: 8px solid transparent;
//     right: -14px;
//     bottom: 50%;
//   `,
// };

// const leftEdgePositions = {
//   'left-top': css`
//     border-left: 8px solid transparent;
//     border-right: 8px solid ${(props) => props.theme.palette.primary.left};
//     border-top: 8px solid ${(props) => props.theme.palette.primary.left};
//     border-bottom: 8px solid transparent;
//     left: -10px;
//     top: 8px;
//   `,
//   'left-bottom': css`
//     border-left: 8px solid transparent;
//     border-right: 8px solid ${(props) => props.theme.palette.primary.left};
//     border-top: 8px solid transparent;
//     border-bottom: 8px solid ${(props) => props.theme.palette.primary.left};
//     left: -10px;
//     bottom: 8px;
//   `,
//   'left-center': css`
//     border-left: 8px solid transparent;
//     border-right: 8px solid ${(props) => props.theme.palette.primary.left};
//     border-top: 8px solid transparent;
//     border-bottom: 8px solid transparent;
//     left: -14px;
//     bottom: 50%;
//   `,
// };

const DialogueBox = styled(motion.div)`
  min-height: 100px;
  // width: 500px;
  position: relative;
  background: ${(props) => props.theme.palette.primary.main};
  // background: ${(props) => props.theme.palette.primary.gradient};
  // color: ${(props) => props.theme.palette.primary.textColor};
  color : #285e7f;
  border-radius: 15px;
  margin: auto;
  padding: 12px;
`;

DialogueBox.propTypes = {
  leftEdge: PropTypes.string,
  rightEdge: PropTypes.string,
};

DialogueBox.defaultProps = {
  leftEdge: '',
  rightEdge: '',
};

export default DialogueBox;
