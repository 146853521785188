/* eslint-disable */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../_actions/actionCreators";
import { Result, AnimateWrapper } from "../../containers";
import { Loader } from "../../ui";
import CueComponent from "../../containers/CueComponent";
import FaqModal from "../../containers/Modal";

const LiteracyResult = () => {
  const [data, setData] = useState([]);
  const [jsonData, setJsonData] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const dispatch = useDispatch();
  const meetingId = useSelector((state) => state.personalInfo.meeting_id);
  const result = useSelector((state) => state.questions.results);
  const resultSummary = useSelector((state) => state.questions.resultSummary);
  const summaryKey = useSelector((state) => state.questions.summaryKey);
  const isLoading = useSelector((state) => state.common.loading);
  const isRole = useSelector((state) => state.personalInfo.role);

  useEffect(() => {
    dispatch(actionCreators.fetchResultSummary(meetingId, result));
  }, [dispatch, meetingId, result]);

  useEffect(() => {
    if (resultSummary && summaryKey) {
      const dataToSave = resultSummary[`${summaryKey}_score`].map((item) => [
        item.category_name,
        item.score,
        item.percentage,
      ]);
      const jsonDataToSave = {};
      const categoriesList = resultSummary[`${summaryKey}_score`].map(
        (item) => item.category_name
      );
      const scoreList = resultSummary[`${summaryKey}_score`].map(
        (item) => item.score
      );
      const percentageList = resultSummary[`${summaryKey}_score`].map(
        (item) => item.percentage
      );
      const performanceList = resultSummary[`${summaryKey}_score`].map(
        (item) => item.performance
      );

      jsonDataToSave.categories = categoriesList;
      jsonDataToSave.scores = scoreList;
      jsonDataToSave.percentages = percentageList;
      jsonDataToSave.performances = performanceList;

      console.log("jsonDataToSave");
      console.log(jsonDataToSave);
      const courseKey = `${summaryKey}_preferred_courses`;
      const suggest = [];
      const video = {
        [summaryKey]: [],
      };
      if (resultSummary[courseKey]) {
        resultSummary[courseKey].forEach((item, index) => {
          const obj = {
            label: item.sub_category.sub_category_name,
            name: item.sub_category.sub_category_name,
            value: index,
            checked: true,
          };
          suggest.push(obj);
          video[summaryKey].push({
            video: item.videos,
            title: item.title,
          });
        });
      }
      dispatch(actionCreators.summaryVideo(video));
      setData(dataToSave);
      setJsonData(jsonDataToSave);
      setSuggestions(suggest);
    }
  }, [resultSummary, summaryKey, dispatch]);
  const onChange = () => {};

  const graphData = () => (
    <div>
      {summaryKey === "literacy" && (
        <p style={{ fontSize: 14 }}>
          Counselor Cue : Ask the Parent : Does your child love reading books /
          Which book have you read to the child recently ?
          <br />
          Explain to the parent the outcome of the childs assessment and how we
          will help the child develop his /her capabilities <br />
          through activities and our programs
        </p>
      )}
      {summaryKey === "numeracy" && (
        <p style={{ fontSize: 14 }}>
          Counselor Cue : Tell the parent that this is a basic assessment to
          gauge the
          <br />
          childs Number sense, operational skills.
        </p>
      )}
    </div>
  );

  return (
    <>
      <AnimateWrapper>
        <Result
          data={data}
          graphJson={jsonData}
          options={suggestions}
          onSelectionChange={onChange}
          title={summaryKey}
        />
        {isRole === "Counsellor" && <CueComponent data={graphData} />}
      </AnimateWrapper>
      {isRole === "Counsellor" && <FaqModal />}

      <Loader open={isLoading} />
    </>
  );
};

export default LiteracyResult;
