import * as actionConstants from '../../_actions/actionConstants';

const initialState = {
  isParent: null,
  pamentNow: null,
  // city: null,
  // branch: null,
  // grade: null,
  applicationEditStatus: false,
  AgreeTermsAndConditions: false,
  applicationDetails: '',
  loading: false,
  uuid: null,
  meeting_id: null,
  lead: {},
  child: {},
  role: null,
  virtual_tour_with_child: null,
  journeyVideo: null,
  openTermsAndConditionsDialog: false,
};

const introductionReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case actionConstants.ON_FETCH_START: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.ON_FETCH_PERSONALINFO_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.ON_FETCH_PERSONALINFO_FAIL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.PERSONAL_INFO: {
      return {
        ...state,
        org: 'Orchids International Schools',
      };
    }
    case actionConstants.FAQ: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.PARENT_CHILD_COUNSELLING: {
      newState.isParent = action.text;
      return newState;
    }
    case actionConstants.PAYMENT_STATUS: {
      newState.pamentNow = action.text;
      return newState;
    }
    case actionConstants.APPLICATION_FORM: {
      newState.applicationDetails = action.details;
      return newState;
    }
    case actionConstants.APPLICATION_EDIT_STATUS: {
      newState.applicationEditStatus = action.status;
      return newState;
    }
    case actionConstants.AGREE_TERMS_AND_CONDITIONS: {
      newState.AgreeTermsAndConditions = action.status;
      return newState;
    }
    case actionConstants.MULTI_INFO: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.PAYMENT_TRANSACTION_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.SINGLE_INPUT_CITY: {
      // newState.lead = { ...state.lead };
      // newState.city = action.text;
      return {
        ...state,
        lead: {
          ...state.lead,
          city: action.text,
        },
      };
    }
    case actionConstants.SINGLE_INPUT_BRANCH: {
      // newState.branch = action.text;
      // return newState;
      return {
        ...state,
        lead: {
          ...state.lead,
          branch: action.text,
        },
      };
    }
    case actionConstants.SINGLE_INPUT_GRADE: {
      // newState.grade = action.text;
      // return newState;
      return {
        ...state,
        child: {
          ...state.child,
          child_class: action.text,
        },
      };
    }
    case actionConstants.GRADE_LIST: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.CATEGORIES: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.CITY_LIST: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.BRANCH_LIST: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.APPLICATION_POST_RESPONSE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.FETCH_INTRO_VIDEO: {
      return {
        ...state,
        introVideo: action.payload.data,
      };
    }
    case actionConstants.FETCH_JOURNEY_VIDEO: {
      return {
        ...state,
        journeyVideo: action.payload.data,
      };
    }
    case actionConstants.AIR_PAY_SUCCESS_RESPONSE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.AIR_PAY_FAIL_RESPONSE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.FETCH_LITERACY_VIDEO: {
      return {
        ...state,
        literacyVideo: action.payload.data,
      };
    }
    case actionConstants.FETCH_NUMERACY_VIDEO: {
      return {
        ...state,
        numeracyVideo: action.payload.data,
      };
    }
    case actionConstants.SUCCESS_ORDERD_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.OPEN_TERMS_AND_CONDITIONS: {
      newState.openTermsAndConditionsDialog = action.status;
      return newState;
    }
    default: {
      return state;
    }
  }
};

export default introductionReducer;
