import * as actionConstants from '../../_actions/actionConstants';

const initialState = {
  loading: false,
  categories: [],
};

// const programAndFeatureReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case actionConstants.ON_FETCH_PROGRAMS_AND_FEATURES_START: {
//       return {
//         ...state,
//         ...action.payload,
//       };
//     }
//     case actionConstants.ON_FETCH_PROGRAMS_AND_FEATURES_SUCCESS: {
//       return {
//         ...state,
//         categories: action.payload.results,
//         loading: action.payload.loading,
//       };
//     }

//     case actionConstants.ON_FETCH_PROGRAMS_AND_FEATURES_FAIL: {
//       return {
//         ...state,
//         ...action.payload,
//       };
//     }
//     case actionConstants.UPDATE_VIEWED_PROGRAMS_AND_FEATURES: {
//       return {
//         ...state,
//         categoriesViewedInfo: action.payload.results,
//       };
//     }
//     default: {
//       return state;
//     }
//   }
// };

// export default programAndFeatureReducer;
const programReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.ON_FETCH_PROGRAMS_START: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.ON_FETCH_PROGRAMS_SUCCESS: {
      return {
        ...state,
        categories: action.payload.results,
        loading: action.payload.loading,
      };
    }

    case actionConstants.ON_FETCH_PROGRAMS_FAIL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionConstants.UPDATE_VIEWED_PROGRAMS: {
      return {
        ...state,
        categoriesViewedInfo: action.payload.results,
      };
    }
    default: {
      return state;
    }
  }
};

export default programReducer;
