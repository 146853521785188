/* eslint-disable */
/* eslint-disable import/no-mutable-exports */
import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import urls from "../../urls";
import Socket from "./socket";

const SocketContext = React.createContext();
let GlobalSocket = null;

const SocketProvider = ({ children }) => {
  const [ws, setWs] = useState(null);

  const meetingId = useSelector((state) => state.personalInfo.meeting_id);
  const role = useSelector((state) => state.personalInfo.role);

  useEffect(() => {
    let webSocket;
    if (meetingId && role) {
      webSocket = new Socket(`${urls.socketBase}${meetingId}/`);
      setWs(webSocket);
      GlobalSocket = webSocket;
      webSocket.bind("open", () => {
        const initialData = {
          stepNo: 1,
          stepId: 1,
          params: "opening_screen",
          role,
        };
        webSocket.trigger("initiation", JSON.stringify(initialData));
      });
    }
    return () => {
      if (
        webSocket &&
        webSocket.connection.readyState === webSocket.connection.OPEN
      ) {
        webSocket.close();
      }
    };
  }, [meetingId, role]);

  return <SocketContext.Provider value={ws}>{children}</SocketContext.Provider>;
};

SocketProvider.propTypes = {
  children: PropTypes.node,
};

SocketProvider.defaultProps = {
  children: "",
};

function useSocket() {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error("useContext must be used within a SocketProvider");
  }
  return context;
}

export { SocketProvider, useSocket, GlobalSocket };
