/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const leftEdgePosition = {
  'left-top': css`
    border-top-left-radius: 0px;
  `,
  'left-bottom': css`
    border-bottom-left-radius: 0px;
  `,
};

const rightEdgePosition = {
  'right-top': css`
    border-top-right-radius: 0px;
  `,
  'right-bottom': css`
    border-bottom-right-radius: 0px;
  `,
};

const Form = styled.div`
  padding: 0 0.5vw;
  width: 100%;
  margin: auto;
  // font-size: 18px;
  font-size : 1.69vw;
  font-weight: 600;
  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
  @media all and (min-width: 920px) {
    font-size : 1.3vw;
  }
`;

const InputGroup = styled.div`
  background-color: #fff;
  box-shadow : 5px 5px 10px rgba(0,0,0,0.4);
  display: block;
  margin: 10px 0;
  position: relative;
  border-radius: ${(props) => (props.allEdges ? '0px' : '10px')};
  ${(props) => rightEdgePosition[props.rightEdge]};
  ${(props) => leftEdgePosition[props.leftEdge]};
  overflow: hidden;
  @media all and (min-width: 920px) {
    border-radius: 21px;

  }
`;

const Label = styled.label`
  // padding: ${(props) => (props.small ? '6px 20px' : '12px 30px')};
  text-align : center !important;
  padding : 10px 5px;
  width: 100%;
  display: block;
  text-align: left;
  // color: #285e7f;
  color: #048;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  border-radius: inherit;
  // border: 1.8px solid ${(props) => props.theme.palette.secondary.textColor};
  // border : 1px solid #1E4D81;
  @media all and (min-width: 920px) {
    padding : 10px 10px;

  }
  &:before {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: '';
    // background: ${(props) => props.theme.palette.primary.main};
    background :  #048;
    // background: ${(props) => props.theme.palette.button.secondaryGradient};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transition: all 400ms cubic-bezier(0.4, 0.0, 0.2, 1);
    opacity: 0;
    z-index: -1;
  }

  /* &:after {
    width: ${(props) => (props.small ? '15px' : '28px')};
    height: ${(props) => (props.small ? '15px' : '28px')};
    content: '';
    // border: 2px solid ${(props) => props.theme.palette.secondary.textColor};
    border : 0.5px solid #1E4D81;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: ${(props) => (props.small ? '-3px -5px' : '2px 3px')};
    border-radius: 50%;
    z-index: 2;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 200ms ease-in;
  } */
`;

const Input = styled.input`
  width: 28px;
  height: 28px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;

  &:disabled ~ ${Label} {
    background: #dddddd;
    cursor: not-allowed;
  }

  &:checked ~ ${Label} {
    color: white;
    border: 2px solid #1E4D81;
  }

  &:checked ~ ${Label}::before {
    transform: translate(-50%, -50%) scale3d(300, 300, 1);
    opacity: 1;
  }

  &:checked ~ ${Label}::after {
    background-color: #54E0C7;
    border-color: #54E0C7;
  }
`;

/**
 * options = [{
 *  value: 'xyz'
 *  label: 'xyz'
 * }]
 */
const RadioCheckBox = ({
  leftEdge,
  rightEdge,
  allEdges,
  type,
  label,
  name,
  onChange,
  id,
  disabled,
  small,
  ...props
}) => (
  <Form>
    <InputGroup
      leftEdge={leftEdge}
      rightEdge={rightEdge}
      allEdges={allEdges}
    >
      <Input
        id={id || label}
        name={name}
        type={type}
        value="option1"
        onChange={onChange}
        disabled={disabled}
        {...props}
      />
      <Label
        htmlFor={id || label}
        small={small}
      >
        {label}
      </Label>
    </InputGroup>
  </Form>
);

RadioCheckBox.propTypes = {
  leftEdge: PropTypes.string,
  rightEdge: PropTypes.string,
  allEdges: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['radio', 'checkbox']),
  label: PropTypes.string.isRequired,
};

RadioCheckBox.defaultProps = {
  leftEdge: '',
  rightEdge: '',
  allEdges: false,
  type: 'checkbox',
  disabled: false,
};

export default RadioCheckBox;
