import * as actionConstants from '../../_actions/actionConstants';

const initialState = {
  stepId: 0,
  direction: 1,
  loading: false,
  pageTitle: '',
  netSpeed: 'high',
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.STEP_ID: {
      return {
        ...state,
        stepId: action.payload.id,
        direction: action.payload.direction,
      };
    }
    case actionConstants.LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionConstants.LOADED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actionConstants.PAGE_TITLE: {
      return {
        ...state,
        pageTitle: action.title,
      };
    }
    case actionConstants.NET_SPEED: {
      return {
        ...state,
        netSpeed: action.netSpeed,
      };
    }
    default: {
      return state;
    }
  }
};

export default commonReducer;
