/* eslint-disable */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actionConstants from "../../_actions/actionConstants";
import FeatureListBase from "./FeatureListBase";
import { useSocket } from "../../utils";
import CueComponent from "../../containers/CueComponent";

/*
 * FeatureList page for counsellor
 */
const FeatureList = () => {
  const categoryId = 4;
  const dispatch = useDispatch();
  const socket = useSocket();
  const {
    selectedCategories: {
      featureListSubCategoryIds = [],
      featureListSubSubCategoryIds = [],
    } = {},
  } = useSelector((state = {}) => state);
  const isRole = useSelector((state) => state.personalInfo.role);

  useEffect(() => {
    const dispatchPayload = {
      type: actionConstants.FEATURE_LIST_CATEGORY,
      payload: { featureListCategoryIds: [{ id: categoryId }] },
    };
    dispatch(dispatchPayload);
    if (socket) socket.trigger("update_selected_categories", dispatchPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateDataArray = (arr, data) => {
    const temp = new Map(arr.map((item) => [item.id, item]));
    if (temp.has(data.id)) {
      temp.delete(data.id);
    } else {
      temp.set(data.id, data);
    }
    return [...temp.values()];
  };
  useEffect(() => {
    if (socket) {
      socket.bind("update_categories_viewed_info", (data = {}) => {
        dispatch({ ...data });
      });
      socket.bind("update_selected_categories", (data = {}) => {
        dispatch({ ...data });
      });
    }
  }, [socket, dispatch]);
  const dispatchSubCategory = (data) => {
    const dispatchPayload = {
      type: actionConstants.FEATURE_LIST_SUB_CATEGORY,
      payload: {
        featureListSubCategoryIds: updateDataArray(
          featureListSubCategoryIds,
          data
        ),
      },
    };
    if (socket) socket.trigger("update_selected_categories", dispatchPayload);
    dispatch(dispatchPayload);
  };
  const dispatchSubSubCategory = (data) => {
    const dispatchPayload = {
      type: actionConstants.FEATURE_LIST_SUB_SUB_CATEGORY,
      payload: {
        featureListSubSubCategoryIds: updateDataArray(
          featureListSubSubCategoryIds,
          data
        ),
      },
    };
    if (socket) socket.trigger("update_selected_categories", dispatchPayload);
    dispatch(dispatchPayload);
  };
  const data = () => (
    <div>
      <p style={{ fontSize: 14 }}>
        Counselor Cue : <br />
        Ask the parent(s) if they would like to know more about the school and
        its features like
        <br />
        • Safety and Security,
        <br />
        • Competition and Events,
        <br />
        • Personal Learning Kits,
        <br />
        • Performing Arts Programs and
        <br />• Sports and PE
      </p>
    </div>
  );
  return (
    <>
      <FeatureListBase
        keyLabel="features"
        label="School Feature"
        categoryId={categoryId}
        updateSubCategory={dispatchSubCategory}
        updateSubSubCategory={dispatchSubSubCategory}
        reduxStoreKey="schoolFeatures" // must not change
        dispatchConstant={actionConstants.UPDATE_VIEWED_FEATURES}
      />
      {isRole === "Counsellor" && <CueComponent data={data} />}
    </>
  );
};

export default FeatureList;
