/* eslint-disable */
/* eslint-disable no-loop-func */
import React, { useState, useRef } from "react";
import DropdownDownArrow from "../../assets/pictures/dropDownDownArrow.svg";
import DropdownUpArrow from "../../assets/pictures/dropDownUpArrow.svg";
import styled from "styled-components";

const CollapseButton = styled.div`
  color: #444;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 14px;
  transition: background-color 0.4s ease;
`;

const EachQuestion = styled.div`
  width: 100%;
  height: auto;
  padding: 10px 15px;
  background-color: white;
  box-shadow: 0 4px 10px 0 rgba(20, 19, 34, 0.03),
    0 0 10px 0 rgba(20, 19, 34, 0.02);
  border-radius: 10px;
  margin: 5px 0px;
  border: 1px solid #dfe3e8;
`;

const AnswerWrapper = styled.div`
  background-color: white;
  overflow: hidden;
  transition: max-height 0.4s ease;
  font-weight: normal;
  font-size: 13px;
`;

const CollapsibleDiv = ({ title, newContent }) => {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
  }

  return (
    <EachQuestion>
      <CollapseButton
        className={`accordion ${setActive}`}
        type="button"
        onClick={toggleAccordion}
      >
        <p className="accordion__title" style={{ width: "80%" }}>
          {title}
        </p>
        <img src={setActive ? DropdownUpArrow : DropdownDownArrow} />
      </CollapseButton>
      <AnswerWrapper
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        <div className="accordion__text" style={{ paddingTop: 5 }}>
          {newContent}
        </div>
      </AnswerWrapper>
    </EachQuestion>
  );
};

// Modal.propTypes = {
//   topInputs: PropTypes.arrayOf(PropTypes.object).isRequired,
//   initialValue: PropTypes.arrayOf(PropTypes.object).isRequired,

// };

export default CollapsibleDiv;
