export const FETCH_QUESTIONS = 'fetch_questions';
export const FETCH_ERRORS = 'fetch_errors';
export const INCREMENT_QUESTION = 'increment_question';
export const RESET_QUESTION = 'reset_question';
export const CHANGE_QUESTION_TYPE = 'change_question_type';
export const SAVE_RESULT = 'SAVE_RESULT';
export const FETCH_RESULT_SUMMARY = 'fetch_result_summary';
export const CHANGE_SUMMARY = 'change_summary';
export const SUMMARY_VIDEO = 'summary_video';
export const CHANGE_CURR_VIDEO_INDEX = 'chng_video_index';
