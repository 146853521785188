/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import {
  useDispatch,
} from 'react-redux';
import { Button } from '../../ui';
// import styled from 'styled-components';
// import {
//   RadioCheckBox,
//   ReactSelect,
//   Input,
//   DialogueBox,
//   Modal,
// } from '../../ui';
// import classes from './airpayIntegration.module.css';
import * as actionConstants from '../../_actions/actionConstants';

const AirPay = ({ htmlData, failResponse }) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(true);
  useEffect(() => {
    setOpen(true);
    setTimeout(() => {
      const form = document.forms[0];
      if (form) {
        form.submit();
      }
    }, 500);
    // console.log('here: ', htmlData);
  }, [htmlData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const handelBack = () => {
    dispatch({
      type: actionConstants.STEP_ID,
      payload: {
        id: 33,
        direction: -1,
      },
    });
  };

  const getHeight = () => {
    const { body } = document;
    const html = document.documentElement;
    const height = Math.max(body.scrollHeight,
      body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    return height;
  };

  const content = () => {
    let data = null;
    if (htmlData) {
      data = (<div dangerouslySetInnerHTML={{ __html: htmlData }} style={{ color: 'black' }} />);
    } else {
      data = (
        <div style={{ textAlign: 'center' }}>
          <div><h1>Something went wrong!</h1></div>
          <div>
            {failResponse && <h6>{JSON.stringify(failResponse)}</h6>}
          </div>
          <div>
            <Button onClick={() => handelBack()}>Go Back</Button>
          </div>
        </div>
      );
    }
    return data;
  };
  return (
    <>
      hi
      {isOpen}
      <div id="airpay" style={{ height: getHeight() }}>
        {content()}
      </div>
      {content}
    </>
  );
};

export default AirPay;
