/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";
// import chroma from 'chroma-js';
import PropTypes from "prop-types";

const InputWrapper = styled.div`
  padding: 0 16px;
  margin: 7px 0;
`;

const Label = styled.label`
  transform-origin: left center;
  color: #1e4d81;
  font-weight: bold;
  letter-spacing: 0.01em;
  font-size: 16px;
  box-sizing: border-box;
  padding: 8px 12px;
  display: block;
  position: absolute;
  margin-top: -46px;
  z-index: 2;
  opacity: 0.3;
  pointer-events: none;
  transition: all 0.25s cubic-bezier(0.53, 0.01, 0.35, 1.5);
`;

const InputField = styled.input`
  appearance: none;
  background-color: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 0;
  width: 100%;
  display: block;
  color: #285e7f;
  letter-spacing: 0.01em;
  position: relative;
  z-index: 1;
  height: 40px;
  border-radius: 10px;
  padding: 0px 30px;
  border: 0px solid;
  border-color: ${(props) => props.theme.palette.secondary.textColor};
  box-shadow: none;
  transition: all 0.25s cubic-bezier(0.53, 0.01, 0.35, 1.5);
  &:focus {
    outline: none;
    border: 1px solid #014c93;
    // background: ${() => {
      // const color = chroma(props.theme.palette.primary.main);
      // return color.alpha(0.2).css();
    }};
    color: #014c93;
  }
  &:valid {
    margin-top: 30px;
  }
  &:valid ~ ${Label} {
    text-transform: uppercase;
    font-size: 16px;
    font-family: sans-serif;
    font-weight: bold;
    opacity: 1;
    transform: translate(5px, -40px) scale(0.6);
    @media all and (min-width: 1024px) {
      font-size: 16px;
    }
  }
  @media all and (min-width: 1024px) {
    height: 50px;
  }
  &input::-webkit-outer-spin-button, &input::-webkit-inner-spin-button {
    -webkit-appearance: none,
    margin: 0,
  },
`;

const DateInput = styled.input`
  height: 50px;
  width: 100%;
  display: block;
  border-radius: 10px;
  background-color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 5px 15px;
  border: 1px solid;
  color: #368;
  margin: 5px 0;
  border-color: ${(props) => props.theme.palette.secondary.textColor};
  outline: none;
`;

const DateLabel = styled.label`
  font-family: sans-serif;
  font-weight: bold;
  color: white;
  letter-spacing: 0em;
  font-size: 16px;
  padding-left: 15px;
  box-sizing: border-box;
  display: block;
  text-transform: uppercase;
`;

const Input = ({ label, type, required, ...props }) => (
  <InputWrapper>
    {type !== "date" ? (
      <>
        <InputField {...props} type={type} required={required} />
        <Label style={{ fontSize: "25px" }}>{`${label}${
          required ? "*" : ""
        }`}</Label>
      </>
    ) : (
      <>
        <DateLabel>{`${label}${required ? "*" : ""}`}</DateLabel>
        <DateInput {...props} type="date" required={required} />
      </>
    )}
  </InputWrapper>
);

Input.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
};

Input.defaultProps = {
  type: "text",
};

export default Input;
