import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';

const variants = {
  enter: (direction) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    zIndex: 0,
    x: direction < 0 ? 1000 : -1000,
    opacity: 0,
  }),
};

const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

const AnimateWrapper = ({ children }) => {
  const id = useSelector((state) => state.common.stepId);
  const direction = useSelector((state) => state.common.direction);

  return (
    <AnimatePresence
      initial="enter"
      custom={direction}
    >
      <Wrapper
        custom={direction}
        key={id}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: 'spring', stiffness: 300, damping: 200 },
          opacity: { duration: 0.2 },
        }}
      >
        {children}
      </Wrapper>
    </AnimatePresence>
  );
};

export default AnimateWrapper;
