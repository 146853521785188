/* eslint-disable */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Question, AnimateWrapper } from "../../containers";
import * as actionCreators from "../../_actions/actionCreators";
import * as actionTypes from "../../_actions/actionConstants";
import { Loader } from "../../ui";
import { useSocket } from "../../utils";
import CueComponent from "../../containers/CueComponent";
import FaqModal from "../../containers/Modal";

// const Heading = styled.h2`
//   margin: 8px 0px;
//   font-weight: bold;
//   text-align: center;
//   color: #204060;
//   font-size : 20px;
//   @media all and (min-width: 1024px) {
//     font-size : 35px;
//     font-weight: bold;
//   }
// `;
const ErrorMessage = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.05);
  font-size: 2rem;
  font-weight: 500;
  color: red;
  text-transform: capitalize;
`;

const QUES_ANS = "ques_ans";

const Questions = () => {
  const dispatch = useDispatch();
  const [currQuestion, setCurrQuestion] = useState(null);
  const UUID = useSelector((state) => state.personalInfo.meeting_id);
  const questions = useSelector((state) => state.questions.questions);
  const isError = useSelector((state) => state.questions.is_error);
  const errorMessage = useSelector((state) => state.questions.errorMessage);
  const questionNumber = useSelector((state) => state.questions.questionNo);
  const questionType = useSelector((state) => state.questions.questionType);
  const type = useSelector((state) => state.questions.type);
  const isLoading = useSelector((state) => state.common.loading);
  const results = useSelector((state) => state.questions.results);
  const isRole = useSelector((state) => state.personalInfo.role);

  const socket = useSocket();

  useEffect(() => {
    if (socket) {
      socket.bind("INCREMENT_QUESTION", (data) => {
        dispatch({
          type: actionTypes.INCREMENT_QUESTION,
          payload: {
            data: data.step,
          },
        });
      });

      socket.bind(QUES_ANS, (data) => {
        dispatch(
          actionCreators.saveResult({
            ...data,
          })
        );
      });

      socket.bind("RESET_QUESTION", () => {
        dispatch({ type: actionTypes.CHANGE_QUESTION_TYPE });
        dispatch({ type: actionTypes.RESET_QUESTION });
      });
    }
  }, [socket, dispatch]);

  useEffect(() => {
    if (isRole !== "Counsellor") {
      dispatch(actionCreators.fetchQuestions(UUID, questionType));
    } else {
      setTimeout(() => {
        dispatch(actionCreators.fetchQuestions(UUID, questionType));
      }, [500]);
    }
  }, [dispatch, UUID, questionType]);

  useEffect(() => {
    if (!isError) {
      if (questions && questions[questionNumber]) {
        setCurrQuestion(questions[questionNumber]);
        dispatch(
          actionCreators.changePageTitle(
            `${questions[questionNumber].question_type.type_name}-${
              questionNumber + 1
            }`
          )
        );
      }
    } else {
      dispatch(actionCreators.changePageTitle(`${type}`));
    }
  }, [questions, questionNumber, dispatch, type]);

  const onChange = (e, id) => {
    dispatch(
      actionCreators.saveResult({
        id,
        option: e.target.value,
        questionType,
      })
    );

    socket.trigger(QUES_ANS, {
      id,
      option: e.target.value,
      questionType,
    });
  };

  const isCheckedOption = (key) => {
    const currId = currQuestion.id;
    const currResult = results.filter((item) => item.id === currId);
    if (currResult.length) {
      return currResult[0].option === key;
    }
    return false;
  };

  const data = () => (
    <div>
      {questionType === 1 && (
        <p style={{ fontSize: 14 }}>
          Counselor Cue : Tell the Parent that this section is to check the
          Reading, and Identification skills of the Child
          <br />
          Tell the Parent that they can select the applicable answer as
          applicable.
        </p>
      )}

      {questionType === 2 && (
        <p style={{ fontSize: 14 }}>
          Counselor Cue : Tell the parent that this is a basic Numeracy
          assessment to check how well <br />
          the child can understand basic numeracy skill
        </p>
      )}
    </div>
  );

  return (
    <>
      {isError ? (
        <ErrorMessage>Error:{errorMessage}</ErrorMessage>
      ) : (
        currQuestion && (
          <AnimateWrapper key={currQuestion.id}>
            {/* <Heading>{currQuestion.question_category.category_name}</Heading> */}
            {/* <hr /> */}
            <Question
              question={
                currQuestion.question && currQuestion.question.question_title
              }
              type="single"
              options={
                currQuestion.options &&
                Object.keys(currQuestion.options).map((key) => ({
                  label: currQuestion.options[key].title,
                  value: key,
                  checked: isCheckedOption(key),
                }))
              }
              onChange={(e) => onChange(e, currQuestion.id)}
              videoSrc={
                currQuestion.question.question_file_type === "video" &&
                currQuestion.question.question_file
              }
              audioSrc={
                currQuestion.question.question_file_type === "audio" &&
                currQuestion.question.question_file
              }
              imageSrc={
                currQuestion.question.question_file_type === "image" &&
                currQuestion.question.question_file
              }
            />
          </AnimateWrapper>
        )
      )}
      {isRole === "Counsellor" && <FaqModal />}

      {isRole === "Counsellor" && <CueComponent data={data} />}

      {isError ? null : <Loader open={!currQuestion || isLoading} />}
    </>
  );
};

export default Questions;
