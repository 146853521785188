/* eslint-disable import/prefer-default-export */
import * as actions from '../actionConstants';

export const changePageTitle = (title) => ({
  type: actions.PAGE_TITLE,
  title,
});

export const changeNetSpeed = (netSpeed) => ({
  type: actions.NET_SPEED,
  netSpeed,
});
