import React, { useState } from "react";
// import React, { useRef, useState, useEffect } from 'react';

import styled from "styled-components";
import { motion } from "framer-motion";
import CueImg from "../../assets/pictures/cluecard.svg";
// import PropTypes from 'prop-types';
// import { DialogueBox, Input } from '../../ui';

const CollapsibleDiv = styled.div`
  width: 18%;
  z-index: 100001;
  max-height: ${(props) => (props.showDiv ? "70vh" : "auto")};
  overflow-y: auto;
  background-color: ${(props) =>
    props.showDiv ? "rgba(255,255,255,0.8)" : "transparent"};
  padding: 10px;
  //   box-shadow : ${(props) =>
    props.showDiv
      ? "10px 10px 30px #d9d9d9,-10px -10px 40px #ffffff"
      : "transparent"};
  border-radius: 10px;
  z-index: 100000;
  cursor: pointer;
  position: absolute;
  top: 2%;
  right: 1%;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const CueButton = styled(motion.button)`
  border-decoration: none;
  margin-left: 80%;
  @media only screen and (min-width: 768px) {
    width: 45px;
    height: 45px;
  }
  @media only screen and (max-width: 767px) {
    width: 35px;
    height: 35px;
  }
  cursor: pointer;
  background-color: white;
  border: 0px solid #048;
  border-radius: 50%;
  text-align: center;
  outline: none;
`;

// const DialogBox = styled(DialogueBox)`
//   text-align: center;
//   width: 100%;
//   background : rgba(119,145,230,0.6);
//   @media all and (min-width: 1024px) {
//     width: 60%;
//     margin: auto;
//   }
// `;

// const Options = styled.div`
//   width: 100%;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   @media all and (min-width: 1024px) {
//     display: block
//   }
// `;

// const OptionList = styled.div`
//   width: 50%;
//   @media all and (min-width: 1024px) {
//     width: 100%;
//   }
// `;

// const Label = styled.h3`
//   color: #285e7f;
//   font-weight : bold;
//   text-align : center;
//   font-size : 20px;
//   @media all and (min-width: 1024px) {
//     font-size : 30px;
//     padding-bottom : 20px;
//   }
// `;

const CueComponent = ({ data }) => {
  //   const [pressed, setPressed] = useState(false);
  //   const [position, setPosition] = useState({ x: 0, y: 0 });
  //   const ref = useRef();

  const [showDiv, setShowDiv] = useState(true);
  const showDivHandle = () => {
    setShowDiv(!showDiv);
  };

  // Monitor changes to position state and update DOM
  //   useEffect(() => {
  //     if (ref.current) {
  //       ref.current.style.transform = `translate(${position.x}px, ${position.y}px)`;
  //     }
  //   }, [position]);

  // Update the current position if mouse is down
  //   const onMouseMove = (event) => {
  //     if (pressed) {
  //       setPosition({
  //         x: position.x + event.movementX,
  //         y: position.y + event.movementY,
  //       });
  //     }
  //   };

  return (
    <motion.div
      drag
      dragConstraints={{
        top: "50%",
        left: "50%",
        right: "50%",
        bottom: "50%",
      }}
    >
      <CollapsibleDiv onClick={showDivHandle} showDiv={showDiv}>
        <div style={{ position: "relative" }}>
          {!showDiv ? (
            <CueButton>
              <img src={CueImg} alt="Cue Img" height="100%" width="100%" />
            </CueButton>
          ) : (
            <h2 style={{ marginLeft: "90%" }}>x</h2>
          )}
          {showDiv && data()}
        </div>
      </CollapsibleDiv>
    </motion.div>
  );
};

// CueComponent.propTypes = {
//   topInputs: PropTypes.arrayOf(PropTypes.object).isRequired,
//   initialValue: PropTypes.arrayOf(PropTypes.object).isRequired,

// };

export default CueComponent;
