const updateViewed = (categoriesViewedInfo_ = []) => {
  const categoriesViewedInfo = categoriesViewedInfo_;
  categoriesViewedInfo.forEach((item, subIndex) => {
    const subCatViewed = item.sub_category.isViewed;
    if (subCatViewed || subCatViewed === false) {
      categoriesViewedInfo[subIndex].sub_category.isViewed = true;
    }
    item.sub_sub_category.forEach((subSubItem, subSubIndex) => {
      if (subSubItem.isViewed || subSubItem.isViewed === false) {
        categoriesViewedInfo[subIndex].sub_sub_category[subSubIndex].isViewed = true;
      }
    });
  });
  return categoriesViewedInfo;
};
const isAllViewed = (categoriesViewedInfo = []) => {
  // eslint-disable-next-line no-console
  console.log('.');
  return categoriesViewedInfo.every((item) => {
    // eslint-disable-next-line no-console
    console.log('.');
    return (item.sub_sub_category.length === 0)
      ? item.sub_category.isViewed
      : item.sub_sub_category.every(
        (subSubItem) => (subSubItem.isViewed || subSubItem.isViewed === false),
      );
  });
};
export { updateViewed, isAllViewed };
