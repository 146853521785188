/* eslint-disable */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../_actions/actionCreators';
import { VideoText } from '../../containers';
import { Loader } from '../../ui';
import img from '../../assets/pictures/boy.svg';

// const data = '';

const Introduction2 = () => {
  const dispatch = useDispatch();
  const introVideo = useSelector((state) => state.personalInfo.introVideo);
  const isLoading = useSelector((state) => state.common.loading);
  const meetingId = useSelector((state) => state.personalInfo.meeting_id);
  useEffect(() => {
    dispatch(actionCreators.fetchIntroVideo(meetingId));
  }, [dispatch, meetingId]);

  return (
    <div>
      <VideoText
        data={introVideo && introVideo[0].title}
        videoSrc={introVideo && introVideo[0].videos}
        img={img}
        imgPosition="bottom-left-img"
      />
      <Loader open={!introVideo || isLoading} />
    </div>
  );
};

export default Introduction2;
