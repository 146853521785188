import React from 'react';
import PdfGenerator from './generatePdf';
import orchidsNewLogo from '../../assets/pictures/orchidsUpdatedlogo.png';

function mainView(data) {
  const infoStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  };
  return (
    <>
      <div>
        <>
          <div style={{ margin: 'auto', textAlign: 'center', padding: '20px' }} className="feeReceipt__main">
            <img src={orchidsNewLogo} alt="crash" height="100px" width="200px" />
          </div>
          <div style={{ margin: 'auto', textAlign: 'center', padding: '20px' }} className="feeReceipt__main">
            <label style={{ fontSize: '1em', fontWeight: 'bold' }}>{data && data.payslip_header}</label>
            <p style={{ marginTop: '-3px', padding: '10px' }}>{data && data.receipt_sub_header}</p>
          </div>
          <div style={{ ...infoStyle, marginTop: '-2px' }}>
            <div>
              Class/Section :
              <strong>{` ${data.student_grade} `}</strong>
            </div>
            <div>
              Academic Year :
              {' '}
              {data.session_year || ''}
            </div>
            <div>
              Date :
              {' '}
              {data.date_of_receipt || ''}
            </div>
          </div>
          <div style={{ ...infoStyle, marginTop: '0.313em', marginBottom: '0.500em' }}>
            <div>
              Receipt No  :
              {' '}
              {data.receipt_number || ''}
            </div>
            <div>
              Student Name :
              <strong>{` ${data.student_name || ''} `}</strong>
            </div>
            <div>
              Parent Name :
              <strong>{` ${data.parent_name || ''} `}</strong>
            </div>
          </div>
          <hr />
        </>
        <div style={{ marginTop: '0.375em', padding: '20px' }}>
          <div style={infoStyle}>
            <div style={{
              width: '50%', height: '1em', lineHeight: '1em', border: '0.006em solid #e9e8e8', paddingLeft: '0.125em', paddingTop: '8px', paddingBottom: '8px',
            }}
            >
              <strong>Fee Particulars</strong>
            </div>
            <div style={{
              width: '25%', height: '1em', lineHeight: '1em', textAlign: 'right', border: '0.006em solid #e9e8e8', paddingRight: '0.125em', paddingTop: '8px', paddingBottom: '8px',
            }}
            >
              <strong>Amount</strong>
            </div>
            <div style={{
              width: '25%', height: '1em', lineHeight: '1em', textAlign: 'right', border: '0.006em solid #e9e8e8', paddingRight: '0.125em', paddingTop: '8px', paddingBottom: '8px',
            }}
            >
              <strong>Installment Due</strong>
            </div>
          </div>
          <div style={infoStyle}>
            <div style={{
              width: '50%', height: '1em', lineHeight: '1em', border: '0.006em solid #e9e8e8', paddingLeft: '0.125em', paddingTop: '8px', paddingBottom: '8px',
            }}
            >
              {(data.fee_type && data.fee_type) || 'Application Fee'}
            </div>
            <div style={{
              width: '25%', height: '1em', lineHeight: '1em', textAlign: 'right', border: '0.006em solid #e9e8e8', paddingRight: '0.125em', paddingTop: '8px', paddingBottom: '8px',
            }}
            >
              {data.Amount || ''}
            </div>
            <div style={{
              width: '25%', height: '1em', lineHeight: '1em', textAlign: 'right', border: '0.006em solid #e9e8e8', paddingRight: '0.125em', paddingTop: '8px', paddingBottom: '8px',
            }}
            >
              {data.balance || ''}
            </div>
          </div>
          <div style={infoStyle}>
            <div style={{
              width: '75%', height: '1em', lineHeight: '1em', border: '0.006em solid #e9e8e8', paddingLeft: '0.125em', paddingTop: '8px', paddingBottom: '8px',
            }}
            >
              Words :
              <strong>{` ${data.amount_in_words || ''} `}</strong>
            </div>
            <div style={{
              width: '25%', height: '1em', lineHeight: '1em', textAlign: 'right', border: '0.006em solid #e9e8e8', paddingRight: '0.125em', paddingTop: '8px', paddingBottom: '8px',
            }}
            >
              Total :
              <strong>{` ${data.total || ''} `}</strong>
            </div>
          </div>
        </div>
        <div>
          <div style={{ marginTop: '0.375em', fontSize: '0.875em' }}>
            <strong>Payment Mode: </strong>
            <strong>{` ${data.payment_mode || ''} `}</strong>
          </div>
        </div>
        <hr />
        <>
          <div>
            <label style={{ fontWeight: 'bold', float: 'left' }}>{data && data.receipt_sub_footer}</label>
            <label style={{ fontWeight: 'bold', float: 'right' }}>Accountant</label>
          </div>
          <div style={{ borderBottom: '0.125em dotted black', clear: 'both', paddingBottom: '0.300em' }}>
            <label>Note :</label>
            {data && data.receipt_footer}
          </div>
        </>
      </div>
    </>
  );
}

const feeReceipts = (data) => {
  const title = 'Application Fee';
  const header = (
    <>
    </>
  );
  const component = (
    <>
      {mainView(data)}
    </>
  );
  const footer = (
    <>
    </>
  );
  PdfGenerator({
    title, header, component, footer,
  });
};

export default feeReceipts;
