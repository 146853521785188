/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import axios from "axios";
import urls from "../../urls";
import * as actions from "../actionConstants";
import feeReceipts from "../../pages/PaymentSuccessFailPage/reciptDownload";

export const fetchApplicationDetail = (data) => (dispatch) => {
  dispatch({
    type: actions.LOADING,
  });
  axios
    .post(
      `${urls.postApplicationDetailsApi}`,
      { ...data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    .then((res) => {
      if (res.status === 201) {
        dispatch({ type: actions.APPLICATION_EDIT_STATUS, status: false });
        dispatch({
          type: actions.APPLICATION_POST_RESPONSE,
          payload: {
            ApplicationUpdatedData: res.data,
          },
        });
        dispatch({
          type: actions.LOADED,
        });
        // window.alert('Details Successfully Saved');
      }
    })
    .catch((error) => {
      dispatch({
        type: actions.LOADED,
      });
      if (
        error.response &&
        error.response.data &&
        error.response.data.err_msg &&
        (error.response.status === 400 || error.response.status === 404)
      ) {
        console.log(error.response.data.err_msg);
        // window.alert(JSON.stringify(error.message));
      } else {
        console.log("Something Went Wrong!");
        // window.alert(JSON.stringify(error.message));
      }
      console.log(error);
    });
};

export const updateUserDetails = (data) => (dispatch) => {
  const info = {
    lead_name: data.parentName,
    lead_email_id: data.email,
    branch_id: data.lead.branch.id,
    city_id: data.lead.city ? data.lead.city.id : data.city.id,
    child_name: data.name,
    child_class_id: data.child.child_class.id,
  };
  dispatch({
    type: actions.LOADING,
  });
  axios
    .put(
      `${urls.updateUserDetailsApi}${data.meeting_id}/virtual_tour_lead_details/`,
      { ...info },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: actions.LOADED,
        });
        console.log("Details Successfully Saved");
      }
    })
    .catch((error) => {
      dispatch({
        type: actions.LOADED,
      });
      if (
        error.response &&
        error.response.data &&
        error.response.data.err_msg &&
        (error.response.status === 400 || error.response.status === 404)
      ) {
        console.log(error.response.data.err_msg);
        // window.alert(JSON.stringify(error.message));
      } else {
        console.log("Something Went Wrong!");
        // window.alert(JSON.stringify(error.message));
      }
      console.log(error);
    });
};

export const fetchGrades = (dispatch) => {
  dispatch({
    type: actions.LOADING,
  });
  axios.get(`${urls.gradeListApi}`).then((res) => {
    dispatch({
      type: actions.GRADE_LIST,
      payload: {
        gradeList: res.data,
      },
    });
    dispatch({
      type: actions.LOADED,
    });
  });
};

export const fetchFaq = (dispatch) => {
  dispatch({
    type: actions.LOADING,
  });
  axios.get(`${urls.getFaq}`).then((res) => {
    dispatch({
      type: actions.FAQ,
      payload: {
        faq: res.data.results,
      },
    });
    dispatch({
      type: actions.LOADED,
    });
  });
};

export const fetchJourneyVideo = (meetingId, isChild) => (dispatch) => {
  dispatch({
    type: actions.LOADING,
  });
  const body = {
    meeting_id: meetingId,
    virtual_tour_with_child: isChild,
  };
  axios.post(`${urls.childJourney}`, body).then((res) => {
    dispatch({
      type: actions.FETCH_JOURNEY_VIDEO,
      payload: {
        data: res.data,
      },
    });
    dispatch({
      type: actions.LOADED,
    });
  });
};

export const fetchIntroVideo = (meetingId) => (dispatch) => {
  dispatch({
    type: actions.LOADING,
  });
  axios.get(`${urls.introVideo}?meeting_id=${meetingId}`).then((res) => {
    dispatch({
      type: actions.FETCH_INTRO_VIDEO,
      payload: {
        data: res.data,
      },
    });
    dispatch({
      type: actions.LOADED,
    });
  });
};

export const fetchCity = (dispatch) => {
  axios.get(`${urls.fetchCityList}`).then((res) => {
    dispatch({
      type: actions.CITY_LIST,
      payload: {
        cityList: res.data,
      },
    });
  });
};

export const fetchBranch = (dispatch) => {
  axios.get(`${urls.fetchBranch}`).then((res) => {
    dispatch({
      type: actions.BRANCH_LIST,
      payload: {
        branchList: res.data,
      },
    });
  });
};

export const fetchTransactionPaymentDetails = (data) => (dispatch) => {
  dispatch({
    type: actions.LOADING,
  });
  const link = `${urls.fetchOrderDetailsApi}${data.transactionId}`;
  axios
    .get(link)
    .then((response) => {
      dispatch({
        type: actions.SUCCESS_ORDERD_DETAILS,
        payload: {
          orderDetails: response.data,
        },
      });
      feeReceipts(response.data);
      dispatch({
        type: actions.LOADED,
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.LOADED,
      });
      if (
        error.response &&
        error.response.data &&
        error.response.data.err_msg &&
        (error.response.status === 400 || error.response.status === 404)
      ) {
        console.log(error.response.data.err_msg);
      } else {
        console.log("Something Went Wrong!");
      }
    });
};

export const fetchAirPayResponse = (data) => (dispatch) => {
  // const link = 'http://15.206.48.27:443/qbox/airpay/virtual_counselling/pay-application-fee-with-airpay/';
  dispatch({
    type: actions.LOADING,
  });
  const link = urls.redirectToAirPayPageApi;
  const body = {
    id: data.ApplicationUpdatedData && data.ApplicationUpdatedData.id,
    uuid: data.uuid,
  };
  axios
    .post(link, body)
    .then((response) => {
      dispatch({
        type: actions.AIR_PAY_SUCCESS_RESPONSE,
        payload: {
          airPayResponse: response.data,
        },
      });
      dispatch({
        type: actions.LOADED,
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.LOADED,
      });
      if (
        error.response &&
        error.response.data &&
        error.response.data.err_msg &&
        (error.response.status === 400 || error.response.status === 404)
      ) {
        console.log(error.response.data.err_msg);
        dispatch({
          type: actions.AIR_PAY_FAIL_RESPONSE,
          payload: {
            airPayResponseFail: error.response.data.err_msg,
          },
        });
      } else {
        console.log("Something Went Wrong!");
        dispatch({
          type: actions.AIR_PAY_FAIL_RESPONSE,
          payload: {
            airPayResponseFail: "Something Went Wrong!",
          },
        });
      }
    });
};

export const thankYouMailSendingApi = (data) => (dispatch) => {
  dispatch({
    type: actions.LOADING,
  });
  const link = `${urls.thankYouMailApi}?meeting_id=${data}`;
  axios
    .get(link)
    .then((response) => {
      console.log(response, "ddddddd");
      dispatch({
        type: actions.LOADED,
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.LOADED,
      });
      if (
        error.response &&
        error.response.data &&
        error.response.data.err_msg &&
        (error.response.status === 400 || error.response.status === 404)
      ) {
        console.log(error.response.data.err_msg);
      } else {
        console.log("Something Went Wrong!");
      }
    });
};

export const DeviceCount = (meetingId, role) => (dispatch) => {
  dispatch({
    type: actions.LOADING,
  });
  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  const link = `${urls.deviceCountApi}${meetingId}/device_type/`;
  axios
    .put(link, {
      device_type: getDeviceType(),
      role,
    })
    .then((response) => {
      console.log(response, "ddddddd");
      dispatch({
        type: actions.LOADED,
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.LOADED,
      });
      if (
        error.response &&
        error.response.data &&
        error.response.data.err_msg &&
        (error.response.status === 400 || error.response.status === 404)
      ) {
        console.log(error.response.data.err_msg);
      } else {
        console.log("Something Went Wrong!");
      }
    });
};

export const getAllCategories = (dispatch) => {
  dispatch({
    type: actions.LOADING,
  });
  axios.get(urls.fetchCategories).then((res) => {
    dispatch({
      type: actions.CATEGORIES,
      payload: {
        categoryList: res.data,
      },
    });
    dispatch({
      type: actions.LOADED,
    });
  });
};

export const feedback = (meetingId, rating, feedbackText) => (dispatch) => {
  dispatch({
    type: actions.LOADING,
  });
  const link = `${urls.base}/${meetingId}/rating/`;
  axios
    .put(
      link,
      {
        rating,
        feedback: feedbackText,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    .then((response) => {
      console.log(response, "ddddddd");
      // window.alert("Thank you for your valuable feedback");
      dispatch({
        type: actions.LOADED,
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.LOADED,
      });
      if (
        error.response &&
        error.response.data &&
        error.response.data.err_msg &&
        (error.response.status === 400 || error.response.status === 404)
      ) {
        console.log(error.response.data.err_msg);
      } else {
        console.log("Something Went Wrong!");
      }
    });
};

export const startCounsellerTimer = (uuid) => () => {
  const link = `${urls.base}/update_start_time/`;
  axios
    .put(
      link,
      {
        meeting_id: uuid,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    .then((response) => {
      console.log(response, "SENT START TIME");
    })
    .catch((error) => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.err_msg &&
        (error.response.status === 400 || error.response.status === 404)
      ) {
        console.log(error.response.data.err_msg);
      } else {
        console.log("Something Went Wrong!");
      }
    });
};

export const endCounsellerTimer = (uuid) => () => {
  const link = `${urls.base}/update_end_time/`;
  axios
    .put(
      link,
      {
        meeting_id: uuid,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    .then((response) => {
      console.log(response, "SENT END TIME");
    })
    .catch((error) => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.err_msg &&
        (error.response.status === 400 || error.response.status === 404)
      ) {
        console.log(error.response.data.err_msg);
      } else {
        console.log("Something Went Wrong!");
      }
    });
};

export const doneCounsellingApi = (meetingId) => () => {
  const link = `${urls.base}/meeting_completed_by_meeting_id/`;
  axios
    .put(
      link,
      {
        meeting_id: meetingId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    .then((response) => {})
    .catch((error) => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.err_msg &&
        (error.response.status === 400 || error.response.status === 404)
      ) {
        console.log(error.response.data.err_msg);
      } else {
        console.log("Something Went Wrong!");
      }
    });
};
