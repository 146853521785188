/* eslint-disable no-underscore-dangle */
import {
  combineReducers,
  createStore,
  applyMiddleware,
  compose,
} from 'redux';
import thunk from 'redux-thunk';
import personalReducer from './personalInfo/reducer';
import commonReducer from './common/reducer';
import videoCategoryReducer from './categoryVideos/reducer';
import programReducer from './programs/reducer';
import featureReducer from './features/reducer';
// import programAndFeatureReducer from './programsAndFeatures/reducer';
import questionReducer from './questions/reducer';
import SelectedCategoryReducer from './categorySelected/reducer';

const reducers = combineReducers({
  personalInfo: personalReducer,
  common: commonReducer,
  videoCategories: videoCategoryReducer,
  specialPrograms: programReducer,
  schoolFeatures: featureReducer,
  questions: questionReducer,
  selectedCategories: SelectedCategoryReducer,
  // specialProgramsAndFeatures: programAndFeatureReducer,
});
const composeEnhancers = typeof window === 'object'
&& window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk),
);

const store = createStore(
  reducers,
  enhancer,
);

export default store;
