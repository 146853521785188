/* eslint-disable */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { DialogueBox, Input } from "../../ui";

const MultiInputWrapper = styled.div`
  width: 90%;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const DialogBox = styled(DialogueBox)`
  text-align: center;
  width: 100%;
  background: rgba(119, 145, 230, 0.6);
  @media all and (min-width: 1024px) {
    width: 60%;
    margin: auto;
  }
`;

const Options = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media all and (min-width: 1024px) {
    display: block;
  }
`;

const OptionList = styled.div`
  width: 50%;
  @media all and (min-width: 1024px) {
    width: 100%;
  }
`;

const Label = styled.h3`
  color: #285e7f;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  @media all and (min-width: 1024px) {
    font-size: 30px;
    padding-bottom: 20px;
  }
`;

const MultiInput = ({
  topInputs,
  //  bottomInputs,
  getInfo,
  initialValue,
}) => (
  <MultiInputWrapper>
    <Label style={{ padding: "10px" }}>
      Before we proceed, let us see if we got this info right.
    </Label>
    <DialogBox>
      <Options>
        {topInputs.map((input) => {
          const valueInfo = input.value;
          return (
            <OptionList key={input.id}>
              <Input
                label={input.label}
                type={input.type}
                required
                name={input.id}
                value={initialValue[valueInfo]}
                onKeyDown={(e) => {
                  if (input.type === "number") {
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                      e.preventDefault();
                  }
                }}
                onChange={(e) =>
                  input.type === "number"
                    ? e.target.value.length < 11 && getInfo(e)
                    : getInfo(e)
                }
              />
            </OptionList>
          );
        })}
      </Options>
    </DialogBox>
    {/* <div style={{ marginTop: '10px' }}>
      <DialogueBox>
        <span>Now a little more about you...</span>
        {bottomInputs.map((input) => (
          <Input label={input.label} type="text" required name={input.id}
          key={input.id} onChange={(e) => getInfo(e)} />
        ))}
      </DialogueBox>
    </div> */}
  </MultiInputWrapper>
);

MultiInput.propTypes = {
  topInputs: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialValue: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MultiInput;
