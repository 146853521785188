/* eslint-disable */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FeatureListBase from '../FeatureLIst/FeatureListBase';
import { useSocket } from '../../utils';
import * as actionConstants from '../../_actions/actionConstants';
// import FaqModal from '../../containers/Modal';

const ProgramList = () => {
  const categoryId = 3;
  const dispatch = useDispatch();
  const socket = useSocket();

  const {
    selectedCategories: {
      specialProgramsSubCategoryIds = [],
      specialProgramsSubSubCategoryIds = [],
    } = {},
  } = useSelector((state = {}) => state);

  useEffect(() => {
    const dispatchPayload = {
      type: actionConstants.FEATURE_LIST_CATEGORY,
      payload: { specialProgramsCategoryIds: [{ id: categoryId }] },
    };

    dispatch(dispatchPayload);
    if (socket) socket.trigger('update_selected_categories', dispatchPayload);

    return () => {
      socket.trigger('RESET_QUESTION');
    };
  }, [socket, dispatch]);

  const updateDataArray = (arr, data) => {
    const temp = new Map(arr.map((item) => [item.id, item]));
    if (temp.has(data.id)) {
      temp.delete(data.id);
    } else {
      temp.set(data.id, data);
    }
    return [...temp.values()];
  };
  useEffect(() => {
    if (socket) {
      socket.bind('update_categories_viewed_info', (data = {}) => {
        dispatch({ ...data });
      });
      socket.bind('update_selected_categories', (data = {}) => {
        dispatch({ ...data });
      });
    }
  }, [socket, dispatch]);
  const dispatchSubCategory = (data) => {
    const dispatchPayload = {
      type: actionConstants.SPECIAL_PROGRAMS_SUB_CATEGORY,
      payload: {
        specialProgramsSubCategoryIds: updateDataArray(specialProgramsSubCategoryIds, data),
      },
    };
    dispatch(dispatchPayload);
    if (socket) socket.trigger('update_selected_categories', dispatchPayload);
  };
  const dispatchSubSubCategory = (data) => {
    const dispatchPayload = {
      type: actionConstants.SPECIAL_PROGRAMS_SUB_SUB_CATEGORY,
      payload: {
        specialProgramsSubSubCategoryIds:
        updateDataArray(specialProgramsSubSubCategoryIds, data),
      },
    };
    dispatch(dispatchPayload);
    if (socket) socket.trigger('update_selected_categories', dispatchPayload);
  };
  return (
    <>
      <FeatureListBase
        keyLabel="programs"
        label="Special Pograms"
        categoryId={categoryId}
        updateSubCategory={dispatchSubCategory}
        updateSubSubCategory={dispatchSubSubCategory}
        reduxStoreKey="specialPrograms"
        dispatchConstant={actionConstants.UPDATE_VIEWED_PROGRAMS}
      />
      {/* {isRole === 'Counsellor' && <FaqModal />} */}

    </>
  );
};

export default ProgramList;
