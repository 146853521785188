import React, { useState } from 'react';
// import styled from 'styled-components';
import { AnimateWrapper } from '../../containers';
import {
  RadioCheckBox,
  ReactSelect,
  Input,
  DialogueBox,
  Modal,
} from '../../ui';

// const RadioBoxWrapper = styled.div`
//   width: 50%;
//   margin: auto;
//   margin-bottom: 20px;
// `;

const colourOptions = [
  { value: 'ocean', label: 'Ocean', color: '#00B8D9' },
  { value: 'blue', label: 'Blue', color: '#0052CC' },
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630' },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' },
];

const Introduction = () => {
  const [isOpen, setOpen] = useState(true);
  const onChange = () => {

  };

  const handleModal = () => {
    setOpen(false);
  };

  return (
    <AnimateWrapper>
      <div>
        Intoduction
      </div>
      <Modal open={isOpen} closeHandler={handleModal} large>
        <DialogueBox>
          <RadioCheckBox
            type="checkbox"
            name="options1"
            label="Option 1"
            onChange={onChange}
            small
          />
          <RadioCheckBox
            type="checkbox"
            onChange={onChange}
            name="options2"
            label="Option 2"
          />
          <ReactSelect
            isClearable
            isSearchable
            // menuIsOpen
            isMulti
            onChange={onChange}
            options={colourOptions}
          />
          <Input type="number" required label="Name" />
        </DialogueBox>
      </Modal>
    </AnimateWrapper>
  );
};

export default Introduction;
