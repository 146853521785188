/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';

const Area = styled.textarea`
  min-height: 50px;
  width: 100%;
  display: block;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  padding: 0px 10px;
  border: 1px solid;
  color: #285e7f;
  border-color: ${(props) => props.theme.palette.secondary.textColor};
  outline: none;
`;

const Label = styled.label`
font-family: sans-serif;
font-weight: bold;
color: white;
letter-spacing: 0.0em;
font-size: 17px;
padding-left: 15px;
box-sizing: border-box;
display: block;
text-transform: uppercase;
`;

const TextArea = ({
  label,
  required,
  ...props
}) => (
  <>
    <Label style={{ paddingBottom: '5px' }}>{`${label}${required ? '*' : ''}`}</Label>
    <Area {...props} required={required} />
  </>
);

export default TextArea;
