/* eslint-disable */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../_actions/actionCreators";
import { VideoText } from "../../containers";
import { Loader } from "../../ui";
import img from "../../assets/pictures/boy.svg";
import CueComponent from "../../containers/CueComponent";
import FaqModal from "../../containers/Modal";

// const data = "Walk with me and I will
// show you my school! Video. Do you like our school? One
// thing that's special about our school is how they teach us. It is special!";

const ChildJourney = () => {
  const dispatch = useDispatch();
  const meetingId = useSelector((state) => state.personalInfo.meeting_id);
  const isParent = useSelector((state) => state.personalInfo.isParent);
  const video = useSelector((state) => state.personalInfo.journeyVideo);
  const isLoading = useSelector((state) => state.common.loading);
  const isRole = useSelector((state) => state.personalInfo.role);

  useEffect(() => {
    dispatch(actionCreators.fetchJourneyVideo(meetingId, !isParent));
  }, [dispatch, meetingId, isParent]);

  useEffect(() => {
    dispatch(actionCreators.fetchIntroVideo(meetingId));
  }, [dispatch, meetingId]);

  const data = () => (
    <div>
      <p style={{ fontSize: 14 }}>
        Counselor Cue : Show Assistance or Guidance
        <br />
        We will take you through Virtual tour of our campus&apos;s
        facilities,features and activities so that <br />
        this virtual experience will help you&apos;ll as parents to take a
        important decision to enroll your
        <br />
        child in one among the Finest International school in India.
      </p>
    </div>
  );

  return (
    <div>
      <VideoText
        data={video && video.title}
        videoSrc={video && video.videos}
        img={img}
        imgPosition="top-right-img"
      />
      {isRole === "Counsellor" && <FaqModal />}

      {isRole === "Counsellor" && <CueComponent data={data} />}

      <Loader open={!video || isLoading} />
    </div>
  );
};

export default ChildJourney;
