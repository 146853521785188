/* eslint-disable */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';
import { AnimateWrapper } from '../../containers';
import fetchFeatures from '../../_actions/schoolProgramsAndFeatureList/featureAction';
import fetchPrograms from '../../_actions/schoolProgramsAndFeatureList/programAction';
// import theme from '../../ui/theme/index';
import { useSocket } from '../../utils';
import { Loader } from '../../ui';
import * as actionConstants from '../../_actions/actionConstants';
import './dummy.css';

const FeatureListDiv = styled.div`
  // display: flex;
  // flex-wrap: no-wrap;
  // padding: 10px;
  // max-width: 100%;
  // min-height: 100%;
  overflow: auto;
`;
const FeatureDiv = styled.div`
  background: linear-gradient(to top right, #6CDBDE, #FCBBEF);
  // padding: 0px;
  // margin: 0px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  // width: 200px;
  // height: 220px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;
const SubFeaturesListDiv = styled.div`
  margin: 2px;
  fontSize: 0.9em;
  padding: 4;
  max-height: 76%;
  overflow: auto;
  background: linear-gradient(to top right, #6CDBDE, #FCBBEF);
`;
const FeatureListBase = (props) => {
  const dispatch = useDispatch();
  const socket = useSocket();
  // const [subCatIds, setSubCatIds] = useState(new Set());
  // const [subSubCatIds, setSubSubCatIds] = useState(new Set());
  const {
    // eslint-disable-next-line react/destructuring-assignment
    [props.reduxStoreKey]: { categories = [], categoriesViewedInfo, loading },
    // specialPrograms
    // schoolFeatures
    altered_to_set: {
      subCatIds = new Set(),
      subSubCatIds = new Set(),
    },
  } = useSelector((state = {}) => {
    const {
      selectedCategories: {
        subCatIds: subCatIdsArr = [],
        subSubCatIds: subSubCatIdsArr = [],
      } = {},
    } = state || {};
    return {
      ...state,
      altered_to_set: {
        subCatIds: new Set([...subCatIdsArr]),
        subSubCatIds: new Set([...subSubCatIdsArr]),
      },
    };
  });

  const setSubCatIds = (data) => {
    const dispatchPayload = {
      type: actionConstants.SET_SUB_CAT_IDS,
      payload: { subCatIds: [...data] },
    };
    dispatch(dispatchPayload);
    if (socket) socket.trigger('update_seletected_cat_ids', dispatchPayload);
  };
  const setSubSubCatIds = (data) => {
    const dispatchPayload = {
      type: actionConstants.SET_SUB_SUB_CAT_IDS,
      payload: { subSubCatIds: [...data] },
    };
    dispatch(dispatchPayload);
    if (socket) socket.trigger('update_seletected_cat_ids', dispatchPayload);
  };

  const updateCategories = (type, subIndex, subSubIndex) => {
    const categoriesData = [...categoriesViewedInfo || categories];
    if (type === 'sub_sub_category') {
      const { sub_sub_category: subSubCategory = [] } = categoriesData[subIndex] || {};
      const { isViewed } = subSubCategory && subSubCategory.length
        ? subSubCategory[subSubIndex] : {};
      // if (categoriesData[subIndex]
      //   && !categoriesData[subIndex].sub_sub_category[subSubIndex].isViewed) {
      if (!isViewed) {
        categoriesData[subIndex].sub_sub_category[subSubIndex] = {
          ...categoriesData[subIndex].sub_sub_category[subSubIndex],
          isViewed: isViewed === false ? undefined : false,
        };
      }
    } else if (type === 'sub_category') {
      const { sub_category: { isViewed } = {} } = categoriesData[subIndex] || {};
      // if (categoriesData[subIndex] && !categoriesData[subIndex].sub_category.isViewed) {
      if (!isViewed) {
        categoriesData[subIndex].sub_category = {
          ...categoriesData[subIndex].sub_category,
          isViewed: isViewed === false ? undefined : false,
        };
      }
    }
    const dispatchPayload = {
      type: props.dispatchConstant,
      payload: {
        results: categoriesData,
      },
    };
    if (socket) socket.trigger('update_categories_viewed_info', dispatchPayload);
    dispatch(dispatchPayload);
  };
  useEffect(() => {
    if (socket) {
      socket.bind('update_categories_viewed_info', (data = {}) => {
        dispatch({ ...data });
      });
      socket.bind('update_seletected_cat_ids', (data = {}) => {
        dispatch({ ...data });
      });
    }
  }, [socket, dispatch]);
  useEffect(() => {
    dispatch(
      props.keyLabel === 'programs'
        ? fetchPrograms({ category: props.categoryId })
        : fetchFeatures({ category: props.categoryId }),
    );

    // returned function will be called on component unmount
    return () => {
      // const dispatchPayload = {
      //   type: props.dispatchConstant,
      //   payload: { results: updateViewed(categoriesViewedInfo) },
      // };
      // dispatch(dispatchPayload);
      // if (socket)socket.trigger('update_categories_viewed_info', dispatchPayload);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSelection = (event, categoryItem, subIndex, subSubIndex) => {
    event.stopPropagation();
    const { id } = categoryItem;
    // if (subSubCatIds && subSubCatIds.size < 3) {
    props.updateSubSubCategory(categoryItem);
    updateCategories('sub_sub_category', subIndex, subSubIndex);
    const currentSubCatIds = new Set([...subSubCatIds]);
    if (currentSubCatIds.has(id)) {
      currentSubCatIds.delete(id);
    } else {
      currentSubCatIds.add(id);
    }
    setSubSubCatIds(currentSubCatIds);
    // }
    //  else {
    //   // eslint-disable-next-line no-alert
    //   window.alert('please click on next');
    // }
  };
  const handleToggle = (event, item, subIndex) => {
    const { id } = item.sub_category;
    props.updateSubCategory(item.sub_category);
    updateCategories('sub_category', subIndex);
    const currentOpenCards = new Set([...subCatIds]);
    if (currentOpenCards.has(id)) {
      currentOpenCards.delete(id);
    } else {
      currentOpenCards.add(id);
    }
    setSubCatIds(currentOpenCards);
  };
  const hasViewed = (type, subIndex, subSubIndex) => {
    if (type === 'sub') {
      return (
        categoriesViewedInfo
        && categoriesViewedInfo.length
        && categoriesViewedInfo[subIndex]
        && categoriesViewedInfo[subIndex].sub_category
        && categoriesViewedInfo[subIndex].sub_category.isViewed
      );
    } if (type === 'sub_sub') {
      return (
        categoriesViewedInfo
        && categoriesViewedInfo.length
        && categoriesViewedInfo[subIndex]
        && categoriesViewedInfo[subIndex]
        && categoriesViewedInfo[subIndex].sub_sub_category
        && categoriesViewedInfo[subIndex].sub_sub_category[subSubIndex]
        && categoriesViewedInfo[subIndex].sub_sub_category[subSubIndex].isViewed
      );
    }
    return null;
  };

  return (
    <AnimateWrapper>
      {/* <div>
        {
        // eslint-disable-next-line react/destructuring-assignment
        props.label
        }
      </div> */}
      <Loader open={loading} />
      {/* <div style={{ display: 'flex', maxWidth: '100%', minHeight: '100%' }}> */}

      <h2 style={{ textAlign: 'center', marginTop: '10vh' }}>Select any of the Categories to watch respective videos</h2>

      <FeatureListDiv
        id="feature-list-div"
        className={['hide-scroll', 'container']}
        // className="hide-scroll"
        // className="container"
        style={{
          height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}
      >
        {
          // eslint-disable-next-line no-nested-ternary
          categories.length ? categories.map((category, subIndex) => (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ rotate: 360, scale: 1 }}
              transition={{
                type: 'spring',
                stiffness: 260,
                damping: 20,

              }}
            >
              <FeatureDiv
                key={category.id}
                onClick={(e) => {
                  handleToggle(e, category, subIndex);
                }}
                className="card"
              >

                <div style={{
                  textAlign: 'center',
                  minWidth: '100%',
                  background: '#FFEFEC',
                  color: 'black',
                  boxSizing: 'border-box',
                  height: '20%',
                  display: 'flex',
                  // ...(subCatIds.has(category.id)
                  // || hasViewed('sub', subIndex)) ? { background: 'black', color: 'white' } : {},
                  ...((subCatIds.has(category.sub_category.id)
                || hasViewed('sub', subIndex))
                && category.sub_sub_category
              && category.sub_sub_category.length)
                    ? { borderRadius: '8px 8px 0px 0px' } : { margin: 'auto' },
                }}
                >
                  <p style={{ margin: 'auto' }}>
                    {/* {
                    category.sub_sub_category && category.sub_sub_category.length === 0
                      ? (
                        <input
                          type="radio"
                          checked={
                        subCatIds.has(category.id)
                        || hasViewed('sub', subIndex)
                      }
                        />
                      ) : null

                    } */}
                    {
                    category.sub_sub_category
                    && category.sub_sub_category.length === 0
                    && (subCatIds.has(category.sub_category.id)
                        || hasViewed('sub', subIndex))
                      ? <span style={{ color: 'green', fontSize: '1.2em' }}>&#10003;</span>
                      : null
}
                      &nbsp;&nbsp;
                    {/* Done(text shall wrap to next line based on length') */}
                    {category.sub_category.sub_category_name
                      && category.sub_category.sub_category_name.includes('/')
                      ? category.sub_category.sub_category_name.split('/').join(' / ')
                      : category.sub_category.sub_category_name}
                    {/* {category.sub_category.sub_category_name.split('/').join(' / ')} */}
                  </p>
                </div>

                {
                  (
                    subCatIds.has(category.sub_category.id)
                || hasViewed('sub', subIndex)
                  )
              && category.sub_sub_category
              && category.sub_sub_category.length
                    ? (
                      <SubFeaturesListDiv className="styled-scroll">
                        {category.sub_sub_category.map(
                          (subItem, subSubIndex) => {
                            const has = subSubCatIds.has(subItem.id);
                            return (
                              <p
                                key={subItem.id}
                                style={{
                                  ...(
                                    has || hasViewed('sub_sub', subIndex, subSubIndex)
                                  )
                                    ? { color: 'black' } : {},

                                  padding: 2,
                                  margin: 3,
                                }}
                                onClick={(e) => {
                                  handleSelection(e, subItem, subIndex, subSubIndex);
                                }}
                              >
                                {/* <input
                                  type="radio"
                                  checked={has || (hasViewed('sub_sub', subIndex, subSubIndex))}
                                  onClick={(e) => { handleSelection(e, subItem); }}
                                /> */}
                                {(has || hasViewed('sub_sub', subIndex, subSubIndex))
                                  ? <span style={{ color: 'green', fontSize: '1.2em' }}>&#10003;</span>
                                  : null}
                          &nbsp;&nbsp;
                                {subItem.sub_sub_category_name}
                              </p>
                            );
                          }
                          ,
                        )}
                      </SubFeaturesListDiv>
                    ) : null
}
              </FeatureDiv>
            </motion.div>
          ))
            // : loading ? 'Loading...'
            : loading ? ''
              : 'Failed to fetch data'
}
      </FeatureListDiv>
      {/* </div> */}

    </AnimateWrapper>
  );
};

export default FeatureListBase;
