import styled from 'styled-components';
import { DialogueBox } from '../../ui';

export const Wrapper = styled.div`
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
  // height: 100vh;
  position : absolute;
  top : 50%;
  left : 50%;
  transform : translate(-50% , -50%);
  @media all and (min-width: 1024px) {
    padding: 20px;
    height : auto;
  }
`;

export const DataWrapper = styled.div`
  width : 65.46vw;
  margin: 0 auto;
  height : auto;
  display : flex;
  flex-direction : column;
  @media all and (min-width: 768px) {
    width : 50.36vw;
  }
`;

export const Section = styled(DialogueBox)`
  // display: flex;
  // width : 90%;
  width : 100%;
  // height : 17.6vw;
  text-align:center;
  padding : 0px;
  background-color : transparent ;
  border : none;
  margin-top: 2.6vw;
  // @media all and (min-width: 768px) {
  //   height : 250px;
  // }
  
`;
export const QuestionWrapper = styled.div`
  // width: ${(props) => (props.large ? '80%' : '90%')};
  min-height: 0px;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 600;
  // text-align: center;
  margin-bottom: 20px;
  position: relative;
  z-index: 1001;
  vertical-align: center;
  color: ${(props) => props.theme.palette.secondary.textColor};
  @media all and (min-width: 1024px) {
    // width: 100%;
    text-align : center;
    font-size: 25px;
  }
`;

export const MediaWrapper = styled.div`
  width: 50%;
  margin: auto;
  // background-color: #ff6666;
  // padding: 12px;
  border-radius: 12px;
`;

export const ImageWrapper = styled(MediaWrapper)`
  /* width: 40%; */
  // height: 40vw;
  width: 100%;

  // @media all and (orientation: landscape) {
  //   // height: 40vh;
  //   max-height: 130px;
  // }
`;

export const AudioWrapper = styled(MediaWrapper)`
width: 100%;
text-align : center;
  @media all and (min-width: 1024px) {
    width: 100%;
  }
`;
export const VideoWrapper = styled(MediaWrapper)`
  width: 100%;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  margin-top : 25px;
  flex-direction : row;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 20px;
  z-index: 1000;
  @media all and (min-width: 920px) {
    margin-top : 15px;

  }

`;
export const Option = styled.div`
  width: 100%;
  /* transform: translateX(30px); */
  @media all and (min-width: 1024px) {
    width: 50%;
    transform: translateX(0px);
  }
`;
