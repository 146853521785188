/* eslint-disable */

import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import MicIcon from "@material-ui/icons/Mic";
import CallEndIcon from "@material-ui/icons/CallEnd";
// import HUE from '@material-ui/core/colors';
import purple from "@material-ui/core/colors/purple";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSocket } from "../../utils";
import * as actionType from "../../_actions/actionConstants";
import * as actionCreators from "../../_actions/actionCreators";
import * as actions from "../../_actions/personalInfo/actions";
import { configMap } from "../../config/stepConfig";
import NextImage from "../../assets/pictures/NextButtonImg.svg";
import Previous from "../../assets/pictures/Previous.svg";
import urls from "../../urls";
import "./wizard.css";
// import Music from '../../assets/pictures/music.mp3';

const VideoCallDiv = styled(motion.video)`
  background: rgba(0, 0, 0, 0.8);
  height: 17.26vh;
  width: 17.26vw;
  border-radius: 5px;
  position: absolute;
  margin-left: 40%;
  margin-top: 1%;
  left: 0;
`;
const VideoDiv = styled(motion.div)`
  z-index: 999999;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const BottomBar = styled.div`
  z-index: 999999;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  height: 5vh;
  width: 17.26vw;
  border-radius: 0px 0px 5px 5px;
  position: fixed;
  margin-left: 40%;
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    margin-top: 7%;
    height: 7vh;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    margin-top: 7%;
    height: 7vh;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    margin-top: 7%;
    height: 5vh;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    margin-top: 7%;
    height: 5vh;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    margin-top: 7%;
    height: 5vh;
  }
`;

const ControlButton = styled.button`
  // background-color: #4CAF50; /* Green */
  // // border: none;
  // color: white;
  // padding: 8px 8px;
  // text-align: center;
  // text-decoration: none;
  // // font-size: 16px;
  // border-radius: 10px;
  // cursor: pointer;
  // // margin: 12px;
  // // position: absolute;
  // height: 40px;
  border-radius: 50% !important;
  position: relative;
  outline: none;
`;

const CallButton = styled.button`
  background-color: #4caf50; /* Green */
  // border: none;
  color: white;
  padding: 12px 28px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  margin: 12px;
  position: absolute;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  width: 85%;
  justify-content: space-between;
  height: 50px;
  position: fixed;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  @media only screen and (min-width: 768px) {
    padding-bottom: 90px;
  }
  @media only screen and (max-width: 767px) {
    padding-bottom: 60px;
  }
  @media all and (min-width: 1024px) {
    bottom: 20px;
  }
`;

const BackButton = styled(motion.button)`
  @media only screen and (min-width: 768px) {
    width: 85px;
    height: 85px;
  }
  @media only screen and (max-width: 767px) {
    width: 55px;
    height: 55px;
  }
  border-decoration: none;
  cursor: pointer;
  background-color: white;
  border: 0px solid #048;
  border-radius: 50%;
  text-align: center;
  outline: none;
  z-index: 1000;
  position: fixed;
  bottom: 20px;
  left: 7%;
`;

const NextButton = styled(motion.button)`
  border-decoration: none;
  @media only screen and (min-width: 768px) {
    width: 85px;
    height: 85px;
  }
  @media only screen and (max-width: 767px) {
    width: 55px;
    height: 55px;
  }
  cursor: pointer;
  background-color: white;
  border: 0px solid #048;
  border-radius: 50%;
  text-align: center;
  outline: none;
  z-index: 1000;
  position: fixed;
  bottom: 20px;
  right: 7%;
`;

const WizardList = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

const WizardWraper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const TitleWrapper = styled.div`
  position: absolute;
  z-index: 1001;
  /* background-color: green; */
  left: 0px;
  transform: rotate(90deg) translate(55px, 0px);
  transform-origin: 40px 10px;
  width: 90vh;
  @media all and (orientation: portrait) {
    width: 90vw;
  }
  @media (min-width: 920px) {
    transform: rotate(90deg) translate(40px, -40px);
    width: 90vh;
  }
`;

const TitleAndIcon = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
`;

const Circle = styled.div`
  width: 14px;
  height: 14px;
  background: ${(props) => (props.color ? "#00397a" : "white")};
  border-radius: 50%;
  margin-right: 10px;
  @media (min-width: 920px) {
    margin-right: 30px;
  }
`;

const BigCircle = styled.div`
  width: 14px;
  height: 64px;
  border-radius: 20px;
  background: #00397a;
  margin-left: 20px;
  transform: rotate(90deg);
  @media (min-width: 920px) {
    height: 80px;
    margin-left: 30px;
  }
`;

const Title = styled.h3`
  /* text-align: center; */
  /* margin-left: 10px; */
  /* transform: translateX(10%); */
  font-weight: 200;
  letter-spacing: 2px;
  font-size: 1.2rem;
  margin-right: 10px;
  color: #00397a;
  font-weight: 400;
  @media (min-width: 920px) {
    font-size: 2rem;
    margin-right: 20px;
  }
`;

function Wizard() {
  const [currStep, setCurrStep] = useState(null);
  const [showButtons, setShowButtons] = useState(false);
  const currStepId = useSelector((state) => state.common.stepId);
  const role = useSelector((state) => state.personalInfo.role);
  const title = useSelector((state) => state.common.pageTitle);
  const dispatch = useDispatch();
  const socket = useSocket();
  const uuidCall = useSelector((state) => state.personalInfo.uuid);
  const meetingId = useSelector((state) => state.personalInfo.meeting_id);
  const uuidBothSides = useSelector((state) => state.personalInfo.url_share);
  const audio = new Audio(
    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/click.mp3"
  );
  const [streamState, setStreamState] = useState({});
  const [showControls, setShowControls] = useState(true);
  const [isHideControls, setIsHideControls] = useState(false);
  const [muteToggle, setmuteToggle] = useState(false);
  const [videoToggle, setVideoToggle] = useState(false);
  const [hangupToggle, sethangupToggle] = useState(false);
  const [showCallButton, setShowCallButton] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    console.log("nnnnnnnnnnnnn");
    setAnchorEl(null);
  };

  // const color = HUE[900];
  // AV calling feature.
  // let name;
  let connectedUser;
  //* *****
  // UI selectors block
  //* *****
  // const cameraSelect = document.querySelector('#camera');
  // const loginPage = document.querySelector('#loginPage');
  // const usernameInput = document.querySelector('#usernameInput');
  // const loginBtn = document.querySelector('#loginBtn');

  // const callPage = document.querySelector('#callPage');
  // const callToUsernameInput = document.querySelector('#callToUsernameInput');
  // const callBtn = document.querySelector('#callBtn');

  // const callername = document.getElementById('callername');

  // const accBtn = document.querySelector('#acc');

  // const rejBtn = document.querySelector('#rej');

  // const cancelBtn = document.querySelector('#cancelBtn');

  // const hangUpBtn = document.querySelector('#hangUpBtn');

  // const localAudio = document.querySelector('#localAudio');
  // const remoteAudio = document.querySelector('#remoteAudio');

  // const audiosrc1 = document.getElementById('audiosrc1');
  // const audiosrc2 = document.getElementById('audiosrc2');

  // const ad1 = document.getElementById('ad1');
  // const ad2 = document.getElementById('ad2');

  // const audiomute = document.querySelector('#audio_mute');
  // const videooff = document.querySelector('#video_off');

  const yourConn = useRef(
    new RTCPeerConnection({
      iceServers: [
        {
          urls: "turn:turnav.letseduvate.com:3478",
          username: "admin",
          credential: "admin123",
        },
      ],
    })
  );
  let stream;

  const USER = uuidCall;

  let websocketProtocol;

  console.log("host = ", window.location.host);

  // console.log('protocol=', location.protocol);

  // if (window.location.protocol === 'http:') {
  //   websocketProtocol = 'ws';
  // } else {
  //   websocketProtocol = 'wss';
  // }

  // const websocketBaseUrl = `${websocketProtocol}://${window.location.host}`;
  console.log("how many times...................");
  const conn = useRef();
  // const conn = new WebSocket(
  //   `${urls.audioAPI}/vc_new_user/none`,
  // );
  // alias for sending JSON encoded messages
  const send = useCallback((message) => {
    // attach the other peer username to our messages
    if (connectedUser) {
      message.name = connectedUser;
    }
    if (conn && conn.current) {
      conn.current.send(JSON.stringify(message));
    }
  });

  useEffect(() => {
    if (meetingId) {
      conn.current = new WebSocket(
        `${urls.audioAPI}/vc_new_user/${meetingId}/`
      );
    }
  }, [meetingId]);

  useEffect(() => {
    if (window.isMobile && window.orientation !== 90) {
      alert(
        "Please turn on auto-rotate in your phone settings for the best experience!"
      );
    }
  }, []);

  // useEffect(() => {
  //   if (conn && conn.current) {
  //     send({
  //       type: 'login',
  //       userid: uuidCall,
  //     });
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  function handleLogin() {
    // loginPage.style.display = 'none';
    // callPage.style.display = 'block';

    // Starting a peer connection

    getMediaDevice();

    // getting local audio stream

    const mediaConstraints = {
      audio: true,
      video: {
        width: 640,
        // width: { min: 640, ideal: 1280, max: 1920 },
        // height: { min: 480, ideal: 720, max: 1080 },
        // deviceId: cameraSelect.value,
      },
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then((mystream) => {
        console.log("Stream connected successfully");
        stream = mystream;
        const videoPlayer = document.getElementById("remoteAudio");
        videoPlayer.setAttribute("autoplay", "");
        videoPlayer.setAttribute("mute", "");
        videoPlayer.setAttribute("playsinline", "");
        // videoPlayer.srcObject = mystream;
        // remoteAudio.srcObject = stream;
        // setStreamState(window.URL.createObjectURL(mystream));

        // using Google public stun server
        // const configuration = {
        //   iceServers: [{ url: 'stun:stun1.1.google.com:19302' }],
        // };

        // yourConn.current.value = new RTCPeerConnection(configuration);
        console.log("yourConn........");
        console.log(yourConn);
        stream.getTracks().forEach((track) => {
          yourConn.current.addTrack(track, stream);
        });

        // setup stream listening
        // yourConn.addStream(stream);

        // when a remote user adds stream to the peer connection, we display it
        // yourConn.onaddstream = function (e) {
        // not reqd
        // videooff.addEventListener('click', (event) => {
        //   // stream.getVideoTracks()[0].enabled = !(stream.getVideoTracks()[0].enabled);

        //   if (stream.getVideoTracks()[0].enabled === true) {
        //     stream.getVideoTracks()[0].enabled = false;
        //     stream.getVideoTracks()[0].stop();
        //   } else stream.getVideoTracks()[0].enabled = true;
        // });
        // not reqd
        // audiomute.addEventListener('click', (event) => {
        //   stream.getAudioTracks()[0].enabled = !(stream.getAudioTracks()[0].enabled);
        // });
        const audioMute = document.getElementById("audioMute");
        const videoOff = document.getElementById("videoOff");
        // if (audioMute && videoOff) {
        audioMute.addEventListener("click", (event) => {
          stream.getAudioTracks()[0].enabled =
            !stream.getAudioTracks()[0].enabled;
        });
        videoOff.addEventListener("click", (event) => {
          stream.getVideoTracks()[0].enabled =
            !stream.getVideoTracks()[0].enabled;
        });
        // }

        // const remoteStream = new MediaStream();
        // videoPlayer.srcObject = remoteStream;
        console.log(" setting this remoteStream to video..............");
        // console.log(remoteStream);
        // setStreamState(window.URL.createObjectURL(remoteStream));
        // setStreamState(remoteStream);

        yourConn.current.addEventListener("track", async (event) => {
          // ad2.pause();
          // $("#myModal2").modal('hide');
          console.log("tracking................");
          // remoteStream.addTrack(event.track, streamState);
          console.log("remoteStream. after addtrack.............");
          [videoPlayer.srcObject] = event.streams;

          // console.log(remoteStream);
        });

        // Setup ice handling
        yourConn.current.onicecandidate = function (event) {
          if (event.candidate) {
            console.log("icecandidate..........");
            console.log(event.candidate);
            send({
              type: "candidate",
              candidate: event.candidate,
            });
          }
        };
      })
      .catch((error) => {
        console.log("error in stream:", error);
      });
  }
  // eslint-disable-next-line func-names
  if (conn && conn.current) {
    conn.current.onopen = function () {
      // send({
      //   type: "login",
      //   userid: uuidCall,
      // });
      handleLogin();
      console.log("Connected to the signaling server");
    };
  }

  // eslint-disable-next-line func-names
  // conn.current.onerror = function (err) {
  //   console.log('Got error', err);
  // };

  // callPage.style.display = 'none';

  // Login when the user clicks the button
  // not reqd
  // loginBtn.addEventListener('click', (event) => {
  //   name = usernameInput.value;

  //   if (name.length > 0) {
  //     send({
  //       type: 'login',
  //       name,
  //     });
  //   }
  // });

  let notbusy = 0;
  // calling

  function handlecall(success) {
    if (success === true) {
      notbusy = 0;
      if (yourConn && yourConn.current) {
        yourConn.current
          .createOffer()
          .then((offer) => {
            send({
              type: "offer",
              offer,
            });

            yourConn.current.setLocalDescription(offer);
          })
          .catch((error) => {
            alert("Error when creating an offer");
          });
        // yourConn.current.createOffer((offer) => {
        //   send({
        //     type: 'offer',
        //     offer,
        //   });

        //   yourConn.current.setLocalDescription(offer);
        // }, (error) => {
        //   alert('Error when creating an offer');
        // });
      }
    } else {
      notbusy = 1;
      alert("Parent is not connected. Try again once parent is connected. ");
      window.location.reload();
    }
  }

  // initiating a call
  // not reqd
  // callBtn.addEventListener('click', () => {
  //   const callToUsername = callToUsernameInput.value;

  //   if (callToUsername.length > 0 && notbusy === 0) {
  //     connectedUser = callToUsername;

  //     // $("#myModal2").modal({backdrop:'static',keyboard:false});

  //     // ad2.play();
  //     send({
  //       type: 'call',
  //       name,
  //     });
  //   }
  // });

  // cancelBtn.addEventListener("click", function () {

  //             send({
  //            type: "leave"
  //           });

  //       handleLeave();
  // });

  // when somebody sends us an offer

  function handleOffer(offer, namee) {
    connectedUser = namee;
    yourConn.current.setRemoteDescription(new RTCSessionDescription(offer));

    //    $("#myModal").modal({backdrop:'static',keyboard:false});
    //  ad1.play();
    // console.log(`Callername: ${name}`);
    //  callername.innerHTML = name ;
    // create an answer to an offer

    // rejBtn.addEventListener("click", function () {
    //             send({
    //              type: "leave"
    //         });

    //       handleLeave();
    // });

    // accBtn.addEventListener("click", function () {
    //   $("#myModal").modal('hide');

    // audiosrc1.src=null;
    // ad1.pause();

    yourConn.current
      .createAnswer()
      .then((answer) => {
        yourConn.current.setLocalDescription(answer);
        setIsHideControls(false);
        send({
          type: "answer",
          answer,
        });
      })
      .catch((error) => {
        alert(`Error when creating an answer: ${error}`);
      });
    // yourConn.current.createAnswer((answer) => {
    //   yourConn.current.setLocalDescription(answer);

    //   send({
    //     type: 'answer',
    //     answer,
    //   });
    // }, (error) => {
    //   alert(`Error when creating an answer: ${error}`);
    // });

    // });
  }

  // when we got an answer from a remote user

  function handleAnswer(answer) {
    yourConn.current.setRemoteDescription(new RTCSessionDescription(answer));
    setShowCallButton(false);
    setIsHideControls(false);
  }

  // when we got an ice candidate from a remote user

  function handleCandidate(candidate) {
    yourConn.current.addIceCandidate(new RTCIceCandidate(candidate));
  }

  const recreatePeerConnection = () => {
    yourConn.current = new RTCPeerConnection({
      iceServers: [
        {
          urls: "turn:turnav.letseduvate.com:3478",
          username: "admin",
          credential: "admin123",
        },
      ],
    });
    handleLogin();
  };

  // Leave function
  function handleLeave() {
    connectedUser = null;
    // remoteAudio.src = null;
    // localAudio.src = null;
    yourConn.current.close();
    yourConn.current.onicecandidate = null;
    yourConn.current.onaddstream = null;
    //   $("#myModal2").modal('hide');
    // $("#myModal").modal('hide');
    // audiosrc1.src=null;
    // audiosrc2.src=null;
    // ad1.pause();
    // ad2.pause();
    recreatePeerConnection();
    setShowCallButton(true);
    setmuteToggle(false);
    setVideoToggle(false);
    setIsHideControls(true);
  }
  // hang up
  // not reqd
  // hangUpBtn.addEventListener('click', () => {
  //   send({
  //     type: 'leave',
  //   });

  //   handleLeave();
  // });

  function getMediaDevice() {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.log("enumerateDevices() not supported.");
      return;
    }

    // List cameras and microphones.
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        devices.forEach((device) => {
          console.log(
            `${device.kind}: ${device.label} id = ${device.deviceId}`
          );

          // add different camera options to a select tag // videoinput
          if (device.kind === "videoinput") {
            const option = document.createElement("option");
            option.value = device.deviceId;
            option.text = device.label;
            // cameraSelect.append(option);
          }
        });
      })
      .catch((err) => {
        console.log(`${err.name}: ${err.message}`);
      });
  }
  // when we got a message from a signaling server
  if (conn && conn.current) {
    conn.current.onmessage = function (msg) {
      console.log("Got message", msg.data);
      const data = JSON.parse(msg.data);
      switch (data.type) {
        case "call":
          handlecall(data.success);
          break;
        // when somebody wants to call us
        case "offer":
          handleOffer(data.offer, data.name);
          break;
        case "answer":
          handleAnswer(data.answer);
          break;
        // when a remote peer sends an ice candidate to us
        case "candidate":
          handleCandidate(data.candidate);
          break;
        case "leave":
          handleLeave();
          break;
        default:
          break;
      }
    };
  }

  const handlevideoOff = () => {
    setVideoToggle(!videoToggle);
  };
  const handleMute = () => {
    setmuteToggle(!muteToggle);
  };
  const endCall = () => {
    sethangupToggle(true);
    send({
      type: "leave",
    });
    handleLeave();
  };
  // react wala code
  const callParent = () => {
    send({
      type: "call",
      meeting_id: meetingId,
      // sending the uuid of the other side
      userid_to_call:
        uuidBothSides[role === "Counsellor" ? "parent" : "counsellor"],
    });
  };
  const toggleControls = () => {
    setShowControls(!showControls);
  };

  console.log(currStepId, "dddddd");
  useEffect(() => {
    const paymentDetails = new URLSearchParams(window.location.search);
    if (
      paymentDetails.has("order_id") &&
      paymentDetails.has("transaction_id") &&
      paymentDetails.has("payment_status")
    ) {
      if (
        window.location.origin === "http://3.7.217.147" ||
        window.location.origin === "http://localhost:3000" ||
        window.location.origin ===
          "https://counselling.orchidsinternationalschool.com" ||
        window.location.origin === "https://counselling.letseduvate.com"
      ) {
        if (
          paymentDetails.has("order_id") &&
          paymentDetails.has("transaction_id") &&
          paymentDetails.has("payment_status")
        ) {
          const information = {
            orderId: paymentDetails.get("order_id"),
            transactionId: paymentDetails.get("transaction_id"),
            paymentStatus: paymentDetails.get("payment_status"),
          };
          dispatch({
            type: actionType.PAYMENT_TRANSACTION_DETAILS,
            payload: {
              transactionDetails: information,
            },
          });
          dispatch({
            type: actionType.STEP_ID,
            payload: {
              id: 35,
              direction: 1,
            },
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowButtons(true);
    }, 1200);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.bind("step_change", (data) => {
        if (window.isMobile && window.orientation !== 90) {
          alert(
            "Please turn on auto-rotate in your phone settings for the best experience!"
          );
        }
        dispatch({
          type: actionType.STEP_ID,
          payload: {
            id: data.step,
            direction: data.direction,
          },
        });
      });
      // socket.trigger('buffering', { uuid: uuidCall });
      // socket.bind('buffering', (data) => {
      //   console.log(data);
      // });
    }
  }, [socket, dispatch, uuidCall]);

  useEffect(() => {
    if (currStepId !== null) {
      dispatch(actionCreators.changePageTitle(""));
      setCurrStep(configMap.get(currStepId));
    }
  }, [currStepId, dispatch]);

  const nextHandler = () => {
    audio.play();
    dispatch(actions.endCounsellerTimer(uuidCall));
    if (currStep.next !== null) {
      const id = currStep.next();
      socket.trigger("step_change", { step: id, direction: 1 });
      dispatch({
        type: actionType.STEP_ID,
        payload: {
          id,
          direction: 1,
        },
      });
    }
  };

  const backHandler = () => {
    audio.play();
    dispatch(actions.endCounsellerTimer(uuidCall));
    if (currStep.back !== null) {
      const id = currStep.back();
      socket.trigger("step_change", { step: id, direction: -1 });
      dispatch({
        type: actionType.STEP_ID,
        payload: {
          id,
          direction: -1,
        },
      });
    }
  };

  return (
    <WizardWraper>
      {currStep && (
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {role === "Counsellor" && showCallButton ? (
            <CallButton onClick={callParent}>
              <span className="material-icons" />
              Call Parent
            </CallButton>
          ) : null}
          {/* {currStepId === 36 && role === 'Counsellor' && (
              <CallButton
                onClick={endCall}
              >
                <span className="material-icons" />
                End Call
              </CallButton>
            )} */}
          <VideoDiv
            onMouseEnter={toggleControls}
            onMouseLeave={toggleControls}
            // onClick={handleClick}
            drag
            dragConstraints={{
              top: "50%",
              left: "50%",
              right: "50%",
              bottom: "50%",
            }}
          >
            <VideoCallDiv id="remoteAudio" autoPlay />
            <BottomBar
              style={{
                display: `${
                  !showControls && !isHideControls ? "flex" : "none"
                }`,
              }}
            >
              {/* <ControlButton
                  onClick={handleMute}
                  className={` btn btn-sm btn-primary  ${muteToggle ? 'strike' : ''} `}
                  type="button"
                  id="audioMute"
                >
                  <VideoCallIcon />
                </ControlButton>
                <ControlButton
                  className={` btn btn-sm btn-warning  ${videoToggle ? 'strike' : ''} `}
                  onClick={handlevideoOff}
                  type="button"
                  id="videoOff"
                >
                  <MicIcon />
                </ControlButton>
                <ControlButton
                  className={` btn btn-sm btn-danger  ${hangupToggle ? 'strike' : ''} `}
                  type="button"
                  onClick={endCall}
                >
                  <CallEndIcon />
                </ControlButton> */}
              {/* border-radius: 50% !important;
  position: relative;
  outline: none; */}

              <MicIcon
                id="audioMute"
                fontSize={window.isMobile ? "small" : "default"}
                color={!muteToggle ? "secondary" : "inherit"}
                onClick={handleMute}
                style={{
                  marginTop: "4px",
                  cursor: "pointer",
                  position: "relative",
                  outline: "none",
                  ...(muteToggle
                    ? {
                        background:
                          "linear-gradient(45deg, transparent 49%, black 47%, black 53%, transparent 51%)",
                      }
                    : {}),
                }}
              />
              <CallEndIcon
                onClick={endCall}
                color="secondary"
                fontSize={window.isMobile ? "small" : "default"}
                // className={`${hangupToggle ? 'strike' : ''} `}
                style={{
                  marginTop: "4px",
                  marginLeft: "10px",
                  marginRight: "10px",
                  cursor: "pointer",
                  position: "relative",
                  outline: "none",
                }}
              />
              <VideoCallIcon
                id="videoOff"
                fontSize={window.isMobile ? "small" : "default"}
                color={!videoToggle ? "secondary" : "inherit"}
                onClick={handlevideoOff}
                style={{
                  marginTop: "4px",
                  cursor: "pointer",
                  position: "relative",
                  outline: "none",
                  ...(videoToggle
                    ? {
                        background:
                          "linear-gradient(45deg, transparent 49%, black 47%, black 53%, transparent 51%)",
                      }
                    : {}),
                }}
              />
            </BottomBar>
          </VideoDiv>
          <TitleWrapper>
            <TitleAndIcon>
              <Title>
                {(title && title.toUpperCase()) ||
                  (currStep.title && currStep.title.toUpperCase())}
              </Title>
              <Circle />
              <Circle />
              <Circle color />
              <BigCircle />
            </TitleAndIcon>
          </TitleWrapper>
          <WizardList>
            <currStep.component />
          </WizardList>
          {showButtons && (
            <>
              <BackButton
                onClick={backHandler}
                style={{
                  cursor: "pointer",
                  visibility: currStep.isBackAllowed ? "visible" : "hidden",
                }}
              >
                <img src={Previous} alt="cash" height="100%" width="100%" />
              </BackButton>
              <NextButton
                onClick={nextHandler}
                style={{
                  cursor: "pointer",
                  // new code
                  visibility:
                    (role === "Parent" && !currStep.isNextAllowed) ||
                    currStepId === 36 ||
                    currStepId === 37
                      ? "hidden"
                      : "visible",
                }}
              >
                <img src={NextImage} alt="cash" height="100%" width="100%" />
              </NextButton>
            </>
          )}
        </div>
      )}
      {/* <audio
        style={{ display: 'none' }}
        loop
        autoPlay
        muted={!(currStepId < 6 && currStepId !== 2)}
      >
        <source src={Music} type="audio/ogg" />
        <source src={Music} type="audio/mpeg" />
      </audio> */}
      {/* {(currStepId < 6 && currStepId !== 2)
        && <embed src={Music} loop="true" autostart="true" width="2" height="0" />} */}
    </WizardWraper>
  );
}

export default Wizard;
