/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
// import {
//   // BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
//   RadialBarChart, RadialBar, Legend, Tooltip,
// } from 'recharts';
// eslint-disable-next-line no-unused-vars
// import Highcharts from 'highcharts';
// import ReactHighcharts from 'react-highcharts';
// import highCharts3d from 'highcharts/highcharts-3d';
import { useDispatch } from 'react-redux';
import Highcharts from 'highcharts';
import highcharts3d from 'highcharts/highcharts-3d';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// import FusionCharts from 'fusioncharts';
// import charts from 'fusioncharts/fusioncharts.charts';
// import ReactFusioncharts from 'react-fusioncharts';
import { RadioCheckBox } from '../../ui';
import * as actionCreators from '../../_actions/actionCreators';
// import './result.css';

highcharts3d(Highcharts);
// charts(FusionCharts);

// const style = {
//   top: 0,
//   left: 280,
//   lineHeight: '24px',
// };
// ChartWrapper:
// @media all and (min-width: 1024px) {
//   transform: translate3d(6.8%, 30%, 0);
// }
const ChartWrapper = styled.div`
  position: relative;
  z-index: 1001;
  @media all and (min-width: 1024px) {
    transform: translate3d(6.8%, 30%, 0);
    width: 50%;
  }
  transform: translate(20px, -40px);
  @media only screen and (max-width: 600px) {
    transform: translate(20px, -40px);
    margin-top: 50px;
    width: 60%;
  }

  @media only screen and (min-width: 600px) {
    margin-top: 50px;
    width: 60%;
  }

  @media only screen and (min-width: 768px) {
    margin-top: 50px;
    width: 50%;
  }
  svg > g[class^="highcharts-credits"] > text{
    display: none;
}
`;

const ResultWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SelectionWrapper = styled.div`
  width: 30%;
  @media all and (min-width: 1024px) {
    transform: translate3d(-20%, 30%, 0);
  }
`;

const Suggestion = styled.h2`
  font-weight: 200;
  font-size: 1.4rem;
  padding-bottom: 8px;
  color: #00397a;
  @media (min-width: 920px) {
    font-size: 2.5rem;
  }
`;

const RadioCheckBoxExt = styled.div`
  margin: -15px 0px;
  @media (min-width: 920px) {
    margin: 0px 0px;
  }
`;
// "canvasBgAlpha": "0"
// "containerBackgroundOpacity": "0"
// "bgAlpha": "0"
const Result = ({
  data,
  graphJson,
  title,
  options,
  onSelectionChange,
}) => {
  // const dataSource = {
  //   chart: {
  //     caption: 'Top 5 High-Income Careers',
  //     yaxisname: 'Annual Income',
  //     showvalues: '2',
  //     numberprefix: '',
  //     theme: 'fusion',
  //     bgAlpha: '0',
  //     containerBackgroundOpacity: '0',
  //     canvasBgAlpha: '0',
  //   },
  //   data: [
  //     {
  //       label: 'Financial Advisor',
  //       value: '85',
  //     },
  //     {
  //       label: 'Physician Assistant',
  //       value: '50',
  //     },
  //     {
  //       label: 'IT Analysts',
  //       value: '45',
  //     },
  //     {
  //       label: 'College Professor',
  //       value: '30',
  //     },
  //     {
  //       label: 'Dentist',
  //       value: '20',
  //     },
  //   ],
  // };
  console.log('options+++', options);
  const dispatch = useDispatch();
  useEffect(() => {
    if (title) {
      dispatch(actionCreators.changePageTitle(title));
    }
    if (graphJson) {
      // commenting pie chart
      // Highcharts.chart('chart-container', {
      //   colors: ['#00b0ff', '#f6685e', '#009688', '#ff784e'],
      //   chart: {
      //     type: 'pie',
      //     options3d: {
      //       enabled: true,
      //       alpha: 45,
      //       beta: 0,
      //     },
      //     backgroundColor: 'none',
      //   },
      //   title: {
      //     text: '',
      //   },
      //   accessibility: {
      //     point: {
      //       valueSuffix: '%',
      //     },
      //   },
      //   credits: {
      //     enabled: false,
      //   },
      //   tooltip: {
      //     pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      //   },
      //   plotOptions: {
      //     pie: {
      //       allowPointSelect: true,
      //       cursor: 'pointer',
      //       depth: 35,
      //       dataLabels: {
      //         enabled: true,
      //         format: '{point.name}',
      //       },
      //     },
      //   },
      //   series: [{
      //     type: 'pie',
      //     name: title,
      //     data,
      //   }],
      // });
      Highcharts.chart('chart-container', {
        credits: {
          enabled: false,
        },
        colors: ['#00b0ff', '#f6685e', '#009688', '#ff784e'],
        chart: {
          // styledMode: true,
          type: 'column',
          options3d: {
            enabled: true,
            alpha: 0,
            beta: 20,
            depth: 50,
          },
          backgroundColor: 'none',
        },
        title: {
          text: '',
        },
        plotOptions: {
          // series: {
          //   pointWidth: 20,
          // },
          series: {
            groupPadding: 0,
            pointPadding: 0.1,
            borderWidth: 0,
          },
          column: {
            depth: 25,
          },
        },
        tooltip: {
          // formatter: () => {
          //   // If you want to see what is available in the formatter, you can
          //   // examine the `this` variable.
          //   //   console.log(this);
          //   return `<b>${Highcharts.numberFormat(this.y, 0)}</b>
          // <br/> in year: ${this.point.name}`;
          // },
          shared: true,
        },
        xAxis: {
          title: {
            text: 'Sub Categories',
            style: {
              fontSize: '20px !important',
              color: 'green',
            },
          },
          categories: graphJson.categories,
          labels: {
            style: {
              fontSize: '14px',
              color: 'black',
              fontWeight: 'bold',
            },
          },
        },
        yAxis: {
          max: 100,
          title: {
            text: 'Performance (%)',
            style: {
              fontSize: '20px !important',
              color: 'green',
            },
          },
          labels: {
            style: {
              fontSize: '14px',
              color: 'black',
              fontWeight: 'bold',
            },
          },
        },
        series: [{
          name: 'Performance',
          showInLegend: false,
          data: graphJson.percentages,
          colorByPoint: true,
        }],
      });
    }
  }, [data, title, dispatch, graphJson]);
  return (
    <ResultWrapper>
      <ChartWrapper>
        {/* <BarChart
        width={400}
        height={200}
        data={data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend wrapperStyle={{ bottom: -10 }} />
        <Bar dataKey="result" fill="#8884d8" />
      </BarChart> */}
        {/* <RadialBarChart
        width={500}
        height={300}
        cx={150}
        cy={150}
        innerRadius={20}
        outerRadius={140}
        barSize={10}
        data={data}
      >
        <RadialBar
          minAngle={15}
          label={{ fill: '#666', position: 'insideStart' }}
          background
          clockWise
          dataKey="result"
        />
        <Legend
          iconSize={10}
          width={120}
          height={140}
          layout="vertical"
          verticalAlign="middle"
          wrapperStyle={style}
        />
        <Tooltip />
      </RadialBarChart> */}
        {/* <ReactHighcharts config={mapOptions} /> */}
        <div id="chart-container" />
        {/* <ReactFusioncharts
          type="bar3d"
          width="100%"
          height="100%"
          dataFormat="JSON"
          dataSource={dataSource}
        /> */}
      </ChartWrapper>
      <SelectionWrapper>
        <Suggestion>
          {`Based on your answers, our ${title} experts have identified special programs by Orchids to aid your child's learning.`}
        </Suggestion>
        {/* {
          options.map((option) => (
            <RadioCheckBoxExt key={option.value}>
              <RadioCheckBox
                {...option}
                onChange={onSelectionChange}
                type="checkbox"
                small={window.isMobile}
              />
            </RadioCheckBoxExt>

          ))
        } */}
      </SelectionWrapper>
    </ResultWrapper>
  );
};

Result.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  graphJson: PropTypes.instanceOf(Object).isRequired,
  options: PropTypes.instanceOf(Array),
  onSelectionChange: PropTypes.func,
};

Result.defaultProps = {
  options: [],
  onSelectionChange: () => { },
};

export default Result;
