/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import Select from 'react-select';
import styled, { ThemeContext } from 'styled-components';
import chroma from 'chroma-js';

const SelectWrapper = styled.div`
  padding: 0 16px;
  margin: 10px 0;
`;

const ReactSelect = ({
  ...props
}) => {
  const theme = useContext(ThemeContext);

  const customStyles = {
    control: (providedStyles) => ({
      ...providedStyles,
      height: '40px',
      borderRadius: '10px',
      padding: '0px 30px',
      border: `1px solid ${theme.palette.secondary.textColor}`,
      boxShadow: 'none',
      '&:hover': {
        border: `1.8px solid ${theme.palette.secondary.main}`,
        cursor: 'pointer',
      },
      '&::-webkit-scrollbar': {
        width: '25px',
      },
      overflowY: 'auto',
      // ':focus': {
      //   border: `1.8px solid ${theme.palette.secondary.main}`,
      // },
    }),
    option: (providedStyles, state) => {
      const color = chroma(theme.palette.secondary.textColor);
      return ({
        ...providedStyles,
        backgroundColor: state.isDisabled
          ? null
          : state.isSelected
            ? color.css()
            : state.isFocused
              ? color.alpha(0.1).css()
              : null,
        color: state.isDisabled
          ? '#ccc'
          : state.isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : color.css,
        cursor: state.isDisabled ? 'not-allowed' : 'default',
      });
    },
    singleValue: (providedStyles) => ({
      ...providedStyles,
      color: theme.palette.secondary.textColor,
    }),
    multiValue: (providedStyles) => {
      const color = chroma(theme.palette.secondary.textColor);
      return {
        ...providedStyles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (providedStyles) => ({
      ...providedStyles,
      color: theme.palette.secondary.textColor,
    }),
    multiValueRemove: (providedStyles) => ({
      ...providedStyles,
      color: theme.palette.secondary.textColor,
      ':hover': {
        backgroundColor: theme.palette.secondary.textColor,
        color: 'white',
      },
    }),
    dropdownIndicator: (providedStyles) => {
      const color = chroma(theme.palette.secondary.textColor);
      return ({
        ...providedStyles,
        color: color.alpha(0.6).css(),
        '&:hover': {
          color: color.css(),
        },
      });
    },
    clearIndicator: (providedStyles) => {
      const color = chroma(theme.palette.secondary.textColor);
      return ({
        ...providedStyles,
        color: color.alpha(0.6).css(),
        '&:hover': {
          color: color.css(),
        },
      });
    },
    menu: (providedStyles) => ({
      ...providedStyles,
      color: '#1a1a1a',
      zIndex: 3,
    }),
  };

  return (
    <SelectWrapper>
      <Select
        {...props}
        styles={customStyles}
      />
    </SelectWrapper>
  );
};

export default ReactSelect;
