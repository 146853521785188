/* eslint-disable */
/* eslint-disable no-nested-ternary */
/* eslint-disable semi */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import AnimateWrapper from "../../containers/AnimateWrapper";
import MultiInput from "../../containers/MultiInput";
import { useSocket, debounce } from "../../utils";
import CueComponent from "../../containers/CueComponent";
import FaqModal from "../../containers/Modal";

const MULTI_INFO = "multi_info";

const MultiInformation = () => {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({});
  const stepId = useSelector((state) => state.common.stepId);
  const fullStateData = useSelector((state) => state.personalInfo);
  const isRole = useSelector((state) => state.personalInfo.role);
  const childName = useSelector((state) => state.personalInfo.child.child_name);
  const socket = useSocket();

  useEffect(() => {
    dispatch({
      type: "multipleInfo",
      payload: {
        // name: (fullStateData.name) || (fullSt
        // ateData.child && fullStateData.child.child_name) || '',
        name:
          fullStateData.name !== undefined
            ? fullStateData.name
            : fullStateData.child && fullStateData.child.child_name
            ? fullStateData.child.child_name
            : "",
        // parentName: (fullStateData.parentName) || (fullStateD
        // ata.lead && fullStateData.lead.lead_name) || '',
        parentName:
          fullStateData.parentName !== undefined
            ? fullStateData.parentName
            : fullStateData.lead && fullStateData.lead.lead_name
            ? fullStateData.lead.lead_name
            : "",
        // contact: (fullStateData.contact) || (fullStateData.le
        // ad && fullStateData.lead.lead_contact_no) || '',
        contact:
          fullStateData.contact !== undefined
            ? fullStateData.contact
            : fullStateData.lead && fullStateData.lead.lead_contact_no
            ? fullStateData.lead.lead_contact_no
            : "",
        // email: (fullStateData.email) || (fullStateData.le
        // ad && fullStateData.lead.lead_email_id) || '',
        email:
          fullStateData.email !== undefined
            ? fullStateData.email
            : fullStateData.lead && fullStateData.lead.lead_email_id
            ? fullStateData.lead.lead_email_id
            : "",
      },
    });
  }, [stepId]);

  useEffect(() => {
    if (socket) {
      socket.bind(MULTI_INFO, (data) => {
        console.log("MultiInfo+++", data);
        dispatch({
          type: "multipleInfo",
          payload: {
            ...data,
          },
        });
      });
    }
  }, [socket, dispatch]);

  const triggerSocket = useRef(
    // eslint-disable-next-line func-names
    debounce(function (existedInputs, name, value) {
      socket.trigger(MULTI_INFO, {
        ...existedInputs,
        [name]: value,
      });
    }, 800)
  ).current;

  const data = () => (
    <div>
      <p style={{ fontSize: 14 }}>
        1. Show interest in the child and appreciate
        <br />
        2. Please ask the Parent to enter a few details of your child.
        <br />
        3. While the Parent is entering the details have a small pep talk with
        the Child <br />
        4. Check with &nbsp;
        {childName}
        &apos;s , siblings, hobbies, interest/likes..
        <br />
        5. Tell Me about Your {}
        ? let this be open ended Counselor to make notes on the answer to make
        follow on question
        <br />
        6. What Are {childName} Greatest Strengths (3-4)
        <br />
        7. What Does {childName} Enjoy Doing at Home.
        <br />
        8. What is {childName} Morning and Night time Routine Like.
        <br />
        9. How is your {childName} doing in his current school ? Why are you
        looking to change schools.
        <br />
        10. What Areas Do you think {childName} Need Support In.
        <br />
        11. How do you measure your {childName} progress.
      </p>
    </div>
  );

  useEffect(() => {
    if (fullStateData) {
      const INIT_STATE = {
        // name: (fullStateData.name) || (fullSt
        // ateData.child && fullStateData.child.child_name) || '',
        name:
          fullStateData.name !== undefined
            ? fullStateData.name
            : fullStateData.child && fullStateData.child.child_name
            ? fullStateData.child.child_name
            : "",
        // parentName: (fullStateData.parentName) || (fullStateD
        // ata.lead && fullStateData.lead.lead_name) || '',
        parentName:
          fullStateData.parentName !== undefined
            ? fullStateData.parentName
            : fullStateData.lead && fullStateData.lead.lead_name
            ? fullStateData.lead.lead_name
            : "",
        // contact: (fullStateData.contact) || (fullStateData.le
        // ad && fullStateData.lead.lead_contact_no) || '',
        contact:
          fullStateData.contact !== undefined
            ? fullStateData.contact
            : fullStateData.lead && fullStateData.lead.lead_contact_no
            ? fullStateData.lead.lead_contact_no
            : "",
        // email: (fullStateData.email) || (fullStateData.le
        // ad && fullStateData.lead.lead_email_id) || '',
        email:
          fullStateData.email !== undefined
            ? fullStateData.email
            : fullStateData.lead && fullStateData.lead.lead_email_id
            ? fullStateData.lead.lead_email_id
            : "",
      };
      // if (!fullStateData.name) {
      //   dispatch({
      //     type: 'multipleInfo',
      //     payload: { ...INIT_STATE },
      //   });
      // }
      setInputs(INIT_STATE);
    }
  }, [stepId, fullStateData]);

  const multiInputsTop = [
    {
      id: "name",
      label: "Student Name",
      value: "name",
      type: "text",
    },
    {
      id: "parentName",
      label: "Parent Name",
      value: "parentName",
      type: "text",
    },
    {
      id: "contact",
      label: "Contact Number",
      value: "contact",
      type: "number",
    },
    {
      id: "email",
      label: "Email Id",
      value: "email",
      type: "text",
    },
  ];
  // const multiInputsBottom = [
  //   {
  //     id: 'city',
  //     label: 'Your City is',
  //   },
  //   {
  //     id: 'interest',
  //     label: 'Which branch are you interested in',
  //   },
  //   {
  //     id: 'grade',
  //     label: 'Which Grade are you getting into',
  //   }];

  const setMultiInputs = (info) => {
    const oldInfo = { ...inputs };
    const { name, value } = info.target;
    setInputs({ ...oldInfo, [name]: value });
    console.log("Around Debounce+++");
    triggerSocket(inputs, name, value);
    dispatch({
      type: "multipleInfo",
      payload: {
        ...inputs,
        [info.target.name]: info.target.value,
      },
    });
  };

  return (
    <AnimateWrapper>
      <div>
        <MultiInput
          topInputs={multiInputsTop}
          // bottomInputs={multiInputsBottom}
          getInfo={setMultiInputs}
          initialValue={inputs}
        />
      </div>
      {isRole === "Counsellor" && <FaqModal />}

      {isRole === "Counsellor" && <CueComponent data={data} />}
    </AnimateWrapper>
  );
};

export default MultiInformation;
