import axios from 'axios';
import { ON_FETCH_PERSONALINFO_FAIL, ON_FETCH_PERSONALINFO_SUCCESS, ON_FETCH_START } from './constants';
import * as actionType from '../actionConstants';
import urls from '../../urls';
import * as actions from './actions';

function onSuccessFullFetch(data) {
  return {
    type: ON_FETCH_PERSONALINFO_SUCCESS,
    payload: {
      ...data,
      loading: false,
    },
  };
}

function onFailedFetch(err) {
  return {
    type: ON_FETCH_PERSONALINFO_FAIL,
    payload: {
      error: err,
      loading: false,
    },
  };
}

function onFetchStart(uuid) {
  return {
    type: ON_FETCH_START,
    payload: {
      loading: true,
      uuid,
    },
  };
}

function fetchPersonalInfo(uuid) {
  return (dispatch) => {
    dispatch(onFetchStart(uuid));

    axios.get(`${urls.fetchLeadDetails}?meeting_id=${uuid}`)
      .then((res) => {
        document.body.classList.add('loaded');
        const { result, role } = res.data;
        dispatch(onSuccessFullFetch({ ...result, role }));
        dispatch((actions.startCounsellerTimer(uuid)));
      })
      .catch((err) => {
        document.body.classList.add('loaded');
        dispatch(onFailedFetch(err.message));
        if (
          err.response
          && err.response.data
          && err.response.data.message
          && err.response.status === 400
        ) {
          dispatch({
            type: actionType.STEP_ID,
            payload: {
              id: 37,
              direction: 1,
            },
          });
        }
      });
  };
}

export default fetchPersonalInfo;
