/* eslint-disable */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-alert */
/* eslint-disable no-debugger */
import store from "../_reducers/index";
import {
  LandingPage,
  Introduction1,
  // Introduction2,
  ChildAcademicQuiz,
  ParentChildInfo,
  Questions,
  QuestionsPage,
  GatherOrVerifyInfo2,
  MultiInfo,
  ChildJourney,
  Result,
  VideoCategoryOne,
  FeatureList,
  ProgramList,
  PamentInitiationAndThank,
  BillingDetails,
  PaymentSuccessFailPage,
  PaymentAirPayPage,
  LiteracyVideo,
  NumeracyVideo,
  PrincipalVideo,
  ThankYouVisitAgain,
  ExpiredPage,
} from "../pages";
// import GatherOrVerifyInfo3 from '../pages/GatherOrVerifyInfo3';
// import GatherOrVerifyInfo4 from '../pages/GatherOrVerifyInfo4';
import * as actionTypes from "../_actions/actionConstants";
import * as actions from "../_actions/personalInfo/actions";
import { updateViewed, isAllViewed } from "../pages/FeatureLIst/utilFunc";
import { GlobalSocket } from "../utils";

const configs = [
  {
    id: 0,
    step: 0,
    component: LandingPage,
    back: null,
    title: "Welcome",
    isBackAllowed: false,
    isNextAllowed: false,
    next() {
      const nextIds = [1];
      return nextIds[0];
    },
  },
  {
    id: 1,
    step: 1,
    component: ParentChildInfo,
    title: "Welcome",
    isBackAllowed: true,
    isNextAllowed: true,
    back() {
      return 0;
    },
    next() {
      const state = store.getState();
      if (state.personalInfo.isParent === null) {
        window.alert("choose any option");
        return 1;
      }
      const nextIds = [4];
      return nextIds[0];
    },
  },
  {
    id: 2,
    step: 2,
    isBackAllowed: true,
    isNextAllowed: false,
    component: Introduction1,
    title: "Tour",
    back() {
      const backIds = [6];
      return backIds[0];
    },
    next() {
      const nextIds = [7];
      return nextIds[0];
    },
  },
  {
    id: 4,
    step: 4,
    isBackAllowed: true,
    isNextAllowed: true,
    component: MultiInfo,
    title: "Details",
    back() {
      const backIds = [1];
      return backIds[0];
    },
    next() {
      const state = store.getState();
      if (state.personalInfo) {
        if (
          !state.personalInfo.name ||
          !state.personalInfo.parentName ||
          !state.personalInfo.contact ||
          !state.personalInfo.email
        ) {
          window.alert("Enter All Fields");
          return 4;
        }
        if (
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            state.personalInfo.email
          )
        ) {
          window.alert("Enter Proper Email Address");
          return 4;
        }
      }
      const nextIds = [5];
      return nextIds[0];
    },
  },
  {
    id: 5,
    step: 5,
    isBackAllowed: true,
    isNextAllowed: true,
    title: "Details",
    component: GatherOrVerifyInfo2,
    back() {
      const backIds = [4];
      return backIds[0];
    },
    next() {
      const { personalInfo } = store.getState();
      const { lead, child, city } = personalInfo;
      if ((!lead?.city && !city?.id) || !child?.child_class || !lead?.branch) {
        window.alert("Please enter all fields");
        return 5;
      }
      store.dispatch(actions.updateUserDetails(personalInfo));
      const nextIds = [6];
      return nextIds[0];
    },
  },
  {
    id: 6,
    step: 6,
    isBackAllowed: true,
    isNextAllowed: false,
    component: ChildJourney,
    title: "Introduction",
    back() {
      const backIds = [5];
      return backIds[0];
    },
    next() {
      const nextIds = [2];
      return nextIds[0];
    },
  },
  {
    id: 7,
    step: 7,
    isBackAllowed: true,
    isNextAllowed: true,
    component: ChildAcademicQuiz,
    title: "Quiz",
    back() {
      const backIds = [2];
      return backIds[0];
    },
    next() {
      const nextIds = [8];
      return nextIds[0];
    },
  },
  {
    id: 8,
    step: 8,
    isBackAllowed: false,
    isNextAllowed: true,
    component: Questions,
    back() {
      // const backIds = [1];
      // return backIds[0];
    },
    next() {
      const stepIds = [18];
      const state = store.getState();
      const {
        questions,
        questionNo,
        questionType,
        results,
        is_error: isError,
      } = state.questions;
      let nextStep;
      if (!isError) {
        const currId = questions[questionNo].id;
        const haveResult = results.some((item) => item.id === currId);

        if (questions.length - 1 === questionNo && haveResult) {
          if (questionType === 1) {
            store.dispatch({ type: actionTypes.CHANGE_QUESTION_TYPE });
            store.dispatch({ type: actionTypes.RESET_QUESTION });
            GlobalSocket.trigger("RESET_QUESTION");

            // nextStep = stepIds[0];
          } else {
            nextStep = stepIds[0];
          }
        } else if (haveResult) {
          store.dispatch({
            type: actionTypes.INCREMENT_QUESTION,
            payload: {
              data: questionNo + 1,
            },
          });
          GlobalSocket.trigger("INCREMENT_QUESTION", {
            step: questionNo + 1,
          });
        } else if (!haveResult) {
          window.alert("Please select a answer");
        }
      }
      if (isError) {
        if (questionType === 2) {
          nextStep = stepIds[0];
        } else {
          store.dispatch({ type: actionTypes.CHANGE_QUESTION_TYPE });
          store.dispatch({ type: actionTypes.RESET_QUESTION });
          GlobalSocket.trigger("RESET_QUESTION");
        }
      }
      return nextStep || 8;
    },
  },

  {
    id: 12,
    step: 12,
    isBackAllowed: false,
    isNextAllowed: true,
    component: ProgramList,
    back() {
      const backIds = [8];
      return backIds[0];
    },
    next() {
      const state = store.getState();
      const {
        specialProgramsSubCategoryIds = [],
        // specialProgramsSubSubCategoryIds = [],
      } = state.selectedCategories;
      const { categoriesViewedInfo = [] } = state.specialPrograms;
      store.dispatch({
        type: actionTypes.UPDATE_VIEWED_PROGRAMS,
        payload: { results: updateViewed(categoriesViewedInfo) },
      });
      let nextIds = [13];
      if (
        specialProgramsSubCategoryIds.length === 0 ||
        isAllViewed(categoriesViewedInfo)
      ) {
        // nextIds = [26];
        store.dispatch({ type: actionTypes.CHANGE_QUESTION_TYPE });
        store.dispatch({ type: actionTypes.RESET_QUESTION });
        // GlobalSocket.trigger('RESET_QUESTION');
        nextIds = [8];
      }
      return nextIds[0];
    },
  },
  {
    id: 13,
    step: 13,
    isBackAllowed: true,
    isNextAllowed: true,
    component: VideoCategoryOne,
    back() {
      const state = store.getState();
      const { currentStepSpecialProgram } = state.videoCategories;
      if (currentStepSpecialProgram > 1) {
        store.dispatch({
          type: actionTypes.DECREMENT_STEP_SPECIAL_PROGRAM,
          payload: { currentStepSpecialProgram: currentStepSpecialProgram - 1 },
        });
        GlobalSocket.trigger("change_video_step", {
          type: actionTypes.DECREMENT_STEP_SPECIAL_PROGRAM,
          payload: { currentStepSpecialProgram: currentStepSpecialProgram - 1 },
        });
        const backIds = [13];
        return backIds[0];
      }
      const backIds = [8];
      return backIds[0];
    },
    next() {
      const state = store.getState();
      const { currentStepSpecialProgram, videoLinks } = state.videoCategories;
      if (!videoLinks.length) {
        store.dispatch({ type: actionTypes.CHANGE_QUESTION_TYPE });
        store.dispatch({ type: actionTypes.RESET_QUESTION });
        const nextIds = [8];
        return nextIds[0];
      }
      if (currentStepSpecialProgram === videoLinks.length) {
        store.dispatch({ type: actionTypes.CHANGE_QUESTION_TYPE });
        store.dispatch({ type: actionTypes.RESET_QUESTION });
        const nextIds = [8];
        return nextIds[0];
      }

      const { categoriesViewedInfo = [] } = state.specialPrograms;
      if (isAllViewed(categoriesViewedInfo)) {
        const nextIds = [8];
        return nextIds[0];
      }
      store.dispatch({
        type: actionTypes.INCREMENT_STEP_SPECIAL_PROGRAM,
        payload: { currentStepSpecialProgram: currentStepSpecialProgram + 1 },
      });
      GlobalSocket.trigger("change_video_step", {
        type: actionTypes.INCREMENT_STEP_SPECIAL_PROGRAM,
        payload: { currentStepSpecialProgram: currentStepSpecialProgram + 1 },
      });
      const nextIds = [13];
      return nextIds[0];
    },
  },
  {
    id: 18,
    step: 18,
    isBackAllowed: false,
    isNextAllowed: true,
    component: Result,
    back() {
      // const backIds = [27];
      // return backIds[0];
    },
    next() {
      const state = store.getState();
      const { summaryKey } = state.questions;
      if (summaryKey === "literacy") {
        return 19;
      }
      const nextIds = [20];
      return nextIds[0];
    },
  },
  {
    id: 19,
    step: 19,
    isBackAllowed: false,
    isNextAllowed: true,
    component: LiteracyVideo,
    back() {
      // const backIds = [27];
      // return backIds[0];
    },
    next() {
      const state = store.getState();
      const { currVideoIndex, summaryVideo, summaryKey } = state.questions;

      if (
        summaryVideo[summaryKey] &&
        currVideoIndex < summaryVideo[summaryKey].length - 1
      ) {
        GlobalSocket.trigger("CHANGE_CURR_VIDEO_INDEX");
        const nextIds = [19];
        return nextIds[0];
      }

      GlobalSocket.trigger("CHANGE_SUMMARY");
      store.dispatch({
        type: actionTypes.CHANGE_SUMMARY,
        payload: {
          data: "numeracy",
        },
      });
      const nextIds = [18];
      return nextIds[0];
    },
  },
  {
    id: 20,
    step: 20,
    isBackAllowed: false,
    isNextAllowed: true,
    component: NumeracyVideo,
    back() {
      // const backIds = [27];
      // return backIds[0];
    },
    next() {
      const state = store.getState();
      const { currVideoIndex, summaryVideo, summaryKey } = state.questions;

      if (
        summaryVideo[summaryKey] &&
        currVideoIndex < summaryVideo[summaryKey].length - 1
      ) {
        store.dispatch({
          type: actionTypes.CHANGE_CURR_VIDEO_INDEX,
          index: currVideoIndex + 1,
        });
        GlobalSocket.trigger("CHANGE_CURR_VIDEO_INDEX");
        const nextIds = [19];
        return nextIds[0];
      }

      const nextIds = [22];
      return nextIds[0];
    },
  },
  {
    id: 22,
    step: 22,
    isBackAllowed: true,
    isNextAllowed: true,
    component: FeatureList,
    title: "Features",
    back() {
      const backIds = [18];
      return backIds[0];
    },
    next() {
      const state = store.getState();
      /* making isViewed = true */
      const { categoriesViewedInfo = [] } = state.schoolFeatures;
      store.dispatch({
        type: actionTypes.UPDATE_VIEWED_PROGRAMS,
        payload: { results: updateViewed(categoriesViewedInfo) },
      });
      const {
        featureListSubSubCategoryIds = [],
        featureListSubCategoryIds = [],
      } = state.selectedCategories;
      if (
        featureListSubCategoryIds.length === 0 &&
        featureListSubSubCategoryIds.length === 0
      ) {
        store.dispatch({
          type: actionTypes.ON_BACK_CLICK,
          payload: { isBack: false },
        });
        GlobalSocket.trigger("handle_back", {
          type: actionTypes.ON_BACK_CLICK,
          payload: { isBack: false },
        });
        const nextIds = [28];
        return nextIds[0];
      }
      store.dispatch({
        type: actionTypes.ON_BACK_CLICK,
        payload: { isBack: false },
      });
      GlobalSocket.trigger("handle_back", {
        type: actionTypes.ON_BACK_CLICK,
        payload: { isBack: false },
      });
      const nextIds = [23];
      return nextIds[0];
    },
  },
  {
    id: 23,
    step: 23,
    isBackAllowed: true,
    isNextAllowed: false,
    component: VideoCategoryOne,
    back() {
      const state = store.getState();
      const { currentStepFeatureListOne } = state.videoCategories;
      if (currentStepFeatureListOne === 1) {
        const backIds = [22];
        return backIds[0];
      }
      store.dispatch({
        type: actionTypes.DECREMENT_STEP_FEATURE_LIST_ONE,
        payload: { currentStepFeatureListOne: currentStepFeatureListOne - 1 },
      });
      GlobalSocket.trigger("change_video_step", {
        type: actionTypes.DECREMENT_STEP_FEATURE_LIST_ONE,
        payload: { currentStepFeatureListOne: currentStepFeatureListOne - 1 },
      });
      const backIds = [23];
      return backIds[0];
    },
    next() {
      const state = store.getState();
      const { currentStepFeatureListOne, featureVideoLinksOne } =
        state.videoCategories;

      if (!featureVideoLinksOne.length) {
        store.dispatch({
          type: actionTypes.RESET_SELECTED_CATEGORY,
        });
        const nextIds = [24];
        return nextIds[0];
      }
      if (currentStepFeatureListOne === featureVideoLinksOne.length) {
        store.dispatch({
          type: actionTypes.RESET_SELECTED_CATEGORY,
        });
        const nextIds = [24];
        return nextIds[0];
      }
      store.dispatch({
        type: actionTypes.INCREMENT_STEP_FEATURE_LIST_ONE,
        payload: { currentStepFeatureListOne: currentStepFeatureListOne + 1 },
      });
      GlobalSocket.trigger("change_video_step", {
        type: actionTypes.INCREMENT_STEP_FEATURE_LIST_ONE,
        payload: { currentStepFeatureListOne: currentStepFeatureListOne + 1 },
      });
      const nextIds = [23];
      return nextIds[0];
    },
  },
  {
    id: 24,
    step: 24,
    isBackAllowed: true,
    isNextAllowed: true,
    component: FeatureList,
    title: "Features",
    back() {
      store.dispatch({
        type: actionTypes.ON_BACK_CLICK,
        payload: { isBack: true },
      });
      GlobalSocket.trigger("handle_back", {
        type: actionTypes.ON_BACK_CLICK,
        payload: { isBack: true },
      });
      const backIds = [23];
      return backIds[0];
    },
    next() {
      const state = store.getState();
      /* making isViewed = true */
      const { categoriesViewedInfo = [] } = state.schoolFeatures;
      store.dispatch({
        type: actionTypes.UPDATE_VIEWED_PROGRAMS,
        payload: { results: updateViewed(categoriesViewedInfo) },
      });
      const {
        featureListSubSubCategoryIds = [],
        featureListSubCategoryIds = [],
      } = state.selectedCategories;
      if (
        featureListSubCategoryIds.length === 0 &&
        featureListSubSubCategoryIds.length === 0
      ) {
        store.dispatch({
          type: actionTypes.ON_BACK_CLICK,
          payload: { isBack: false },
        });
        GlobalSocket.trigger("handle_back", {
          type: actionTypes.ON_BACK_CLICK,
          payload: { isBack: false },
        });
        const nextIds = [28];
        return nextIds[0];
      }
      store.dispatch({
        type: actionTypes.ON_BACK_CLICK,
        payload: { isBack: false },
      });
      GlobalSocket.trigger("handle_back", {
        type: actionTypes.ON_BACK_CLICK,
        payload: { isBack: false },
      });
      const nextIds = [25];
      return nextIds[0];
    },
  },
  {
    id: 25,
    step: 25,
    isBackAllowed: true,
    isNextAllowed: false,
    component: VideoCategoryOne,
    back() {
      const state = store.getState();
      const { currentStepFeatureListTwo } = state.videoCategories;

      if (currentStepFeatureListTwo === 1) {
        const backIds = [24];
        return backIds[0];
      }
      store.dispatch({
        type: actionTypes.DECREMENT_STEP_FEATURE_LIST_TWO,
        payload: { currentStepFeatureListTwo: currentStepFeatureListTwo - 1 },
      });
      GlobalSocket.trigger("change_video_step", {
        type: actionTypes.DECREMENT_STEP_FEATURE_LIST_TWO,
        payload: { currentStepFeatureListTwo: currentStepFeatureListTwo - 1 },
      });
      const backIds = [25];
      return backIds[0];
    },
    next() {
      const state = store.getState();
      const { currentStepFeatureListTwo, featureVideoLinksTwo } =
        state.videoCategories;

      if (!featureVideoLinksTwo.length) {
        store.dispatch({
          type: actionTypes.RESET_SELECTED_CATEGORY,
        });
        const nextIds = [28];
        return nextIds[0];
      }
      if (currentStepFeatureListTwo === featureVideoLinksTwo.length) {
        store.dispatch({
          type: actionTypes.RESET_SELECTED_CATEGORY,
        });
        const nextIds = [28];
        return nextIds[0];
      }
      store.dispatch({
        type: actionTypes.INCREMENT_STEP_FEATURE_LIST_TWO,
        payload: { currentStepFeatureListTwo: currentStepFeatureListTwo + 1 },
      });
      GlobalSocket.trigger("change_video_step", {
        type: actionTypes.INCREMENT_STEP_FEATURE_LIST_TWO,
        payload: { currentStepFeatureListTwo: currentStepFeatureListTwo + 1 },
      });
      const nextIds = [25];
      return nextIds[0];
    },
  },
  {
    id: 28,
    step: 28,
    isBackAllowed: true,
    isNextAllowed: true,
    component: QuestionsPage,
    title: "Questions",
    back() {
      const { featureListSubSubCategoryIds = [] } =
        store.selectedCategories || {};
      if (!featureListSubSubCategoryIds) {
        const backIds = [24];
        return backIds[0];
      }
      store.dispatch({
        type: actionTypes.ON_BACK_CLICK,
        payload: { isBack: true },
      });
      GlobalSocket.trigger("handle_back", {
        type: actionTypes.ON_BACK_CLICK,
        payload: { isBack: true },
      });
      const backIds = [25];
      return backIds[0];
    },
    next() {
      const nextIds = [30];
      return nextIds[0];
    },
  },
  {
    id: 30,
    step: 30,
    isBackAllowed: true,
    isNextAllowed: false,
    title: "Testimonials",
    component: PrincipalVideo,
    back() {
      return 28;
    },
    next() {
      const nextIds = [36];
      return nextIds[0];
    },
  },
  {
    id: 32,
    step: 32,
    isBackAllowed: true,
    isNextAllowed: true,
    component: PamentInitiationAndThank,
    back() {
      const backIds = [28];
      return backIds[0];
    },
    next() {
      const state = store.getState();
      if (state.personalInfo.pamentNow === null) {
        window.alert("Select Your Choice");
        return 32;
      }
      if (state.personalInfo.pamentNow === true) {
        return 33;
      }
      const nextIds = [36];
      return nextIds[0];
    },
  },
  {
    id: 33,
    step: 33,
    isBackAllowed: true,
    isNextAllowed: true,
    component: BillingDetails,
    title: "Payment",
    back() {
      const backIds = [32];
      return backIds[0];
    },
    next() {
      const state = store.getState();
      // eslint-disable-next-line max-len
      if (
        state.personalInfo.applicationDetails.length !== 0 &&
        (!state.personalInfo.applicationDetails[0].student_name ||
          !state.personalInfo.applicationDetails[0].phoneNumber ||
          !state.personalInfo.applicationDetails[0].parentName ||
          !state.personalInfo.applicationDetails[0].address ||
          !state.personalInfo.applicationDetails[0].dateofBirth)
      ) {
        window.alert("please fill your application form first");
        return 33;
      }
      if (state.personalInfo.applicationEditStatus === true) {
        window.alert("First Save the Appication Details");
        return 33;
      }
      store.dispatch({
        type: actionTypes.OPEN_TERMS_AND_CONDITIONS,
        status: true,
      });
      const nextIds = [33];
      return nextIds[0];
    },
  },
  {
    id: 34,
    step: 34,
    isBackAllowed: true,
    isNextAllowed: true,
    component: PaymentAirPayPage,
    back() {
      const backIds = [33];
      return backIds[0];
    },
    next() {
      const nextIds = [34];
      return nextIds[0];
    },
  },
  {
    id: 35,
    step: 35,
    isBackAllowed: false,
    isNextAllowed: true,
    component: PaymentSuccessFailPage,
    back() {
      const backIds = [33];
      return backIds[0];
    },
    next() {
      const nextIds = [36];
      return nextIds[0];
    },
  },
  {
    id: 36,
    step: 36,
    isBackAllowed: false,
    isNextAllowed: false,
    component: ThankYouVisitAgain,
    back() {
      const backIds = [28];
      return backIds[0];
    },
    next() {
      const nextIds = [36];
      return nextIds[0];
    },
  },
  {
    id: 37,
    step: 37,
    component: ExpiredPage,
    isBackAllowed: false,
    isNextAllowed: false,
    next() {
      // const nextIds = [1];
      // return nextIds[0];
      const nextIds = [0];
      return nextIds[0];
    },
  },
];

const configMap = new Map();

configs.forEach((element) => {
  configMap.set(element.id, element);
});

const startId = 0;

export { configMap, startId };
