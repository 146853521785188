/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { VideoText, AnimateWrapper } from "../../containers";
import { Loader } from "../../ui";
import img from "../../assets/pictures/boy.svg";
import { useSocket } from "../../utils";
import * as actionCreators from "../../_actions/actionCreators";
import CueComponent from "../../containers/CueComponent";
import FaqModal from "../../containers/Modal";
// import store from '../../_reducers';

const LiteracyVideo = () => {
  const dispatch = useDispatch();
  // const meetingId = useSelector((state) => state.personalInfo.meeting_id);
  // const isParent = useSelector((state) => state.personalInfo.isParent);
  const videos = useSelector((state) => state.questions.summaryVideo);
  const videoIndex = useSelector((state) => state.questions.currVideoIndex);
  const isLoading = useSelector((state) => state.common.loading);
  // const resultSummary = useSelector((state) => state.questions.resultSummary);
  // const summaryKey = useSelector((state) => state.questions.summaryKey);
  // eslint-disable-next-line no-unused-vars
  const [dataInfo, setDataInfo] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [suggestions, setSuggestions] = useState([]);
  const socket = useSocket();
  const isRole = useSelector((state) => state.personalInfo.role);

  // useEffect(() => {
  //   if (resultSummary
  //     && resultSummary.literacy_preferred_courses
  //     && resultSummary.literacy_score.length >= 2) {
  //     const dataToSave = resultSummary.literacy_preferred_courses[1].map((item) => ({
  //       name: item.category_name,
  //       result: item.score,
  //     }));
  //     const suggest = resultSummary.literacy_preferred_courses[0].map((item, index) => ({
  //       label: item,
  //       name: item,
  //       value: index,
  //       checked: true,
  //     }));
  //     setDataInfo(dataToSave);
  //     setSuggestions(suggest);
  //   }
  //   // dispatch(actionCreators.fetchLiteracyVideo(meetingId, !isParent));
  // }, [dispatch, meetingId, isParent, resultSummary]);
  useEffect(() => {
    console.log("logging the video url.............");
    console.log(
      videos.literacy &&
        videos.literacy[videoIndex] &&
        videos.literacy[videoIndex].video
    );
  }, []);

  useEffect(() => {
    if (videos && videos.literacy && videos.literacy[videoIndex]) {
      // dispatch(actionCreators.changePageTitle(videos.literacy[videoIndex].title));
      dispatch(actionCreators.changePageTitle("Literacy"));
    }
  }, [dispatch, videos, videoIndex]);

  useEffect(() => {
    if (socket) {
      socket.bind("CHANGE_CURR_VIDEO_INDEX", () => {
        dispatch(actionCreators.changeVideoIndex(videoIndex + 1));
      });
    }
    return () => {
      if (socket) {
        socket.unbind("CHANGE_CURR_VIDEO_INDEX");
      }
      if (videos.literacy && videoIndex === videos.literacy.length - 1) {
        dispatch(actionCreators.changeSummaryType());
      }
    };
  }, [socket, dispatch, videoIndex, videos]);

  const data = () => (
    <div>
      <p style={{ fontSize: 14 }}>
        Counselor Cue : Ask the Parent : Does your child make friends easily?
        Tell the Parent that we focus on Public Speaking skills <br />
        development by giving the child the opportunity to speak and develop
        their <br />
        • confidence,
        <br />
        • eye contact,
        <br />
        • body language,
        <br />
        • effective communication,
        <br />• interpersonal and social skills
      </p>
    </div>
  );

  return (
    <AnimateWrapper key={videoIndex}>
      <VideoText
        data={
          videos.literacy &&
          videos.literacy[videoIndex] &&
          videos.literacy[videoIndex].title
        }
        videoSrc={
          videos.literacy &&
          videos.literacy[videoIndex] &&
          videos.literacy[videoIndex].video
        }
        img={img}
        imgPosition="top-right-img"
      />
      {isRole === "Counsellor" && <FaqModal />}

      {isRole === "Counsellor" && <CueComponent data={data} />}

      <Loader open={isLoading} />
    </AnimateWrapper>
  );
};

export default LiteracyVideo;
