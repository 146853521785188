/* eslint-disable */
import React, { useState } from "react";
// import React, { useRef, useState, useEffect } from 'react';
import styled from "styled-components";
// import urls from '../../urls';
import { useSelector } from "react-redux";
import { ReactSelect, Modal } from "../../ui";

import QuestonImage from "../../assets/pictures/Faq.svg";
import CollapsibleDiv from "../CollapsibleDiv";
import urls from "../../urls";
import NoDataDescription from "../../assets/pictures/noDataDescription.svg";

// import { motion } from 'framer-motion';

const FaqModalDiv = styled(Modal)`
  background: #ffffff !important;
  overflow: visible !important;
`;

const TopImg = styled.img`
  width: 100px;
  position: absolute;
  top: -50px;
  height: auto;
`;

const FaqHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FaqContent = styled.div`
  display: flex;
  width: 100%;
  height: 340px;
  padding: 10px 15px;
`;

const FaqCategories = styled.div`
  display: flex;
  flex-direction: column;
  width: 18%;
  height: 100%;
  border-radius: 10px;
  background: #00397a;
  margin-right: 2%;
  justify-content: space-around;
  align-items: center;
  color: #ffffff;
  overflow: auto;
`;

const FaqQuestions = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  overflow: auto;
`;

const FaqSearch = styled.input`
  padding: 7px;
  padding-top: 6px;
  border: 1px solid blue;
  margin-top: 8px;
  font-size: 17px;
  border-right: none;
  outline: none;
`;

const FaqButton = styled.button`
  position: absolute;
  z-index: 10005;
  bottom: 5%;
  right: 50%;
  padding: 10px;
  border-radius: 10px;
  background-color: #00397a;
  border: none;
  outline: none;
  box-shadow: 2px 2px 4px #003068, -2px -2px 4px #00428c;
  cursor: pointer;
  color: #ffffff;
`;
const ReactedSelect = styled(ReactSelect)`
  padding: 7px;
  padding-top: 6px;
  border: 1px solid blue;
  margin-top: 8px;
  font-size: 17px;
  outline: none;
  width: 380px;
`;
const CatButton = styled.button`
  background-color: #00397a;
  border: none;
  width: 100%;
  cursor: pointer;
  color: #ffffff;
`;

const FaqModal = () => {
  const [isOpen, setOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [searchData, setSearchData] = useState("nodata");
  const { faq = [] } = useSelector((state) => state.personalInfo);
  const { categoryList = [] } = useSelector((state) => state.personalInfo);
  const [selectedCategory, setSelectedCategory] = useState({});
  // console.log(faqs);

  const openModal = () => {
    setOpen((prevVal) => !prevVal);
  };

  const handleModal = () => {
    setOpen(false);
  };

  const getSearchVal = (e) => {
    setSearchVal(e.target.value);
  };

  const getSearchData = async () => {
    const response = await fetch(`${urls.searchFaq}?question=${searchVal}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const fetchData = await response.json();
    setSearchData(fetchData);
  };

  const getFilteredData = async (id) => {
    // TODO: change URL
    console.log("id :");
    console.log(id);
    const response = await fetch(`${urls.fiterFaqsAPI}?category_id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const fetchData = await response.json();
    setSearchData(fetchData.results);
  };

  const onCategoryChange = (e) => {
    if (e) {
      setSelectedCategory(e);
      getFilteredData(e.id);
      setSearchVal("");
    }
  };
  // const getCategories = async () => {
  //   const response = await fetch(`${urls.fetchCats}`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //   });
  //   const fetchData = await response.json();
  //   setCategories(fetchData);
  // };
  //   async function getFaqs() {
  //     const response = await fetch(`${urls.getFaq}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Accept: 'application/json',
  //       },
  //     });
  //     const fetchData = await response.json();
  //     setFaq(fetchData);
  //   }

  // useEffect(() => {
  //   getAllCate();
  // }, []);

  return (
    <>
      <FaqButton onClick={openModal} type="button">
        {isOpen ? "Close" : "Open"} FAQ
      </FaqButton>
      <FaqModalDiv
        open={isOpen}
        closeHandler={handleModal}
        large
        style={{
          borderRadius: "20px",
          opacity: 1,
          overflow: "visible",
          backgroundColor: "#F9F9FC",
        }}
      >
        <FaqHeader>
          <TopImg
            src={QuestonImage}
            width="10%"
            height="10%"
            alt="anyquestions"
          />
          <div style={{ marginTop: "40px", textAlign: "center" }}>
            <p>Couldn&apos;t find the answer you were looking for ? </p>
            <p>We have shared some of the most frequent queries</p>
            {/* <hr style={{ border: '1px solid black', marginTop: '20px' }} /> */}
          </div>
          <div style={{ height: "48px" }}>
            <FaqSearch
              type="text"
              placeholder="Search.."
              value={searchVal}
              onChange={(e) => getSearchVal(e)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  getSearchData();
                }
              }}
            />
            <button
              type="button"
              onClick={getSearchData}
              style={{
                padding: "8px",
                fontSize: "15px",
                border: "1px solid blue",
                borderLeft: "none",
                cursor: "pointer",
              }}
            >
              Search
            </button>
          </div>
        </FaqHeader>
        <FaqContent>
          <FaqCategories>
            <CatButton key={0} onClick={() => setSearchData(faq)}>
              <CatButton type="button" style={{ padding: "5px 0px" }}>
                All
              </CatButton>
            </CatButton>
            {categoryList &&
              categoryList.length !== 0 &&
              categoryList.map((cat) => (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <CatButton key={cat.id} onClick={() => onCategoryChange(cat)}>
                  <CatButton type="button" style={{ padding: "5px 0px" }}>
                    {cat.category_name}
                  </CatButton>
                </CatButton>
              ))}
          </FaqCategories>
          <FaqQuestions>
            {searchData &&
              searchData === "nodata" &&
              faq &&
              faq.length > 0 &&
              faq.map((faqs) => (
                <CollapsibleDiv
                  title={faqs.question}
                  newContent={faqs.answer}
                />
              ))}

            {searchData &&
              searchData !== "nodata" &&
              searchData.length > 0 &&
              searchData.map((faqs) => (
                <CollapsibleDiv
                  title={faqs.question}
                  newContent={faqs.answer}
                />
              ))}
            {searchData &&
              searchData !== "nodata" &&
              searchData.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "40vh",
                  }}
                >
                  <img src={NoDataDescription} />
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      marginTop: 5,
                      color: "rgb(77, 154, 195)",
                    }}
                  >
                    No FAQ Found
                  </p>
                </div>
              )}
          </FaqQuestions>
        </FaqContent>
      </FaqModalDiv>
    </>
  );
};

// Modal.propTypes = {
//   topInputs: PropTypes.arrayOf(PropTypes.object).isRequired,
//   initialValue: PropTypes.arrayOf(PropTypes.object).isRequired,

// };

export default FaqModal;
