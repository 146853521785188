import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const buttonBackground = css`
  ${(props) => {
    let properties = `
    background: ${props.theme.palette.button.main};
    background: ${props.theme.palette.button.gradient};
    color: ${props.theme.palette.primary.textColor};
    `;
    if (!props.color) {
      properties = `
      background: #fff;
      color: ${props.theme.palette.secondary.textColor};
      `;
    } else if (props.color === 'secondary') {
      properties = `
      background: ${props.theme.palette.button.main};
      background: ${props.theme.palette.button.secondaryGradient};
      color: ${props.theme.palette.primary.textColor};
      `;
    }
    return properties;
  }}
`;

const leftEdgePosition = {
  'left-top': css`
    border-top-left-radius: 0px;
  `,
  'left-bottom': css`
    border-bottom-left-radius: 0px;
  `,
};

const rightEdgePosition = {
  'right-top': css`
    border-top-right-radius: 0px;
  `,
  'right-bottom': css`
    border-bottom-right-radius: 0px;
  `,
};

const Button = styled(motion.button)`
  display: inline-block;
  height: 2.5rem;
  min-width: ${(props) => {
    let width = '200px';
    if (props.small) {
      width = '120px';
    } else if (props.large) {
      width = '300px';
    }
    return width;
  }};
  line-height: 2.5rem;
  border: ${(props) => (props.color
    ? '0px solid transparent'
    : `1px solid ${props.theme.palette.button.main}`)};
  ${buttonBackground}
  border-radius: ${(props) => (props.allEdges ? '0px' : '20px')};
  ${(props) => rightEdgePosition[props.rightEdge]}
  ${(props) => leftEdgePosition[props.leftEdge]}
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  };
  &:focus, &:active {
    outline: 0;
  }
`;

Button.propTypes = {
  rightEdge: PropTypes.string,
  leftEdge: PropTypes.string,
  allEdges: PropTypes.bool,
};

Button.defaultProps = {
  rightEdge: '',
  leftEdge: '',
  allEdges: false,
};

export default Button;
