/* eslint-disable no-console */
import React from 'react';
import styled from 'styled-components';
// import {
//   useDispatch,
// } from 'react-redux';
// import * as actionConstants from '../../_actions/actionConstants';
import AnimateWrapper from '../../containers/AnimateWrapper';
import {
  DialogueBox,
} from '../../ui';
import PageExpired from '../../assets/pictures/PageExpired.svg';

const DialogBox = styled(DialogueBox)`
text-align: center;
background-color: white;
color: #1E4D81;
padding: 8px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
@media only screen and (min-width: 768px) {
  width: 50%;
  height: 50%;
}
@media only screen and (max-width: 767px) {
  width: 90%;
  height: 62%;
}
`;

const SuccessLable = styled.p`
text-align: center;
color: #1E4D81;
font-weight: 400px;
font-size: 15px;
@media only screen and (min-width: 768px) {
  padding-top: 150px;
  padding-bottom: 5px;
  font-size: 25px;
}
@media only screen and (max-width: 767px) {
  padding-top: 95px;
  padding-bottom: 2px;
  font-size: 25px;
}
`;

const DivTag = styled.div`
@media only screen and (min-width: 768px) {
  padding: 5px;
}
@media only screen and (max-width: 767px) {
  padding: 2px;
}
`;

const Image = styled.img`
@media only screen and (min-width: 768px) {
  height:200px;
  width:200px;
};
@media only screen and (max-width: 767px) {
  height: 150px;
  width: 150px;
}
`;

const ExpiredPage = () => (
  <AnimateWrapper>
    <DialogBox>
      <Image
        src={PageExpired}
        alt="expired"
        style={{
          position: 'absolute', top: 0, transform: 'translate(-50%, -50%)',
        }}
      />
      <DivTag>
        <SuccessLable>
          Oops! Your page is expired.
          <br />
          Please contact counsellor for further action.
        </SuccessLable>
      </DivTag>
    </DialogBox>
  </AnimateWrapper>
);
// };

export default ExpiredPage;
