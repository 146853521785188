import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FeatureList from './featureList';
// import FeatureListPage from './FeatureListPage';
import { useSocket } from '../../utils';
import FaqModal from '../../containers/Modal';

const FeatureListHome = () => {
  const {
    personalInfo: { role } = { },
  } = useSelector((state = {}) => state);
  const socket = useSocket();
  const dispatch = useDispatch();
  useEffect(() => {
    if (socket) {
      socket.bind('update_selected_categories', (data = {}) => {
        dispatch({ ...data });
      });
    }
  }, [socket, dispatch]);
  // return role === 'Counsellor' ? <FeatureList /> : <FeatureListPage />;

  return (
    <>
      <FeatureList />
      {role === 'Counsellor' && <FaqModal />}

    </>
  );
};

export default FeatureListHome;
