import * as actionTypes from '../../_actions/actionConstants';

const intialState = {
  questions: null,
  is_error: false,
  questionNo: 0,
  questionType: 1,
  results: [],
  resultSummary: null,
  summaryKey: 'literacy',
  type: '',
  summaryVideo: {},
  currVideoIndex: 0,
  errorMessage: '',
};

const questionReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_QUESTIONS: {
      return {
        ...state,
        is_error: false,
        errorMessage: '',
        questions: action.payload.data,
      };
    }
    case actionTypes.FETCH_ERRORS: {
      return {
        ...state,
        is_error: true,
        errorMessage: action.payload.data,
        type: action.payload.type,
      };
    }
    case actionTypes.INCREMENT_QUESTION: {
      return {
        ...state,
        questionNo: action.payload.data,
      };
    }
    case actionTypes.RESET_QUESTION: {
      return {
        ...state,
        questionNo: 0,
      };
    }
    case actionTypes.CHANGE_QUESTION_TYPE: {
      return {
        ...state,
        questionType: 2,
      };
    }
    case actionTypes.SAVE_RESULT: {
      const newResult = state.results.filter((item) => item.id !== action.payload.data.id);
      newResult.push(action.payload.data);
      return {
        ...state,
        results: newResult,
      };
    }
    case actionTypes.FETCH_RESULT_SUMMARY: {
      return {
        ...state,
        resultSummary: action.payload.data,
      };
    }
    case actionTypes.CHANGE_SUMMARY: {
      return {
        ...state,
        summaryKey: action.payload.data,
        currVideoIndex: 0,
      };
    }
    case actionTypes.SUMMARY_VIDEO: {
      return {
        ...state,
        summaryVideo: action.payload.data,
      };
    }
    case actionTypes.CHANGE_CURR_VIDEO_INDEX: {
      return {
        ...state,
        currVideoIndex: action.index,
      };
    }
    default: {
      return state;
    }
  }
};

export default questionReducer;
