import React from 'react';
import styled from 'styled-components';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import * as actionType from '../../_actions/actionConstants';
import SuccessImage from '../../assets/pictures/success.svg';
import failImage from '../../assets/pictures/fail3.svg';
import AnimateWrapper from '../../containers/AnimateWrapper';
import {
  DialogueBox,
  Button,
  Loader,
} from '../../ui';
import * as actions from '../../_actions/personalInfo/actions';

const DialogBox = styled(DialogueBox)`
text-align: center;
background-color: white;
color: #1E4D81;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
@media only screen and (min-width: 768px) {
  width: 50%;
  height: 50%;
}
@media only screen and (max-width: 767px) {
  width: 90%;
  height: 65%;
}
`;

const SuccessLable = styled.h1`
text-align: center;
color: #1E4D81;;
@media only screen and (min-width: 768px) {
  padding-top: 30px;
  padding-bottom: 5px;
}
@media only screen and (max-width: 767px) {
  padding-top: 25px;
  padding-bottom: 5px;
}
`;

const FailLable = styled.h1`
text-align: center;
color: red;
@media only screen and (min-width: 768px) {
  padding-top: 30px;
  padding-bottom: 5px;
}
@media only screen and (max-width: 767px) {
  padding-top: 25px;
  padding-bottom: 5px;
}
`;
const Title = styled.p`
text-align: center;
color: #1E4D81;
@media only screen and (min-width: 768px) {
  padding: 5px;
  font-size: 18px;
}
@media only screen and (max-width: 767px) {
  padding: 5px;
  font-size: 15px;
}
`;
const DivTag = styled.div`
@media only screen and (min-width: 768px) {
  padding: 5px;
}
@media only screen and (max-width: 767px) {
  padding: 2px;
}
`;

const PaymentSuccessFailPage = () => {
  const dispatch = useDispatch();
  const audio = new Audio('https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/click.mp3');
  const isLoading = useSelector((state) => state.common.loading);
  const transactionDetails = useSelector((state) => state.personalInfo.transactionDetails);
  function backStepFunction() {
    dispatch({
      type: actionType.STEP_ID,
      payload: {
        id: 33,
        direction: -1,
      },
    });
  }

  function handlePayLater() {
    dispatch({
      type: actionType.STEP_ID,
      payload: {
        id: 36,
        direction: 1,
      },
    });
  }

  function funcionToDownloadReciept(details) {
    audio.play();
    dispatch((actions.fetchTransactionPaymentDetails(details)));
  }

  return (
    <AnimateWrapper>
      {transactionDetails && transactionDetails.paymentStatus === 'true' && (
      <DialogBox>
        <img
          src={SuccessImage}
          alt="animated"
          height="100px"
          width="100px"
          style={{
            position: 'absolute', top: 0, transform: 'translate(-50%, -50%)',
          }}
        />
        <DivTag>
          <SuccessLable>Success</SuccessLable>
        </DivTag>
        <DivTag>
          <Title> Thank You, Your payment of Rs.1500 has been received </Title>
          <Title>
            Order ID  :
            {` ${transactionDetails && transactionDetails.orderId} `}
          </Title>
          <Title>
            Transaction ID :
            {` ${transactionDetails && transactionDetails.transactionId} `}
          </Title>
        </DivTag>
        <DivTag style={{ paddingBottom: '20px' }}>
          <Button
            style={{ backgroundColor: '#1E4D81', color: 'white', borderRadius: '10px' }}
            onClick={() => funcionToDownloadReciept(transactionDetails)}
          >
            Print Receipt
          </Button>
        </DivTag>
      </DialogBox>
      )}
      {transactionDetails && transactionDetails.paymentStatus === 'false' && (
        <DialogBox>
          <DivTag>
            <img
              src={failImage}
              alt="crash"
              height="100px"
              width="100px"
              style={{
                position: 'absolute', top: 0, transform: 'translate(-50%, -50%)',
              }}
            />
          </DivTag>
          <DivTag>
            <FailLable>Oh no !</FailLable>
          </DivTag>
          <DivTag>
            <Title> Order Amount  : 1500 Rs/-</Title>
            <Title> Payment failed, please try again </Title>
          </DivTag>
          <DivTag>
            <Button
              style={{ backgroundColor: '#1E4D81', color: 'white', borderRadius: '10px' }}
              onClick={() => backStepFunction()}
            >
              Try Again
            </Button>
            &nbsp; &nbsp;
            <Button
              style={{ backgroundColor: '#1E4D81', color: 'white', borderRadius: '10px' }}
              onClick={() => handlePayLater()}
            >
              PAY LATER
            </Button>
          </DivTag>
        </DialogBox>
      )}
      <Loader open={isLoading} />
    </AnimateWrapper>
  );
};

export default PaymentSuccessFailPage;
