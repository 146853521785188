import React, { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Wizard from './containers/Wizard';
import CommonBg from './assets/pictures/CommonBg.svg';
import * as actions from './_actions/personalInfo/actions';
import fetchPersonalInfo from './_actions/personalInfo/personalInfoactions';
import { SocketProvider } from './utils';
import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: 'Open Sans', sans-serif;
  }
`;

const Wrapper = styled.div`
  background-image: url(${CommonBg});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

function UUID() {
  const params = new URLSearchParams(window.location.search);
  if (params.has('uuid')) return params.get('uuid');
  return null;
}

function App() {
  const dispatch = useDispatch();
  const meetingId = useSelector((state) => state.personalInfo.meeting_id);
  const role = useSelector((state) => state.personalInfo.role);
  useEffect(() => {
    if (meetingId) {
      dispatch((actions.DeviceCount(meetingId, role)));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingId]);
  useEffect(() => {
    if (UUID()) {
      dispatch(fetchPersonalInfo(UUID()));
      dispatch((actions.fetchGrades));
      dispatch((actions.fetchBranch));
      dispatch((actions.fetchCity));
      dispatch((actions.fetchFaq));
      dispatch((actions.getAllCategories));
    }
    const timer = setTimeout(() => {
      document.body.classList.add('loaded');
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <GlobalStyles />
      <SocketProvider>
        <Wizard />
      </SocketProvider>
    </Wrapper>
  );
}

export default App;
